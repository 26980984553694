import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import EventProfile from '../views/apps/event/EventProfile';
import ViewUser from '../views/apps/users-creation/ViewUser';
import VanDistrictAllocation from '../views/apps/rath/VanDistrictAllocation';
import DCVanList from '../views/apps/rath/DCVanList';
import DayNodalOfficer from '../views/apps/rath/DayNodalOfficer';
import UrbanVanAllocation from '../views/apps/rath/UrbanVanAllocation';
import AddUL from '../views/apps/rath/addUL';
import ViewUL from '../views/apps/rath/viewUL';
import EditUL from '../views/apps/rath/EditUL';
import PublicEventList from '../views/apps/event/PublicEventList';
import Event from '../views/apps/event/Event';
import VanChecklist from '../views/apps/urban/VanChecklist';
import ActivityChecklist from '../views/apps/urban/ActivityChecklist';
import RathAddVan from '../views/apps/rath/AddVan';
import UrbanAddVan from '../views/apps/urban/AddVan';

const CreateUser = Loadable(lazy(() => import('../views/apps/users-creation/CreateUser')));

const Home = Loadable(lazy(() => import('../views/Home/Home')));
const About = Loadable(lazy(() => import('../views/apps/About')));
const Terms = Loadable(lazy(() => import('../views/apps/Terms')));
const MCScript = Loadable(lazy(() => import('../views/apps/MCScript')));
const PrivacyPolicy = Loadable(lazy(() => import('../views/apps/PrivacyPolicy')));
const PublicDownload = Loadable(lazy(() => import('../views/apps/PublicDownload')));
const PublicEvents = Loadable(lazy(() => import('../views/public-event/PublicEvent')));
const PublicEventsList = Loadable(lazy(() => import('../views/public-event/PublicEventList')));
const PublicEventMedia = Loadable(
  lazy(() => import('../views/public-event-media/PublicEventMedia')),
);
const Flagship = Loadable(lazy(() => import('../views/apps/Flagship')));
const Photo = Loadable(lazy(() => import('../views/apps/Photo')));
const Quiz = Loadable(lazy(() => import('../views/apps/Quiz')));
const Video = Loadable(lazy(() => import('../views/apps/Video')));
const Selfie = Loadable(lazy(() => import('../views/Home/Selfie')));
const UploadSelfie = Loadable(lazy(() => import('../views/Home/UploadSelfie')));
/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const FullAuthenticatedLayout = Loadable(
  lazy(() => import('../layouts/full-layout/FullAuthenticatedLayout')),
);
// const FullAutheticatedRathLayout = Loadable(lazy(() => import('../layouts/full-layout/FullAutheticatedRathLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../views/authentication/ResetPassword')));

/* ****Pages***** */
const Dashboard1 = Loadable(lazy(() => import('../views/dashboards/Dashboard1')));
const Dashboard2 = Loadable(lazy(() => import('../views/dashboards/Dashboard2')));
const Dashboard3 = Loadable(lazy(() => import('../views/dashboards/Dashboard3')));

/* ****Apps***** */
const Chats = Loadable(lazy(() => import('../views/apps/chats/Chats')));
const Notes = Loadable(lazy(() => import('../views/apps/notes/Notes')));
const Email = Loadable(lazy(() => import('../views/apps/email/Email')));
const Shop = Loadable(lazy(() => import('../views/apps/event/EventList')));
const CreateEvent = Loadable(lazy(() => import('../views/apps/event/CreateEvent')));
const RathList = Loadable(lazy(() => import('../views/apps/rath/RathList')));
const EventReport = Loadable(lazy(() => import('../views/apps/event/EventReport')));
const RathAllocation = Loadable(lazy(() => import('../views/apps/rath/RathAllocation')));
const RathForm = Loadable(lazy(() => import('../views/apps/rath/RathForm')));
const MapView = Loadable(lazy(() => import('../views/apps/rath/MapView')));
const MapViewLive = Loadable(lazy(() => import('../views/apps/rath/MapViewLive')));
const RouteReplay = Loadable(lazy(() => import('../views/apps/rath/RouteReplay')));
const EventGeoTagging = Loadable(lazy(() => import('../views/apps/rath/EventGeoTags')));
const VanRouteReplay = Loadable(lazy(() => import('../views/apps/rath/VanRouteReplay')));
const PeopleCount = Loadable(lazy(() => import('../views/apps/rath/PeopleCount')));
const DayNodalProgress = Loadable(lazy(() => import('../views/apps/rath/DayNodalProgress')));
const DayNodalSatatKrishi = Loadable(lazy(() => import('../views/apps/rath/DayNodalSatatKrishi')));
const Dharti = Loadable(lazy(() => import('../views/apps/rath/Dharti')));
const OnSpot = Loadable(lazy(() => import('../views/apps/rath/OnSpot')));
const FieldOfficerProgress = Loadable(
  lazy(() => import('../views/apps/rath/FieldOfficerProgress')),
);
const SRLMmkmz = Loadable(lazy(() => import('../views/apps/rath/SRLMmkmz')));
const StateCompletionReport = Loadable(lazy(() => import('../views/apps/state-prabhari/StateCompletionReport')));
const SrlmEventUpload = Loadable(lazy(() => import('../views/SrlmEventUpload')));
const FieldAttendence = Loadable(lazy(() => import('../views/apps/rath/FieldAttendence')));
const Help = Loadable(lazy(() => import('../views/apps/rath/Help')));
const Sankalp = Loadable(lazy(() => import('../views/apps/rath/Sankalp')));
const CelebrationProgress = Loadable(lazy(() => import('../views/apps/rath/CelebrationProgress')));
const Calendar = Loadable(lazy(() => import('../views/apps/rath/Calendar')));
const VanPhoto = Loadable(lazy(() => import('../views/apps/urban/VanPhoto')));
const AssociatedInventory = Loadable(lazy(() => import('../views/apps/urban/AssociatedInventory')));
const Participants = Loadable(lazy(() => import('../views/apps/urban/Participants')));
const StateVisit = Loadable(lazy(() => import('../views/apps/state-prabhari/StateVisit')));
const OfficerList = Loadable(lazy(() => import('../views/apps/state-prabhari/OfficerList')));
const MarkList = Loadable(lazy(() => import('../views/apps/state-prabhari/MarkList')));
const VanList = Loadable(lazy(() => import('../views/apps/state-prabhari/VanList')));
const AddSwagatCommittee = Loadable(lazy(() => import('../views/apps/rath/AddSwagatCommittee')));
const AddUtsavCommittee = Loadable(lazy(() => import('../views/apps/rath/AddUtsavCommittee')));
const UrbanVanList = Loadable(lazy(() => import('../views/apps/urban/UrbanVanList')));
const Download = Loadable(lazy(() => import('../views/downloads/Download')));
const DownloadCertificate = Loadable(lazy(() => import('../views/downloads/DownloadCertificate')));
const VisitReport = Loadable(lazy(() => import('../views/apps/state-prabhari/VisitReport')));
const UrbanCalendar = Loadable(lazy(() => import('../views/apps/urban/UrbanCalendar')));
const ApprovedContent = Loadable(lazy(() => import('../views/apps/urban/ApprovedContent')));
const OnSpotActivity = Loadable(lazy(() => import('../views/apps/urban/UploadOnSpotActivity')));
const UploadContent = Loadable(lazy(() => import('../views/apps/urban/UploadContent')));
const UplaodCertificate = Loadable(lazy(() => import('../views/apps/urban/UploadCertificate')));
const HelpNotification = Loadable(lazy(() => import('../views/apps/urban/HelpNotificaton')));
const UrbanPeopleCount = Loadable(lazy(() => import('../views/apps/urban/UrbanPeopleCount')));
const UrbanSankalp = Loadable(lazy(() => import('../views/apps/urban/UrbanSankap')));
const UrbanDayNodalProgress = Loadable(
  lazy(() => import('../views/apps/urban/UrbanDayNodalProgress')),
);
const ModerationReport = Loadable(lazy(() => import('../views/apps/ModerationReport')));
const UserReport = Loadable(lazy(() => import('../views/apps/UserReport')));
const EventVehicleReport = Loadable(lazy(() => import('../views/apps/EventVehicleReport')));
const VanEmceesReport = Loadable(lazy(() => import('../views/apps/VanEmceesReport')))
const VehicleRunningStatus = Loadable(lazy(() => import('../views/apps/VehicleRunningStatus')))
const DistrictCompletionReport = Loadable(lazy(() => import('../views/apps/state-prabhari/DistrictCompletionReport')));
const AllVans = Loadable(lazy(() => import('../views/apps/rath/AllVans')));
const EventUpdates = Loadable(lazy(() => import('../views/apps/event/EventUpdates')));
const PreEventMKMZ = Loadable(lazy(() => import('../views/apps/rath/PreEventMKMZ')));
const PreEventSatatKrishi = Loadable(lazy(() => import('../views/apps/rath/PreEventSatatKrishi')));
const UrbanDriverAllocation = Loadable(lazy(() => import('../views/apps/rath/UrbanDriverAllocation')));
const RuralDriverAllocation = Loadable(lazy(() => import('../views/apps/rath/RuralDriverAllocation')));
const VbsyVisit = Loadable(lazy(() => import('../views/apps/state-prabhari/VbsyVist')));
const VbsyVisitReport = Loadable(lazy(() => import('../views/apps/state-prabhari/VbsyVisitReport')));
const Guideline = Loadable(lazy(() => import('../views/Guideline/Guideline')));
const VisitStatusReport = Loadable(lazy(() => import('../views/apps/state-prabhari/VisitStatusReport')));
const PreEventDKPP = Loadable(lazy(() => import('../views/apps/rath/PreEventDKPP')));
const CompletionStatusReport = Loadable(lazy(() => import('../views/apps/state-prabhari/CompletionStatusReport')));
const QuillEditor = Loadable(lazy(() => import('../views/quill-editor/QuillEditor')));
const Treeview = Loadable(lazy(() => import('../views/treeview/Treeview')));
const Pricing = Loadable(lazy(() => import('../views/pricing/Pricing')));
const ManageQuiz = Loadable(lazy(() => import('../views/apps/rath/Quiz')));
const CompletionReport = Loadable(lazy(() => import('../views/apps/state-prabhari/CompletionReport')));
const CustomTimeline = Loadable(lazy(() => import('../views/timeline/CustomTimeline')));
const CustomTypography = Loadable(lazy(() => import('../views/typography/CustomTypography')));
const CustomerEdit = Loadable(lazy(() => import('../views/apps/customers/CustomerEdit')));
const CustomerLists = Loadable(lazy(() => import('../views/apps/customers/CustomerLists')));
/* ****Tables***** */
const BasicTable = Loadable(lazy(() => import('../views/tables/BasicTable')));
const PaginationTable = Loadable(lazy(() => import('../views/tables/PaginationTable')));
const EnhancedTable = Loadable(lazy(() => import('../views/tables/EnhancedTable')));
const CollapsibleTable = Loadable(lazy(() => import('../views/tables/CollapsibleTable')));
const FixedHeaderTable = Loadable(lazy(() => import('../views/tables/FixedHeaderTable')));
const SelfieWithPM = Loadable(lazy(() => import('../views/Home/SelfieWithPM')));

// form elements
const ExAutoComplete = Loadable(lazy(() => import('../views/form-elements/ExAutoComplete')));
const ExButton = Loadable(lazy(() => import('../views/form-elements/ExButton')));
const ExCheckbox = Loadable(lazy(() => import('../views/form-elements/ExCheckbox')));
const ExDateTime = Loadable(lazy(() => import('../views/form-elements/ExDateTime')));
const ExRadio = Loadable(lazy(() => import('../views/form-elements/ExRadio')));
const ExSlider = Loadable(lazy(() => import('../views/form-elements/ExSlider')));
const ExSwitch = Loadable(lazy(() => import('../views/form-elements/ExSwitch')));
const FormWizard = Loadable(lazy(() => import('../views/form-layouts/FormWizard')));
// form layouts
const FormLayouts = Loadable(lazy(() => import('../views/form-layouts/FormLayouts')));
const FormCustom = Loadable(lazy(() => import('../views/form-layouts/FormCustom')));

// widgets
const WidgetFeed = Loadable(lazy(() => import('../views/widgets/widget-feed/WidgetFeed')));
const WidgetApps = Loadable(lazy(() => import('../views/widgets/widget-apps/WidgetApps')));

// userprofile

const ShopDetail = Loadable(lazy(() => import('../views/apps/shop-detail/ShopDetail')));

// chart
const LineChart = Loadable(lazy(() => import('../views/charts/LineChart')));
const GredientChart = Loadable(lazy(() => import('../views/charts/GredientChart')));
const DoughnutChart = Loadable(lazy(() => import('../views/charts/DoughnutChart')));
const AreaChart = Loadable(lazy(() => import('../views/charts/AreaChart')));
const ColumnChart = Loadable(lazy(() => import('../views/charts/ColumnChart')));
const CandlestickChart = Loadable(lazy(() => import('../views/charts/CandlestickChart')));
const RadialbarChart = Loadable(lazy(() => import('../views/charts/RadialbarChart')));

// icons
const ReactIcons = Loadable(lazy(() => import('../views/icons/ReactIcons')));

// Alert
const ExAlert = Loadable(lazy(() => import('../views/alert/ExAlert')));
const VanAllocationList = Loadable(lazy(() => import('../views/apps/rath/VanAllocationList')));

/* ****Routes***** */

const Router = [
  /* ****Landing Routes***** */
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/mc-script',
    element: <MCScript />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/terms',
    element: <Terms />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/public-download',
    element: <PublicDownload />,
  },
  {
    path: '/photo',
    element: <Photo />,
  },
  {
    path: '/video',
    element: <Video />,
  },
  {
    path: '/selfie',
    element: <Selfie />,
  },
  {
    path: '/upload-selfie',
    element: <UploadSelfie />,
  },
  {
    path: '/quiz',
    element: <Quiz />,
  },
  {
    path: '/public-events',
    element: <PublicEvents />,
  },
  {
    path: '/public-event-media',
    element: <PublicEventMedia />,
  },
  {
    path: '/flagship',
    element: <Flagship />,
  },
  {
    path: '/selfie-with-pm',
    element: <SelfieWithPM />,
  },
  {
    path: '/public-event-list',
    element: <PublicEventList />,
  },
  {
    path: '/public-event-list/:locationType/:id',
    element: <Event />,
  },
  { path: '/events-list', element: <PublicEventsList /> },
  { path: '/events-locations', element: <EventGeoTagging /> },
  { path: '/guideline', element: <Guideline /> },
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/event/:id', element: <EventProfile /> },
    ],
  },
  /* ****Authenticated Routes***** */
  {
    path: '/',
    element: <FullAuthenticatedLayout />,
    children: [
      { path: '/dashboards/dashboard1', exact: true, element: <Dashboard1 /> },
      { path: '/dashboards/dashboard2', exact: true, element: <Dashboard2 /> },
      { path: '/customers/lists', exact: true, element: <CustomerLists /> },
      { path: '/chats', element: <Chats /> },
      { path: '/notes', element: <Notes /> },
      { path: '/email', element: <Email /> },
      { path: '/event-list', element: <Shop /> },
      { path: '/srlm-meri-kahani-meri-zubani', element: <SRLMmkmz /> },
      { path: '/calendar', element: <Calendar /> },
      { path: '/rath-entry-exit', element: <RathForm /> },
      { path: '/event/add-event', element: <CreateEvent /> },
      { path: '/rath', element: <RathList /> },
      { path: '/allocation-van-list', element: <VanAllocationList /> },
      { path: '/vbsy-visit', element: <VbsyVisit /> },
      { path: '/add-day-nodal-officer', element: <DayNodalOfficer /> },
      { path: '/rath/district-collector', element: <DCVanList /> },
      { path: '/rath/allocation', element: <RathAllocation /> },
      { path: '/rath/district-allocation', element: <VanDistrictAllocation /> },
      { path: '/rath-progress', element: <PeopleCount /> },
      { path: '/sankalp', element: <Sankalp /> },
      { path: '/manage-quiz', element: <ManageQuiz /> },
      { path: '/celebration-progress', element: <CelebrationProgress /> },
      { path: '/day-nodal-progress', element: <DayNodalProgress /> },
      { path: '/day-nodal-satat-krishi', element: <DayNodalSatatKrishi /> },
      { path: '/dharti-kahe-pukaar-ke', element: <Dharti /> },
      { path: '/add-swagat-committee', element: <AddSwagatCommittee /> },
      { path: '/add-utsav-committee', element: <AddUtsavCommittee /> },
      { path: '/map-view', element: <MapView /> },
      { path: '/live-tracking', element: <MapViewLive /> },
      { path: '/route-replay', element: <RouteReplay /> },
      // { path: '/events-locations', element: <EventGeoTagging /> },
      // { path: '/van-route-replay', element: <VanRouteReplay /> },
      { path: '/on-spot-services', element: <OnSpot /> },
      { path: '/add-user/:type', element: <CreateUser /> },
      { path: '/view-users/:type', element: <ViewUser /> },
      { path: '/on-spot-services', element: <OnSpot /> },
      { path: '/field-officer-progress', element: <FieldOfficerProgress /> },
      { path: 'field-officer-attendence', element: <FieldAttendence /> },
      { path: '/help', element: <Help /> },
      { path: '/urban/van-photo', element: <VanPhoto /> },
      { path: '/urban/inventory', element: <AssociatedInventory /> },
      { path: '/urban/participants', element: <Participants /> },
      { path: '/state-visit', element: <StateVisit /> },
      { path: '/officer-list', element: <OfficerList /> },
      { path: '/mark-list', element: <MarkList /> },
      { path: '/van-list', element: <VanList /> },
      { path: '/urban-van-list', element: <UrbanVanList /> },
      { path: '/download', element: <Download /> },
      { path: '/download-certificate', element: <DownloadCertificate /> },
      { path: '/urban-van-allocation', element: <UrbanVanAllocation /> },
      { path: '/visit-report', element: <VisitReport /> },
      { path: '*', element: <Navigate to='/auth/404' /> },
      { path: '/on-spot-activity', element: <OnSpotActivity /> },
      { path: '/approved-content', element: <ApprovedContent /> },
      { path: '/urban-calendar', element: <UrbanCalendar /> },
      { path: '/upload-content', element: <UploadContent /> },
      { path: '/upload-certificate', element: <UplaodCertificate /> },
      { path: '/help-notification', element: <HelpNotification /> },
      { path: '/urban-people-count', element: <UrbanPeopleCount /> },
      { path: '/urban-sankalp', element: <UrbanSankalp /> },
      { path: '/urban-day-nodal-progress', element: <UrbanDayNodalProgress /> },
      { path: '/van-checklist', element: <VanChecklist /> },
      { path: '/activity-checklist', element: <ActivityChecklist /> },
      { path: '/add-urban-event', element: <AddUL /> },
      { path: '/view-urban-event', element: <ViewUL /> },
      { path: '/edit-urban-event', element: <EditUL /> },
      { path: '/event-updates', element: <EventUpdates /> },
      { path: '/pre-event-mkmz', element: <PreEventMKMZ /> },
      { path: '/pre-event-satat-krishi', element: <PreEventSatatKrishi /> },
      { path: '/urban-driver-allocation', element: <UrbanDriverAllocation /> },
      { path: '/rural-driver-allocation', element: <RuralDriverAllocation /> },
      { path: '/vbsy-visit-report', element: <VbsyVisitReport /> },
      { path: '/all-van-list', element: <AllVans /> },
      { path: '/event-report', element: <EventReport /> },
      { path: '/rath/add-van', element: <RathAddVan /> },
      { path: '/urban/add-van', element: <UrbanAddVan /> },
      { path: '/pre-event-dkpp', element: <PreEventDKPP /> },
      { path: '/visit-status-report', element: <VisitStatusReport /> },
      { path: '/srlm-event-media', element: <SrlmEventUpload /> },
      { path: '/state-completion-report', element: <StateCompletionReport /> },
      { path: '/district-completion-report', element: <DistrictCompletionReport /> },
      { path: '/completion-report', element: <CompletionReport /> },
      { path: '/completion-status-report', element: <CompletionStatusReport /> },
      { path: '/moderation-report', element: <ModerationReport /> },
      { path: '/vehicle-event-report', element: <EventVehicleReport /> },
      { path: '/user-report', element: <UserReport /> },
      { path: '/van-emcee-report', element: <VanEmceesReport /> },
      { path: '/vehicle-running-status', element: <VehicleRunningStatus /> },
      { path: '*', element: <Navigate to='/auth/404' /> },

      //template-route
      // {path: '/customers/lists', exact: true, element: <CustomerLists/>},
      // {path: '/chats', element: <Chats/>},
      // {path: '/notes', element: <Notes/>},
      // {path: '/email', element: <Email/>},
      // {path: '/calendar', element: <Calendar/>},
      // {path: '/customers/edit', element: <CustomerEdit/>},
      // {path: '/tables/basic-table', element: <BasicTable/>},
      // {path: '/tables/pagination-table', element: <PaginationTable/>},
      // {path: '/tables/enhanced-table', element: <EnhancedTable/>},
      // {path: '/tables/collapsible-table', element: <CollapsibleTable/>},
      // {path: '/tables/fixed-header-table', element: <FixedHeaderTable/>},
      // {path: '/form-layouts/form-layouts', element: <FormLayouts/>},
      // {path: '/form-elements/autocomplete', element: <ExAutoComplete/>},
      // {path: '/form-elements/button', element: <ExButton/>},
      // {path: '/form-elements/checkbox', element: <ExCheckbox/>},
      // {path: '/form-elements/date-time', element: <ExDateTime/>},
      // {path: '/form-elements/radio', element: <ExRadio/>},
      // {path: '/form-elements/slider', element: <ExSlider/>},
      // {path: '/form-elements/switch', element: <ExSwitch/>},
      // {path: '/form-layouts/form-wizard', element: <FormWizard/>},
      // {path: '/widgets/widget-feed', element: <WidgetFeed/>},
      // {path: '/widgets/widget-apps', element: <WidgetApps/>},
      // {path: '/shop/shop-detail', element: <ShopDetail/>},
      // {path: '/charts/line-chart', element: <LineChart/>},
      // {path: '/charts/gredient-chart', element: <GredientChart/>},
      // {path: '/charts/doughnut-pie-chart', element: <DoughnutChart/>},
      // {path: '/charts/area-chart', element: <AreaChart/>},
      // {path: '/charts/column-chart', element: <ColumnChart/>},
      // {path: '/charts/candlestick-chart', element: <CandlestickChart/>},
      // {path: '/charts/radialbar-chart', element: <RadialbarChart/>},
      // {path: '/react-icons', element: <ReactIcons/>},
      // {path: '/form-layouts/form-custom', element: <FormCustom/>},
      // {path: '/quill-editor', element: <QuillEditor/>},
      // {path: '/treeview', element: <Treeview/>},
      // {path: '/pricing', element: <Pricing/>},
      // {path: '/timeline', element: <CustomTimeline/>},
      // {path: '/typography', element: <CustomTypography/>},
      // {path: '/alert', element: <ExAlert/>},
    ],
  },

  /*Public Routes*/
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: '*', element: <Navigate to='/auth/404' /> },
    ],
  },
  //navigate to 404
  { path: '*', element: <Navigate to='/auth/404' /> },
];

export default Router;
