import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
} from '@mui/material';
import firebase from '../../../../firebase';
import Breadcrumb from '../../../../layouts/full-layout/breadcrumb/Breadcrumb';
import { formatFirebaseTimestampToDateString } from '../../rath/utility';
import { convertJsonToCsvAndDownload } from '../../../../utility/ExportButton';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
const UserTable = ({ columns, table_data, type, logged_in_user, info }) => {
  const { t } = useTranslation();
  const Url = process.env.REACT_APP_FIREBASE_ENV === 'staging' ? 'https://asia-south1-vbsy-stagging.cloudfunctions.net/deleteDayUsers' : 'https://asia-south1-viksit-bharat-s-y-dic.cloudfunctions.net/deleteDayUsers'
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const columnNames = Object.keys(columns);
  const columnsProper = {
    state_name: 'State Name',
    ul_name: 'Urban Event Location',
    district_name: 'District Name',
    block_name: 'Block Name',
    ulb_name: 'ULB Name',
    gp_name: 'GP Name',
    name: 'Name',
    mobile: 'Mobile',
    designation: 'Designation',
    email: 'Email',
    agency: 'Agency',
    scheme: 'Scheme',
    role: 'Role',
    department: 'Department',
    location_type: 'Location Type',
    camp: 'Camp'
  };

  useEffect(() => {
    setData(table_data);
  }, [table_data]);

  const exportdata = () => {
    // Create an array for the CSV data with columns from columnsProper only if data is present
    const csvData = data.map((row) => {
      const formattedRow = {};

      Object.keys(columnsProper).forEach((columnName) => {
        const columnValue = row[columnName];
        const formattedColumnName = columnsProper[columnName];

        // Check if the column value exists in the row before adding it
        if (columnValue !== undefined) {
          // Enclose the value in double quotes if it contains a comma
          formattedRow[formattedColumnName] = columnValue.includes(',') ? `"${columnValue}"` : columnValue;
        }
      });

      return formattedRow;
    });

    // Convert the data to CSV and download it
    convertJsonToCsvAndDownload(csvData, 'list.xlsx');
  };




  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = open; // Assuming 'open' is a valid object, otherwise replace with the correct data source

    try {

      firebase.auth().onAuthStateChanged(async (user) => {
        const token = await user.getIdToken();
        axios.get(`${Url}?doc_id=${data.doc_id}&token=${token}&info=${JSON.stringify(info)}`).then((res) => {

          if (res?.data?.status == 200) {
            handleClose();

            setAlertSeverity('success');
            setAlertMessage('Deleted Successfully !');
            setIsAlertOpen(true);

            // handleUserFormChange(type).then(()=>{
            //   setLoading(false);
            // })

            setTimeout(() => {
              window.location.reload();
            }, 1000)

          } else {
            setLoading(false);
            setAlertSeverity('error');
            setAlertMessage(` ${type == 'rath_login' ? "Please delete van allocation related to this user first" : "Something went wrong!"}`);
            handleClose();
            setIsAlertOpen(true);
          }
        })
      })


    } catch (error) {
      setLoading(false);
      setAlertSeverity('error');
      setAlertMessage('Something went wrong!' + " " + error);
      setIsAlertOpen(true);
      handleClose();
      // Handle any errors that may occur during the execution
      console.error('An error occurred:', error);
    }
  };
  const handleAlertClose = () => {
    setIsAlertOpen(false);
  };
  return (
    <><Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isAlertOpen}
      autoHideDuration={3000}
      onClose={handleAlertClose}
    >
      <Alert severity={alertSeverity} onClose={handleAlertClose} variant="filled">
        {alertMessage}
      </Alert>
    </Snackbar>
      <Card>
        <CardContent>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Box sx={{ width: '70%' }}>
              <Typography sx={{ marginLeft: '1rem' }} variant="h4">
                {t('list_of_users')}
              </Typography>
            </Box>
            {data.length > 0 && (
              <Button
                sx={{ marginRight: '1rem' }}
                onClick={() => exportdata()}
                variant="contained"
                color="primary"
              >
                {t('export')}
              </Button>
            )}
          </Stack>
          <Box style={{ overflow: 'auto' }}>
            <Table
              aria-label="simple table"
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h5">S.No</Typography>
                  </TableCell>
                  {columnNames.map(
                    (columnName) =>
                      columns[columnName] && (
                        <TableCell key={columnName}>
                          <Typography variant="h5">{columnsProper[columnName]}</Typography>
                        </TableCell>
                      ),
                  )}
                  {logged_in_user == 'district_nodal_login' && ["rath_login", "district_login"].includes(type) && <TableCell>
                    <Typography variant="h5">Delete</Typography>
                  </TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  row.initial && row.allocated ? <></> :
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      {columnNames.map(
                        (columnName) =>
                          columns[columnName] && (
                            <TableCell key={columnName}>
                              {row[columnName]} {/* Render data for the column */}
                            </TableCell>
                          ),
                      )}

                      {logged_in_user == 'district_nodal_login' && ["rath_login", "district_login"].includes(type) && <TableCell>
                        <Typography color="textSecondary" variant="h6" fontWeight="400">
                          <FeatherIcon
                            onClick={() => setOpen(row)}
                            style={{ cursor: 'pointer' }}
                            icon={'delete'}
                            width="20"
                            height="20"
                          />
                        </Typography>

                      </TableCell>
                      }
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </CardContent>
      </Card>

      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        fullWidth
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title' variant='h4'>
          {t('edit_users')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description' component='div'>
            <span style={{ color: 'red' }}>{t('are_you_sure')}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={() => handleSubmit()} color='danger' variant='contained'>
            {t('delete')} {' '} <FeatherIcon style={{ position: 'relative', left: '5px' }} icon={'trash'}
              width='20' height='20' />
          </LoadingButton>

        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserTable;
