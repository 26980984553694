// export function convertJsonToCsvAndDownload(jsonData, filename) {
//   // Convert JSON to CSV
//   const csvData = convertJsonToCsv(jsonData);

//   // Create a Blob containing the CSV data
//   const blob = new Blob([csvData], { type: 'text/csv' });

//   // Create a download link and trigger the download
//   const link = document.createElement('a');
//   link.href = window.URL.createObjectURL(blob);
//   link.download = filename || 'data.csv';
//   link.style.display = 'none';
//   document.body.appendChild(link);
//   link.click();

//   // Clean up
//   document.body.removeChild(link);
// }

// function convertJsonToCsv(jsonData) {
//   const headers = Object.keys(jsonData[0]);

//   // Create CSV content
//   return [
//     headers.join(','), // CSV header
//     ...jsonData.map((row) => headers.map((header) => row[header]).join(',')),
//   ].join('\n');
// }


import ExcelJS from 'exceljs';

export function convertJsonToCsvAndDownload(jsonData, filename) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  // Add headers
  const headers = Object.keys(jsonData[0]);
  worksheet.addRow(headers);

  // Add data
  jsonData.forEach((row) => {
    const values = headers.map((header) => row[header]);
    worksheet.addRow(values);
  });

  // Create a Blob containing the Excel data
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a download link and trigger the download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename || 'data.xlsx';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  });
}
