import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import 'firebase/compat/functions';
import { getAuth } from 'firebase/auth';
import { staging_config } from './config/staging';
import { prod_config } from './config/production';
const { getFunctions } = require('firebase/functions');

const { initializeAppCheck, ReCaptchaV3Provider } = require('firebase/app-check');

const firebaseConfig =
  process.env.REACT_APP_FIREBASE_ENV === 'production' ? prod_config : staging_config;
const app = firebase.initializeApp(firebaseConfig);
firebase.database();
firebase.storage();
export const firestore = firebase.firestore();
firebase.analytics();
firebase.firestore();
// if (window.location.hostname == "localhost") {
//   window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// }

export const functions = getFunctions(app);
export const auth = getAuth(app);
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider("6Ld8NXsnAAAAAB_AKPoWIRgKyE9ssGjkF6bjrDE4"),
//   isTokenAutoRefreshEnabled: true,
// });

export default firebase;
