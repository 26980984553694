import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Divider,
    MenuItem,
    Grid,
    Alert,
    Snackbar,
    Box,
    Typography,
} from '@mui/material';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import '../event/event-list.css';
import firebase from '../../../firebase';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const VanChecklist = () => {
    const { t } = useTranslation();

    const profileReducer = useSelector((state) => state.ProfileReducer);
    const profileDetail = useSelector((state) => state.ProfileDetailReducer);
    const [isAlertOpen, setIsAlertOpen] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('success');
    const [alertMessage, setAlertMessage] = React.useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [filteredEvent, setFilteredEvent] = useState([]);
    const [doneEvent, setDoneEvent] = useState([]);
    const [isEventDatePast, setIsEventDatePast] = useState(false);
    const [selectedUlName, setSelectedUlName] = useState('');
    const [selectedUlCode, setSelectedUlCode] = useState('');
    const [ulOptions, setUlOptions] = useState([]);

    const nameToLabelMap = (name) => {
        const map = {
            branding_with_qr_code_available: t('branding_with_qr_code_available'),
            master_of_ceremony_present: t('master_of_ceremony_present'),
            functional_led_screen: t('functional_led_screen'),
            function_generator: t('function_generator'),
            cordless_mic: t('cordless_mic'),
            sound_system: t('sound_system'),
            standies: t('standies'),
            selfie_point: t('selfie_point'),
            flood_light: t('flood_light'),
            wifi: t('wifi'),
            gps_tracker_installed: t('gps_tracker_installed'),
        }
        return map[name]
    }

    const validationSchema = Yup.object().shape({
        branding_with_qr_code_available: Yup.boolean().required(t('field_is_required')),
        master_of_ceremony_present: Yup.boolean().required(t('field_is_required')),
        functional_led_screen: Yup.boolean().required(t('field_is_required')),
        function_generator: Yup.boolean().required(t('field_is_required')),
        cordless_mic: Yup.boolean().required(t('field_is_required')),
        sound_system: Yup.boolean().required(t('field_is_required')),
        standies: Yup.boolean().required(t('field_is_required')),
        selfie_point: Yup.boolean().required(t('field_is_required')),
        flood_light: Yup.boolean().required(t('field_is_required')),
        wifi: Yup.boolean().required(t('field_is_required')),
        gps_tracker_installed: Yup.boolean().required(t('field_is_required')),
    });
    const [event, setEvent] = React.useState({
        branding_with_qr_code_available: null,
        master_of_ceremony_present: null,
        functional_led_screen: null,
        function_generator: null,
        cordless_mic: null,
        sound_system: null,
        standies: null,
        selfie_point: null,
        flood_light: null,
        wifi: null,
        gps_tracker_installed: null,
    });

    useEffect(() => {
        if (selectedUlCode) {
            setDoneEvent([]);
            const db = firebase.firestore();
            const eventsCollection = db.collection('events');
            eventsCollection.where('ul_code', '==', selectedUlCode).get().then((querySnapshot) => {
                if (!querySnapshot.empty) {
                    querySnapshot.docs.forEach((doc) => {
                        let data = doc.data();
                        if (data.van_checklist_done) {
                            const activitiesCollection = db.collection('activities');
                            activitiesCollection.where('event_id', '==', doc.id)
                                .where('ul_code', '==', selectedUlCode)
                                .where('category', '==', 'van_checklist')
                                .get()
                                .then((querySnapshot1) => {
                                    if (!querySnapshot1.empty) {
                                        querySnapshot1.docs.forEach((doc1) => {
                                            let data1 = doc1.data();
                                            data = {
                                                ...data,
                                                [data1.key_name]: data1.key_value
                                            }
                                            setDoneEvent(data);
                                        })
                                    }
                                });

                        } else {
                            setDoneEvent(data);
                        }
                    });
                }
            });
        }
    }, [selectedUlCode]);

    //Get event date
    useEffect(() => {
        setIsEventDatePast(false);
        const db = firebase.firestore();
        const vanDateMapping = db.collection('van_date_mapping');
        if (selectedUlCode) {
            vanDateMapping
                .where('ul_code', '==', selectedUlCode)
                .get()
                .then((querySnapshot) => {
                    if (!querySnapshot.empty) {
                        const data = querySnapshot.docs.map((doc) => doc.data())[0];
                        const timestamp = data.start_date;
                        const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6;
                        const givenDate = dayjs(milliseconds).startOf('day');

                        // Get the current date
                        let currentDate = dayjs().startOf('day');
                        // Calculate the difference in days
                        const daysDifference = givenDate.diff(currentDate, 'day');

                        // older days
                        if (daysDifference < 0) {
                            setIsEventDatePast(true)
                        }

                        // today's date with forenoon slot
                        if (data.slot.includes('Forenoon') && daysDifference === 0) {
                            setIsEventDatePast(true);
                        }

                        // today's date with afternoon slot
                        if (data.slot.includes('Afternoon') && daysDifference === 0 && new Date().getHours() > 12) {
                            setIsEventDatePast(true);
                        }
                    }
                })
                .catch((error) => {
                    console.log('Error getting document:', error);
                });
        }
    }, [selectedUlCode]);

    useEffect(() => {
        const db = firebase.firestore();
        const userDetail = db.collection('users_detail');

        if (profileReducer.info.phone_number) {
            userDetail
                .where('mobile', '==', profileReducer.info.phone_number)
                .get()
                .then((querySnapshot) => {
                    if (!querySnapshot.empty) {
                        const data = querySnapshot.docs.map((doc) => doc.data());
                        const filteredUlOptions = data
                            .map((item) => ({
                                label: item.ul_name,
                                value: item.ul_code,
                                ulb_name: item.ulb_name,
                            }));
                        setUlOptions(filteredUlOptions);
                    }
                })
                .catch((error) => {
                    console.log('Error getting document:', error);
                });
        }
    }, [profileReducer]);

    const handleSelectedUL = (selectedValue, setFieldValue) => {
        setFieldValue('event_date', '');
        // const selectedOption = ulOptions.find((option) => option.label === selectedValue);

        const data = JSON.parse(selectedValue)
        if (data?.value) {
            updateEventBasedOnSelectedUL(data?.value);
            const updatedEvent = {
                ...event,
                ul_name: data?.label,
                ul_code: data?.value,
            };
            setEvent(updatedEvent);
            setSelectedUlName(data);
            setSelectedUlCode(data?.value);
        }
    };

    const updateEventBasedOnSelectedUL = (selectedULCode) => {
        const selectedULInfo = profileDetail.info.find((item) => item.ul_code === selectedULCode);
        setFilteredEvent(selectedULInfo);
    };

    const handleSubmit = async (values, { setSubmitting, resetForm },) => {
        setIsLoading(true);
        const db = firebase.firestore();
        const eventsCollection = db.collection('events');
        const activitiesCollection = db.collection('activities');

        try {
            let querySnapshot;
            querySnapshot = await eventsCollection.where('ul_code', '==', selectedUlCode).get();

            if (!querySnapshot.empty) {
                // Update the first matching document (you can handle multiple matches if needed)
                const docRef = querySnapshot.docs[0].ref;

                let keys = Object.keys(values)
                for (let i = 0; i < keys.length; i++) {
                    const activityQuerySnapshot = await activitiesCollection
                        .where('event_id', '==', docRef.id)
                        .where('ul_code', '==', selectedUlCode)
                        .where('category', '==', 'van_checklist')
                        .where('key_name', '==', keys[i])
                        .get();
                    if (!activityQuerySnapshot.empty) {
                        const activityDocRef = activityQuerySnapshot.docs[0].ref;
                        await activityDocRef.update({
                            key_value: values[keys[i]],
                            updated_at: Date.now(),
                            updated_by: profileReducer.info.phone_number,
                        });
                        continue;
                    }
                    const activityData = {
                        state_code: filteredEvent.state_code || "",
                        state_name: filteredEvent.state_name || "",
                        district_name: filteredEvent.district_name || "",
                        district_code: filteredEvent.district_code || "",
                        block_name: filteredEvent.block_name || "",
                        block_code: filteredEvent.block_code || "",
                        ul_name: selectedUlName?.label || '',
                        ul_code: selectedUlCode || '',
                        ulb_name: filteredEvent.ulb_name || "",
                        ulb_code: filteredEvent.ulb_code || "",
                        gp_name: filteredEvent.gp_name || '',
                        gp_code: filteredEvent.gp_code || '',
                        category: 'van_checklist',
                        event_id: docRef.id,
                        created_by: profileReducer.info.phone_number || "",
                        created_at: Date.now(),
                        user_type: 'NON_PUBLIC',
                        location_type: profileReducer?.info?.location_type || '',
                        key_name: keys[i],
                        key_value: values[keys[i]],
                        phase: 'PHASE1',
                        key_label: nameToLabelMap(keys[i])
                    };
                    try {
                        await activitiesCollection.add(activityData);
                    } catch (error) {
                        console.error('Error saving data:', error);
                    }
                }

                await docRef.update({
                    van_checklist_done: true,
                });

                setAlertSeverity('success');
                setAlertMessage(`${t('van_checklist_updated_successfully')}`);
                setIsAlertOpen(true);
                // Reset the form
                resetForm();
                setIsLoading(false);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                // No matching document found; show an alert
                setAlertSeverity('error');
                setAlertMessage(`${t('event_not_geo_tagged_yet')}`);
                setIsAlertOpen(true);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error updating or checking document:', error);
            setAlertSeverity('error');
            setAlertMessage(`${t('something_went_wrong')}`);
            setIsAlertOpen(true);
            setIsLoading(false);
        } finally {
            setSubmitting(false);
            setIsLoading(false);
        }
    };

    const handleAlertClose = () => {
        setIsAlertOpen(false);
    };

    return (
        <div>
            <Snackbar open={isAlertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert variant="filled" severity={alertSeverity} onClose={handleAlertClose}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Formik
                initialValues={event}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    handleSubmit(values, actions);
                }}
            >
                {({ setFieldValue, resetForm, values }) => (
                    <Form>
                        <Card
                            sx={{
                                p: 0,
                            }}
                        >
                            <Box
                                sx={{
                                    padding: '15px 30px',
                                }}
                                display="flex"
                                alignItems="center"
                            >
                                <Box flexGrow={1}>
                                    <Typography fontWeight="500" variant="h4">
                                        {t('van_checklist')}
                                    </Typography>
                                </Box>
                            </Box>

                            <Divider />
                            <CardContent
                                sx={{
                                    padding: '30px',
                                }}
                            >
                                <Grid item lg={12} xs={12}>
                                    <>
                                        <CustomFormLabel htmlFor='ul_name'>
                                            {t('select_ul')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomSelect}
                                            fullWidth
                                            id='ul_name'
                                            variant='outlined'
                                            name='ul_name'
                                            size='small'
                                            sx={{
                                                mb: 2,
                                            }}
                                            onChange={(e) => {
                                                handleSelectedUL(e.target.value, setFieldValue);
                                            }}
                                            value={JSON.stringify(selectedUlName)}
                                            required
                                        >
                                            {ulOptions?.map((option) => (
                                                <MenuItem key={option.value} value={JSON.stringify(option)}>
                                                    {`${option.label} (${option?.ulb_name})`}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                        <ErrorMessage name='ul_name' component='div' className='error-message' />
                                    </>
                                </Grid>
                                <Grid container spacing={1}>
                                    {!isEventDatePast &&
                                        selectedUlName && (
                                            <Grid item xs={12}>
                                                <Box pt={2}>
                                                    <Typography variant="body2" color="error">
                                                        {t('data_cannot_be_uploaded_as_the_event_is_of_future_date_and_afternoon_slot')}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        )}

                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="branding_with_qr_code_available"
                                        >
                                            {nameToLabelMap('branding_with_qr_code_available')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomSelect}
                                            fullWidth
                                            id="branding_with_qr_code_available"
                                            variant="outlined"
                                            name="branding_with_qr_code_available"
                                            size="small"
                                            sx={{
                                                mb: 2,
                                            }}
                                        >
                                            <MenuItem value={true}>{t('Yes')}</MenuItem>
                                            <MenuItem value={false}>{t('No')}</MenuItem>
                                        </Field>
                                        <ErrorMessage
                                            name="branding_with_qr_code_available"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="master_of_ceremony_present"
                                        >
                                            {nameToLabelMap('master_of_ceremony_present')}
                                            < sup >*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomSelect}
                                            fullWidth
                                            id="master_of_ceremony_present"
                                            variant="outlined"
                                            name="master_of_ceremony_present"
                                            size="small"
                                            sx={{
                                                mb: 2,
                                            }}
                                        >
                                            <MenuItem value={true}>{t('Yes')}</MenuItem>
                                            <MenuItem value={false}>{t('No')}</MenuItem>
                                        </Field>
                                        <ErrorMessage
                                            name="master_of_ceremony_present"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="functional_led_screen"
                                        >
                                            {nameToLabelMap('functional_led_screen')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomSelect}
                                            fullWidth
                                            id="functional_led_screen"
                                            variant="outlined"
                                            name="functional_led_screen"
                                            size="small"
                                            sx={{
                                                mb: 2,
                                            }}
                                        >
                                            <MenuItem value={true}>{t('Yes')}</MenuItem>
                                            <MenuItem value={false}>{t('No')}</MenuItem>
                                        </Field>
                                        <ErrorMessage
                                            name="functional_led_screen"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="function_generator"
                                        >
                                            {nameToLabelMap('function_generator')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomSelect}
                                            fullWidth
                                            id="function_generator"
                                            variant="outlined"
                                            name="function_generator"
                                            size="small"
                                            sx={{
                                                mb: 2,
                                            }}
                                        >
                                            <MenuItem value={true}>{t('Yes')}</MenuItem>
                                            <MenuItem value={false}>{t('No')}</MenuItem>
                                        </Field>
                                        <ErrorMessage
                                            name="function_generator"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="cordless_mic"
                                        >
                                            {nameToLabelMap('cordless_mic')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomSelect}
                                            fullWidth
                                            id="cordless_mic"
                                            variant="outlined"
                                            name="cordless_mic"
                                            size="small"
                                            sx={{
                                                mb: 2,
                                            }}
                                        >
                                            <MenuItem value={true}>{t('Yes')}</MenuItem>
                                            <MenuItem value={false}>{t('No')}</MenuItem>
                                        </Field>
                                        <ErrorMessage
                                            name="cordless_mic"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="sound_system"
                                        >
                                            {nameToLabelMap('sound_system')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomSelect}
                                            fullWidth
                                            id="sound_system"
                                            variant="outlined"
                                            name="sound_system"
                                            size="small"
                                            sx={{
                                                mb: 2,
                                            }}
                                        >
                                            <MenuItem value={true}>{t('Yes')}</MenuItem>
                                            <MenuItem value={false}>{t('No')}</MenuItem>
                                        </Field>
                                        <ErrorMessage
                                            name="sound_system"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="standies"
                                        >
                                            {nameToLabelMap('standies')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomSelect}
                                            fullWidth
                                            id="standies"
                                            variant="outlined"
                                            name="standies"
                                            size="small"
                                            sx={{
                                                mb: 2,
                                            }}
                                        >
                                            <MenuItem value={true}>{t('Yes')}</MenuItem>
                                            <MenuItem value={false}>{t('No')}</MenuItem>
                                        </Field>
                                        <ErrorMessage
                                            name="standies"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="selfie_point"
                                        >
                                            {nameToLabelMap('selfie_point')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomSelect}
                                            fullWidth
                                            id="selfie_point"
                                            variant="outlined"
                                            name="selfie_point"
                                            size="small"
                                            sx={{
                                                mb: 2,
                                            }}
                                        >
                                            <MenuItem value={true}>{t('Yes')}</MenuItem>
                                            <MenuItem value={false}>{t('No')}</MenuItem>
                                        </Field>
                                        <ErrorMessage
                                            name="selfie_point"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="flood_light"
                                        >
                                            {nameToLabelMap('flood_light')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomSelect}
                                            fullWidth
                                            id="flood_light"
                                            variant="outlined"
                                            name="flood_light"
                                            size="small"
                                            sx={{
                                                mb: 2,
                                            }}
                                        >
                                            <MenuItem value={true}>{t('Yes')}</MenuItem>
                                            <MenuItem value={false}>{t('No')}</MenuItem>
                                        </Field>
                                        <ErrorMessage
                                            name="flood_light"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="wifi"
                                        >
                                            {nameToLabelMap('wifi')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomSelect}
                                            fullWidth
                                            id="wifi"
                                            variant="outlined"
                                            name="wifi"
                                            size="small"
                                            sx={{
                                                mb: 2,
                                            }}
                                        >
                                            <MenuItem value={true}>{t('Yes')}</MenuItem>
                                            <MenuItem value={false}>{t('No')}</MenuItem>
                                        </Field>
                                        <ErrorMessage
                                            name="wifi"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="gps_tracker_installed"
                                        >
                                            {nameToLabelMap('gps_tracker_installed')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomSelect}
                                            fullWidth
                                            id="gps_tracker_installed"
                                            variant="outlined"
                                            name="gps_tracker_installed"
                                            size="small"
                                            sx={{
                                                mb: 2,
                                            }}
                                        >
                                            <MenuItem value={true}>{t('Yes')}</MenuItem>
                                            <MenuItem value={false}>{t('No')}</MenuItem>
                                        </Field>
                                        <ErrorMessage
                                            name="gps_tracker_installed"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                </Grid>

                                <Box pt={2} textAlign="center">
                                    {isEventDatePast ? (
                                        <Box pt={2} textAlign="center">
                                            <LoadingButton
                                                loading={isLoading}
                                                color="primary"
                                                variant="contained"
                                                type="submit"
                                            >
                                                Save as Draft
                                            </LoadingButton>
                                        </Box>
                                    ) : (
                                        !isEventDatePast &&
                                        selectedUlName && (
                                            <Typography variant="body2" color="error">
                                                {t('data_cannot_be_uploaded_as_the_event_is_of_future_date_and_afternoon_slot')}
                                            </Typography>
                                        )
                                    )}
                                </Box>
                            </CardContent>
                        </Card>
                    </Form>
                )}
            </Formik>

            {
                doneEvent?.van_checklist_done && (
                    <Card
                        sx={{
                            p: 0,
                        }}
                    >
                        <Box
                            sx={{
                                padding: '15px 30px',
                            }}
                            display="flex"
                            alignItems="center"
                        >
                            <Box flexGrow={1}>
                                <Typography fontWeight="500" variant="h4">
                                    {t('submitted_data')}
                                </Typography>
                            </Box>
                        </Box>

                        <Divider />
                        <CardContent
                            sx={{
                                padding: '30px',
                            }}
                        >
                            <Grid container spacing={1}>
                                <Grid item lg={12} xs={12}>
                                    <CustomFormLabel
                                        sx={{
                                            mt: 2,
                                        }}
                                        htmlFor="ul_name"
                                    >
                                        {doneEvent.ul_name}
                                    </CustomFormLabel>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('branding_with_qr_code_available')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="branding_with_qr_code_available"
                                        >
                                            - {doneEvent.branding_with_qr_code_available ? 'True' : 'False'}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('master_of_ceremony_present')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="master_of_ceremony_present"
                                        >
                                            - {doneEvent.master_of_ceremony_present ? 'True' : 'False'}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('functional_led_screen')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="functional_led_screen"
                                        >
                                            - {doneEvent.functional_led_screen ? 'True' : 'False'}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('function_generator')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="function_generator"
                                        >
                                            - {doneEvent.function_generator ? 'True' : 'False'}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('cordless_mic')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="cordless_mic"
                                        >
                                            - {doneEvent.cordless_mic ? 'True' : 'False'}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('sound_system')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="sound_system"
                                        >
                                            - {doneEvent.sound_system ? 'True' : 'False'}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('standies')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="standies"
                                        >
                                            - {doneEvent.standies ? 'True' : 'False'}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('selfie_point')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="selfie_point"
                                        >
                                            - {doneEvent.selfie_point ? 'True' : 'False'}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('flood_light')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="flood_light"
                                        >
                                            - {doneEvent.flood_light ? 'True' : 'False'}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('wifi')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="wifi"
                                        >
                                            - {doneEvent.wifi ? 'True' : 'False'}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('gps_tracker_installed')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="gps_tracker_installed"
                                        >
                                            - {doneEvent.gps_tracker_installed ? 'True' : 'False'}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )
            }
        </div >
    );
};

export default VanChecklist;
