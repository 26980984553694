//type of users login wise

export const userTypes = {
  super_admin: [
    { name: 'State In-charge (Prabharis)', key: 'state_in_charge_login' },
    { name: 'District In-charge (Prabharis)', key: 'district_in_charge_login' },
    { name: 'Central Nodals of Central Agencies', key: 'central_nodal_agency_login' },
    { name: 'State Nodals of Central Agencies', key: 'state_nodal_agency_login' },
    { name: 'District Nodals of Central Agencies', key: 'district_nodal_agency_login' },
    { name: 'State Line (Scheme) Departments', key: 'state_line_login' },
    { name: 'State Level Committe (Co-Convenors)', key: 'state_committe_login' },
    { name: 'District Level Officer of Line (Scheme) Departments', key: 'district_line_login' },
    { name: 'District Level Committee (Co-Convenors)', key: 'district_committe_login' },
    { name: 'District Nodal Officer', key: 'district_login' },
    { name: 'ULB Nodal Officer', key: 'urban_nodal_login' },
    { name: 'Day Nodal Officer', key: 'day_login' },
    { name: 'Van Nodal Officer', key: 'van_login' },
    { name: 'Block Line Departments', key: 'block_line_login' },
    { name: 'Block Level Committe (Co-Convenors)', key: 'block_committe_login' },
    { name: 'GP Level Committe (Co-Convenors)', key: 'gp_committe_login' },
    { name: 'Utsav Committe ', key: 'block_utsav_committe_login' },
    { name: 'Swagat Committe ', key: 'block_swagat_committe_login' },
    { name: 'Block Level Officer', key: 'block_level_login' },
    { name: 'GP Level Field Officer', key: 'gp_field_officer_login' },
    { name: 'VBSY Event Observer', key: 'vbsy_event_observer' },
    { name: 'Central Nodals of Central Agencies', key: 'central_nodal_agency_login' },
    { name: 'State Nodals of Central Agencies', key: 'state_nodal_agency_login' },
  ],
  central_in_charge_login: [
    { name: 'State In-charge (Prabharis)', key: 'state_in_charge_login' },
    { name: 'District In-charge (Prabharis)', key: 'district_in_charge_login' },
    { name: 'Central Nodals of Central Agencies', key: 'central_nodal_agency_login' },
  ],
  central_nodal_officer_login: [
    { name: 'VBSY Event Observer', key: 'vbsy_event_observer' },
  ],
  master_login: [
    { name: 'VBSY Event Observer', key: 'vbsy_event_observer' },
  ],
  central_nodal_agency_login: [
    { name: 'State Nodals of Central Agencies', key: 'state_nodal_agency_login' },
  ],
  state_nodal_agency_login: [
    { name: 'District Nodals of Central Agencies', key: 'district_nodal_agency_login' },
  ],
  district_nodal_agency_login: [
    { name: 'Day Nodal Officer', key: 'day_login' },
    { name: 'GP Nodal Officer Of Central Agency', key: 'gp_nodal_officer_central_agency' },
  ],
  state_nodal_login: [
    // { name: 'State Line (Scheme) Departments', key: 'state_line_login' },
    { name: 'State Level Committe (Co-Convenors)', key: 'state_committe_login' },
    { name: 'District Collector', key: 'district_nodal_login' },
  ],
  district_nodal_login: [
    // { name: 'District Level Officer of Line (Scheme) Departments', key: 'district_line_login' },
    { name: 'District Level Committee (Co-Convenors)', key: 'district_committe_login' },
    { name: 'District Nodal Officer', key: 'district_login' },
    { name: 'ULB Nodal Officer', key: 'urban_nodal_login' },
    { name: 'Day Nodal Officer', key: 'day_login' },
    // { name: 'Van Nodal Officer', key: 'van_login' },
  ],

  district_nodal_login_delhi: [
    // { name: 'District Level Officer of Line (Scheme) Departments', key: 'district_line_login' },
    // { name: 'District Level Committee (Co-Convenors)', key: 'district_committe_login' },
    // { name: 'District Nodal Officer', key: 'district_login' },
    { name: 'ULB Nodal Officer', key: 'urban_nodal_login' },
    // { name: 'Day Nodal Officer', key: 'day_login' },
    // { name: 'Van Nodal Officer', key: 'van_login' },
  ],

  district_nodal_login_other: [
    // { name: 'District Level Officer of Line (Scheme) Departments', key: 'district_line_login' },
    // { name: 'District Level Committee (Co-Convenors)', key: 'district_committe_login' },
    // { name: 'District Nodal Officer', key: 'district_login' },
    { name: 'ULB Nodal Officer', key: 'urban_nodal_login' },
    // { name: 'Day Nodal Officer', key: 'day_login' },
    // { name: 'Van Nodal Officer', key: 'van_login' },
  ],
  block_nodal_login: [
    { name: 'Block Line Departments', key: 'block_line_login' },
    { name: 'Block Level Committe (Co-Convenors)', key: 'block_committe_login' },
    { name: 'GP Level Committe (Co-Convenors)', key: 'gp_committe_login' },
    { name: 'Utsav Committe ', key: 'block_utsav_committe_login' },
    { name: 'Swagat Committe ', key: 'block_swagat_committe_login' },
    { name: 'Block Level Officer', key: 'block_level_login' },
  ],
  urban_nodal_login: [
    { name: 'Line Scheme Officers', key: 'line_scheme_officer' },
    { name: 'Day Urban Camp Officer ', key: 'camp_nodal_officer' },
  ],

  block_level_login: [{ name: 'GP Level Field Officer', key: 'gp_field_officer_login' }],

  cbc_hlcc: [{ key: 'executive_ib', name: 'Executive I&B Officer' }],
  state_ib: [
    // { name: 'State Line (Scheme) Departments', key: 'state_line_login' },
    { name: 'State Level Committe', key: 'state_committe_login' },
    { name: 'District Nodal Officers I&B', key: 'district_ib' },
    { name: 'Urban Van Supervisor-MC', key: 'urban_van_supervisor' },

  ],
  mohua: [
    { name: 'Municipal Commissioner', key: 'municipal_user' },
    { name: 'ULB Nodal Officer', key: 'urban_nodal_login' },
    { name: 'District Collector - Delhi', key: 'district_nodal_login_delhi' },
    { name: 'District Collector- (Other States/ULB)', key: 'district_nodal_login_other' },
    { key: 'state_urban_nodal', name: 'State Urban Development Nodal Officers' },
    { key: 'district_nodal_urban_agency', name: 'District Nodal Officer-Urban' },
    // { name: 'Municipal Commissioner', key: 'municipal_user' },

  ]
  ,
  municipal_user: [
    { name: 'ULB Nodal Officer', key: 'urban_nodal_login' },
  ],
  district_nodal_urban_agency: [
    { name: 'ULB Nodal Officer', key: 'urban_nodal_login' },
    { name: 'Day Nodal Officer-Urban', key: 'camp_nodal_officer' },
  ]
  , line_scheme_officer: [
    { key: 'urban_camp_executive', name: 'Urban Camp Executive' },
  ],

};