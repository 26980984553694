import firebase from '../../../../firebase';
import { Timestamp } from 'firebase/firestore';

export function formatTimestampObjectToDateString(timestampObj) {
    if (!timestampObj || typeof timestampObj.seconds !== 'number') {
      console.error('Invalid or missing Timestamp object:', timestampObj);
      return ''; // Handle the error gracefully
    }

    // Create a Firebase Timestamp object
    const timestamp = new firebase.firestore.Timestamp(
      timestampObj.seconds,
      timestampObj.nanoseconds
    );

    // Convert the Firebase Timestamp to a JavaScript Date object
    const jsDate = timestamp.toDate();

    // Extract the day, month, and year components
    const day = jsDate.getDate();
    const month = jsDate.getMonth() + 1; // Month is zero-based, so add 1
    const year = jsDate.getFullYear();

    // Ensure single-digit day and month are formatted with leading zeros
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');

    // Create the date string in "DD/MM/YYYY" format
    const dateString = `${formattedMonth}/${formattedDay}/${year}`;

    return dateString;
  }




  export function formatFirebaseTimestampToDateString(firebaseTimestamp) {
    // Convert Firebase Timestamp to a JavaScript Date object
    const jsDate = firebaseTimestamp.toDate();

    // Extract the day, month, and year components
    const day = jsDate.getDate();
    const month = jsDate.getMonth() + 1; // Month is zero-based, so add 1
    const year = jsDate.getFullYear();

    // Ensure single-digit day and month are formatted with leading zeros
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');

    // Create the date string in "DD/MM/YYYY" format
    return `${ formattedDay }/${ formattedMonth }/${ year }`;
  }

export const formatFirebaseTimestampToDateStringForCalendar = (timestamp) => {
  // Check if timestamp is a Firebase Timestamp
  if (!(timestamp instanceof Timestamp)) {
    throw new Error('Invalid timestamp format');
  }

  // Convert Firebase Timestamp to JavaScript Date
  const jsDate = timestamp.toDate();

  // Format the date as needed for your calendar
  const year = jsDate.getFullYear();
  const month = String(jsDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(jsDate.getDate()).padStart(2, '0');
  const hours = String(jsDate.getHours()).padStart(2, '0');
  const minutes = String(jsDate.getMinutes()).padStart(2, '0');

  // Example format: "2023-11-17 10:00:00" without the 'T'
  return `${year}-${month}-${day} ${hours}:${minutes}:00`;
};

export const convertToIST = (obj) => {
  // Extract seconds from the object
  const timestampSeconds = obj.seconds;

  // Convert to milliseconds (JavaScript uses milliseconds)
  const timestampMilliseconds = timestampSeconds * 1000;

  // Create a new Date object
  const date = new Date(timestampMilliseconds);

  // Adjust the date to IST (UTC+5:30)
  date.setUTCHours(date.getUTCHours() + 5);
  date.setUTCMinutes(date.getUTCMinutes() + 30);

  // Format the date as a string
  return date.toISOString().replace(/T/, ' ').replace(/\..+/, '');
}


  export function findAvailableDate(mainDate, datesArray) {
    let availableStartDate = mainDate.start_date;
    let availableEndDate = mainDate.end_date;

    for (const dateRange of datesArray) {
      if (new Date(dateRange.end_date) >= new Date(availableStartDate)) {
        const endDate = new Date(dateRange.end_date);
        endDate.setDate(endDate.getDate() + 1);
        availableStartDate = endDate.toLocaleDateString('en-US');
      }
    }

    const availableDateRange = { start_date: availableStartDate, end_date: availableEndDate };
    return availableDateRange;
  }


  export function dateDifferenceInDays(date1, date2) {

    // Convert both dates to UTC to ensure consistent results
    const utcDate1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const utcDate2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

    // Calculate the difference in milliseconds and convert to days
    const millisecondsInADay = 24 * 60 * 60 * 1000;
    const differenceInMilliseconds = Math.abs(utcDate2 - utcDate1);
    const differenceInDays = Math.floor(differenceInMilliseconds / millisecondsInADay);

    return differenceInDays;
  }
