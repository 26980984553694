import React from 'react';
import { Grid, Box, Card, CardContent, Typography, Button, Avatar } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import profilecover from '../../assets/images/backgrounds/profilebg.jpg';
import {
  GoogleMap,
  Marker,
  LoadScript,
} from '@react-google-maps/api';
import userimg from '../../assets/images/users/user2.jpg';

const CoverCard = ({event}) => (
  <Card
    sx={{
      padding: '0',
    }}
  >

<LoadScript googleMapsApiKey="AIzaSyA93xR8JywwU_tDrKPfpSjqM5TDDQ8i_FE" >
              <GoogleMap
                mapContainerStyle={{ width: '100%', height: '250px' }}
                center={{ lat: event.latitude, lng: event.longitude }}
                zoom={ 12 }                
              >
           
                  <Marker position={ {lat: event.latitude, lng: event.longitude} } />
               
                  </GoogleMap>
                </LoadScript>
    {/* <img srcSet={`${profilecover} 1x, ${profilecover} 2x`} alt={profilecover} width="100%" /> */}
    
    <CardContent
      sx={{
        pt: '24px',
        pb: '28px',
      }}
    >
      <Grid container spacing={0}>
        <Grid
          item
          lg={4}
          sm={12}
          xs={12}
          sx={{
            order: {
              xs: '2',
              sm: '2',
              lg: '1',
            },
          }}
        >
          <h1>{event?.gp_name}</h1>
          <p>{event?.state_name}{event?.district_name && <span>,{event?.district_name}</span>}</p>
        </Grid>
        {/* about profile */}
        <Grid
          item
          lg={4}
          sm={12}
          xs={12}
          sx={{
            order: {
              xs: '1',
              sm: '1',
              lg: '2',
            },
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              mt: '-90px',
            }}
          >
           
          </Box>
        </Grid>
        {/* friends following buttons */}
        {/* <Grid
          item
          lg={4}
          sm={12}
          xs={12}
          display="flex"
          alignItems="center"
          sx={{
            justifyContent: {
              sm: 'center',
              lg: 'flex-end',
            },
            mt: {
              sm: 2,
              lg: 0,
            },
            ml: {
              sm: 4,
              lg: 0,
            },
            order: {
              xs: '3',
              sm: '3',
              lg: '3',
            },
          }}
        >
          <Grid
            container
            spacing={0}
            sx={{
              mt: {
                xs: 1,
              },
              mb: {
                xs: 1,
              },
            }}
          >
            <Grid
              item
              lg={4}
              sm={4}
              xs={4}
              sx={{
                textAlign: 'center',
              }}
            >
              <Typography
                sx={{
                  color: (theme) => theme.palette.grey.A200,
                }}
              >
                <FeatherIcon icon="file-text" width="20" />
              </Typography>
              <Typography
                variant="h4"
                fontWeight="600"
                sx={{
                  lineHeight: '1.2',
                }}
              >
                {event?.state_name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="h5"
                fontWeight="400"
                sx={{
                  lineHeight: '1.2',
                }}
              >
                State
              </Typography>
            </Grid>
            <Grid
              item
              lg={4}
              sm={4}
              xs={4}
              sx={{
                textAlign: 'center',
              }}
            >
              <Typography
                sx={{
                  color: (theme) => theme.palette.grey.A200,
                }}
              >
                <FeatherIcon icon="user-check" width="20" />
              </Typography>
              <Typography
                variant="h4"
                fontWeight="600"
                sx={{
                  lineHeight: '1.2',
                }}
              >
               {event?.district_name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="h5"
                fontWeight="400"
                sx={{
                  lineHeight: '1.2',
                }}
              >
                District
              </Typography>
            </Grid>
            <Grid
              item
              lg={4}
              sm={4}
              xs={4}
              sx={{
                textAlign: 'center',
              }}
            >
              <Typography
                sx={{
                  color: (theme) => theme.palette.grey.A200,
                }}
              >
            <FeatherIcon icon="users" width="20" /> 
              </Typography>
              <Typography
                variant="h4"
                fontWeight="600"
                sx={{
                  lineHeight: '1.2',
                }}
              >
                {event?.block_name ? event.block_name : event.ulb_name && event.ulb_name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="h5"
                fontWeight="400"
                sx={{
                  lineHeight: '1.2',
                }}
              >
                {event?.block_name ? 'Block' : event.ulb_name && 'ULB'}
              </Typography>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </CardContent>
  </Card>
);

export default CoverCard;
