import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { Menu, MenuItem, Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { Select } from '@mui/material';
import { useSelector } from 'react-redux';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import Goi from '../../assets/images/logos/goi.png';
import Emblem from '../../assets/images/logos/emblem.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './header.css';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import twitter from '../../assets/images/logos/twitter.svg';
import InstagramIcon from '@mui/icons-material/Instagram';
import { YouTube } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { deepOrange } from '@mui/material/colors';

const drawerWidth = 240;

const Navbar = ({ window, handleNavigate }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorElCitizen, setAnchorElCitizen] = React.useState(null);
  const [anchorElPM, setAnchorElPM] = React.useState(null);
  const [anchorElGlimpse, setAnchorElGlimpse] = React.useState(null);
  const profileReducer = useSelector((state) => state.ProfileReducer);
  const openCitizen = Boolean(anchorElCitizen);
  const openPM = Boolean(anchorElPM);
  const openGlimpse = Boolean(anchorElGlimpse);
  const handleClickCitizen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorElCitizen(event.currentTarget);
  };

  const handleClickGlimpse = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorElGlimpse(event.currentTarget);
  };

  const handleClickPM = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorElPM(event.currentTarget);
  };
  const handleCloseCitizen = () => {
    setAnchorElCitizen(null);
  };

  const handleClosePM = () => {
    setAnchorElPM(null);
  };

  const handleCloseGlimpse = () => {
    setAnchorElGlimpse(null);
  };

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const handleNavigateToHome = () => {
    navigate('/');
  };
  const handleNavigateToLogin = () => {
    navigate('/auth/login');
  };

  const handleNavigateToEventsCalendar = () => {
    navigate('/public-events');
  };

  const handleNavigateToMCScript = () => {
    navigate('/mc-script');
  };

  const handleNavigateToEvents = () => {
    navigate('/public-event-list');
  };

  const handleNavigateToUploadEvent = () => {
    navigate('/public-event-media');
  };

  const handleNavigateToUploadSelfie = () => {
    navigate('/upload-selfie');
  };

  const handleNavigateToSelfieWithPM = () => {
    navigate('/selfie-with-pm');
  };

  const handleNavigateToAbout = () => {
    navigate('/about');
  };

  const handleNavigateToDownload = () => {
    navigate('/public-download');
  };

  const handleNavigateToFlag = () => {
    navigate('/flagship');
  };

  const handleNavigateToQuiz = () => {
    navigate('/quiz');
  };

  const handleNavigateToPhotos = () => {
    navigate('/photo');
  };

  const handleNavigateToVideos = () => {
    navigate('/video');
  };

  const handleNavigateToSelfies = () => {
    navigate('/selfie');
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <img src={Emblem} alt="Logo" width={150} />
      <Divider />
      <List>
        {/*<ListItem disablePadding>*/}
        {/*  <ListItemButton sx={ {textAlign: 'center'} }>*/}
        {/*    <ListItemText primary={ 'Events' }/>*/}
        {/*  </ListItemButton>*/}
        {/*</ListItem>*/}
        {/*<ListItem disablePadding>*/}
        {/*  <ListItemButton sx={ {textAlign: 'center'} }>*/}
        {/*    <ListItemText primary={ 'Home' }/>*/}
        {/*  </ListItemButton>*/}
        {/*</ListItem>*/}
        {/*<ListItem disablePadding>*/}
        {/*  <ListItemButton sx={ {textAlign: 'center'} }>*/}
        {/*    <ListItemText primary={ 'About' }/>*/}
        {/*  </ListItemButton>*/}
        {/*</ListItem>*/}
        {/*<ListItem disablePadding>*/}
        {/*  <ListItemButton sx={ {textAlign: 'center'} }>*/}
        {/*    <ListItemText primary={ 'Contact Us' }/>*/}
        {/*  </ListItemButton>*/}
        {/*</ListItem>*/}
        {/*<ListItem disablePadding>*/}
        {/*  <ListItemButton sx={ {textAlign: 'center'} }>*/}
        {/*    <ListItemText primary={ 'downloads' }/>*/}
        {/*  </ListItemButton>*/}
        {/*</ListItem>*/}
        {/*<ListItem disablePadding>*/}
        {/*  <ListItemButton sx={ {textAlign: 'center'} }>*/}
        {/*    <ListItemText primary={ 'Reports' }/>*/}
        {/*  </ListItemButton>*/}
        {/*</ListItem>*/}

        {/* <Stack direction="column" spacing={2} p={1}> */}
        {/* <Button
            variant="contained"
            sx={{ minWidth: '90px', background: '#DB7600' }}
            onClick={handleNavigateToEvents}
          >
            {t('view_events')}
          </Button> */}
        {/* </Stack> */}
        {/* <Stack direction="column" spacing={2} p={1}>
          <Button
            variant="contained"
            sx={{ background: '#DB7600' }}
            onClick={handleNavigateToUploadEvent}
          >
            {t('upload_event_photo')}
          </Button>
        </Stack> */}
        <Stack direction="column" spacing={2} p={1}>
          <Button
            variant="contained"
            sx={{ minWidth: '90px', background: 'transparent', color: '#000' }}
            onClick={handleNavigateToHome}
          >
            {t('home')}
          </Button>
        </Stack>
        <Stack direction="column" spacing={2} p={1}>
          <Button
            variant="contained"
            sx={{ minWidth: '90px', background: 'transparent', color: '#000' }}
            onClick={handleNavigateToAbout}
          >
            {t('about')}
          </Button>
        </Stack>
        {/* <Stack direction="column" spacing={2} p={1}>
          <Button
            className="header-links"
            variant="contained"
            sx={{ background: 'transparent' }}
            id="basic-button1"
            aria-controls={open ? 'basic-menu1' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClickCitizen}
          >
            {t('citizen_activities')}
          </Button>
          <Menu
            id="basic-menu1"
            anchorEl={anchorElCitizen}
            open={openCitizen}
            onClose={handleCloseCitizen}
            MenuListProps={{
              'aria-labelledby': 'basic-button1',
            }}
          >
            {/* <MenuItem>
              <a
                href="https://pledge.mygov.in/viksit-bharat/"
                target="_blank"
                style={{
                  textDecoration: 'none',
                  color: 'black',
                }}
              >
                {t('sankalp_pledge')}
              </a>
            </MenuItem> */}
        {/* <MenuItem onClick={handleNavigateToQuiz}>{t('quiz')}</MenuItem>
            <MenuItem onClick={handleNavigateToUploadEvent}>{t('upload_event')}</MenuItem>
            <MenuItem onClick={handleNavigateToUploadSelfie}>
              {t('upload_selfie_at_event_location')}
            </MenuItem> */}
        {/* <MenuItem onClick={handleNavigateToSelfieWithPM}>{t('selfie_with_pm')}</MenuItem> */}
        {/* </Menu> */}
        {/* </Stack> */}

        <Stack direction="column" spacing={2} p={1}>
          <div>
            <Button
              className="header-links"
              variant="container"
              sx={{ background: 'transparent' }}
              id="basic-button1"
              aria-controls={open ? 'basic-menu1' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClickGlimpse}
            >
              {t('event_glimpses')}
            </Button>
            <Menu
              id="basic-menu1"
              anchorEl={anchorElGlimpse}
              open={openGlimpse}
              onClose={handleCloseGlimpse}
              MenuListProps={{
                'aria-labelledby': 'basic-button1',
              }}
            >
              <MenuItem onClick={handleNavigateToPhotos}>{t('photos')}</MenuItem>
              <MenuItem onClick={handleNavigateToVideos}>{t('videos')}</MenuItem>
              <MenuItem onClick={handleNavigateToSelfies}>{t('selfies')}</MenuItem>
            </Menu>
          </div>
        </Stack>
        <Stack direction="column" spacing={2} p={1}>
          <Button
            variant="contained"
            sx={{ minWidth: '90px', background: 'transparent', color: '#000' }}
            onClick={handleNavigateToDownload}
          >
            {t('download')}
          </Button>
        </Stack>
        {/*{profileReducer?.logged_in ? (*/}
        {/*  <></>*/}
        {/*) : (*/}
        {/*  <Stack direction="column" spacing={2} p={1}>*/}
        {/*    <Button*/}
        {/*      variant="contained"*/}
        {/*      sx={{ minWidth: '90px', background: 'transparent', color: '#000' }}*/}
        {/*      onClick={handleNavigateToLogin}*/}
        {/*    >*/}
        {/*      {t('login')}*/}
        {/*    </Button>*/}
        {/*  </Stack>*/}
        {/*)}*/}
        <Stack direction="column" spacing={2} p={1}>
          <Button
            variant="contained"
            sx={{ minWidth: '90px', background: 'transparent', color: '#000' }}
            onClick={handleNavigateToFlag}
          >
            {t('flagship_schemes')}
          </Button>
        </Stack>
        {/* <Stack direction="column" spacing={2} p={1}>
          <Button
            variant="contained"
            sx={{ background: 'green', color: 'white' }}
            id="basic-button1"
            aria-controls={open ? 'basic-menu1' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClickPM}
          >
            {t('connect_with_pm')}
          </Button>
          <Menu
            id="basic-menu1"
            anchorEl={anchorElPM}
            open={openPM}
            onClose={handleClosePM}
            MenuListProps={{
              'aria-labelledby': 'basic-button1',
            }}
          >
            <MenuItem>
              <a
                href="https://nm-4.com/ViksitBharatYatra"
                target="_blank"
                style={{
                  textDecoration: 'none',
                  color: 'black',
                }}
              >
                {t('viksit_bharat_quiz')}
              </a>
            </MenuItem>
            <MenuItem>
              <a
                href="https://nm-4.com/ViksitBharatYatra"
                target="_blank"
                style={{
                  textDecoration: 'none',
                  color: 'black',
                }}
              >
                {t('share_your_message_with_pm_modi')}
              </a>
            </MenuItem>
          </Menu>
        </Stack> */}
        <Stack direction="column" spacing={2} p={1}>
          <Button
            variant="contained"
            sx={{ background: 'green', color: 'white' }}
            onClick={handleNavigateToEvents}
          >
            {t('events')}
          </Button>
        </Stack>
        <Stack direction="column" spacing={2} p={1}>
          <Button
            variant="contained"
            sx={{ background: 'green', color: 'white' }}
            onClick={handleNavigateToEventsCalendar}
          >
            {t('event_calendar')}
          </Button>
        </Stack>
        <Stack direction="column" spacing={2} p={1}>
          <Button
            variant="contained"
            fullWidth
            sx={{ background: 'green', color: 'white' }}
            onClick={handleNavigateToMCScript}
          >
            {t('emcee')}
          </Button>
        </Stack>
        {profileReducer.logged_in ? (
          <Stack direction="column" spacing={2} p={1}>
            <Button
              aria-label="menu"
              color="inherit"
              aria-controls="profile-menu"
              aria-haspopup="true"
              onClick={() => navigate(-1)}
            >
              <Avatar
                src="/broken-image.jpg"
                sx={{
                  width: '30px',
                  height: '30px',
                  bgcolor: deepOrange[500],
                }}
              />
            </Button>
          </Stack>
        ) : null}
        {/* <Stack direction="column" spacing={2} p={1}>
          <Button
            variant="contained"
            sx={{ minWidth: '90px', background: 'transparent', color: '#000' }}
            onClick={handleNavigateToQuiz}
          >
            {t('quiz')}
          </Button>
        </Stack> */}
        {/* <Stack direction="column" spacing={2} p={1}>
          <a href="https://pledge.mygov.in/viksit-bharat/" target="_blank">
            <Button
              variant="contained"
              sx={{ minWidth: '90px', background: 'transparent', color: '#000' }}
            >
              {t('pledge')}
            </Button>
          </a>
        </Stack> */}

        {/*<Stack direction="column" spacing={2} p={1}>*/}
        {/*<Button*/}
        {/*  className="header-links"*/}
        {/*  variant="text"*/}
        {/*  sx={{ background: 'transparent', fontSize: '16px' }}*/}
        {/*  onClick={() => handleNavigateToHome()}*/}
        {/*>*/}
        {/*  {t('home')}*/}
        {/*</Button>*/}
        {/*</Stack>*/}
        {/*<Stack direction="column" spacing={2} p={1}>*/}
        {/*<Button*/}
        {/*  className="header-links"*/}
        {/*  variant="text"*/}
        {/*  sx={{ background: 'transparent', fontSize: '16px' }}*/}
        {/*  onClick={() => handleNavigateToAbout()}*/}
        {/*>*/}
        {/*  {t('about')}*/}
        {/*</Button>*/}
        {/*</Stack>*/}

        {/*<Stack direction="column" spacing={2} p={1}>*/}
        {/*<Button*/}
        {/*  className="header-links"*/}
        {/*  variant="text"*/}
        {/*  sx={{ background: 'transparent', fontSize: '16px' }}*/}
        {/*  onClick={handleNavigateToDownload}*/}
        {/*>*/}
        {/*  Download*/}
        {/*</Button>*/}
        {/*</Stack>*/}
        {/*<Stack direction="column" spacing={2} p={1}>*/}
        {/*<Button*/}
        {/*  className="header-links"*/}
        {/*  variant="text"*/}
        {/*  sx={{ background: 'transparent', fontSize: '16px' }}*/}
        {/*  onClick={handleNavigateToLogin}*/}
        {/*>*/}
        {/*  {t('login')}*/}
        {/*</Button>*/}
        {/*</Stack>*/}
        {/*<Stack direction="column" spacing={2} p={1}>*/}
        {/*<Button*/}
        {/*  className="header-links"*/}
        {/*  variant="text"*/}
        {/*  sx={{ background: 'transparent', fontSize: '16px' }}*/}
        {/*  onClick={handleNavigateToFlag}*/}
        {/*>*/}
        {/*  Flagship Schemes*/}
        {/*</Button>*/}
        {/*</Stack>*/}
        {/*<Stack direction="column" spacing={2} p={1}>*/}
        {/*<Button*/}
        {/*  variant="contained"*/}
        {/*  sx={{ width: '150px', background: 'green', color: 'white' }}*/}
        {/*  onClick={handleNavigateToEvents}*/}
        {/*>*/}
        {/*  Events*/}
        {/*</Button>*/}
        {/*</Stack>*/}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <CssBaseline />
      <AppBar component="nav" style={{ backgroundColor: '#302987' }}>
        <Box
          display="flex"
          py={1}
          px={3}
          sx={{ background: '#5f17be45', justifyContent: 'flex-end', alignItems: 'center' }}
        >
          <a
            href="https://www.twitter.com/HSVB2047"
            target="_blank"
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <img
              src={twitter}
              style={{ padding: '5px', height: '30px', marginRight: 12 }}
              alt="twitter"
            />
          </a>
          <a
            href="https://www.facebook.com/HSVB2047"
            target="_blank"
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <FeatherIcon
              icon="facebook"
              size="30"
              style={{ padding: '5px', marginRight: 12 }}
              fill={'white'}
            />
          </a>
          <a
            href="https://www.instagram.com/HSVB2047"
            target="_blank"
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <InstagramIcon
              style={{ padding: '5px', marginRight: 12, color: 'white', width: 30, height: 30 }}
            />
          </a>
          <a
            href="https://www.youtube.com/@HSVB2047"
            target="_blank"
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <YouTube
              style={{ padding: '5px', marginRight: 12, color: 'white', width: 30, height: 30 }}
            />
          </a>
          <Select
            defaultValue={i18n.language || 'en'}
            onChange={(e) => changeLanguage(e.target.value)}
            size="small"
            style={{ background: 'white', height: 24 }}
          >
            <MenuItem value={'en'}>
              <em>English</em>
            </MenuItem>
            <MenuItem value={'hi'}>
              <em>हिन्दी</em>
            </MenuItem>
            <MenuItem value={'ben'}>
              <em>Bengali</em>
            </MenuItem>
            <MenuItem value={'as'}>
              <em>Assamese</em>
            </MenuItem>
            <MenuItem value={'guj'}>
              <em>Gujarati</em>
            </MenuItem>
            <MenuItem value={'kan'}>
              <em>Kannada</em>
            </MenuItem>
            <MenuItem value={'mal'}>
              <em>Malayalam</em>
            </MenuItem>
            <MenuItem value={'mar'}>
              <em>Marathi</em>
            </MenuItem>
            <MenuItem value={'od'}>
              <em>Oriya</em>
            </MenuItem>
            <MenuItem value={'pun'}>
              <em>Punjabi</em>
            </MenuItem>
            <MenuItem value={'tam'}>
              <em>Tamil</em>
            </MenuItem>
            <MenuItem value={'tel'}>
              <em>Telugu</em>
            </MenuItem>
          </Select>
        </Box>
        <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, color: 'white' }}
          >
            <MenuOutlinedIcon />
          </IconButton>
          <div style={{ cursor: 'pointer' }} onClick={() => handleNavigateToHome()}>
            <img src={Goi} alt="Logo1" width={104} height={46} />
          </div>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Stack direction="row" spacing={1.1}>
              <Button
                className="header-links"
                variant="text"
                sx={{ background: 'transparent', fontSize: '16px' }}
                onClick={() => handleNavigateToHome()}
              >
                {t('home')}
              </Button>
              <Button
                className="header-links"
                variant="text"
                sx={{ background: 'transparent', fontSize: '16px' }}
                onClick={() => handleNavigateToAbout()}
              >
                {t('about')}
              </Button>
              {/* <div>
                <Button
                  className="header-links"
                  variant="text"
                  sx={{ background: 'transparent', fontSize: '16px' }}
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClickCitizen}
                >
                  {t('citizen_activities')}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElCitizen}
                  open={openCitizen}
                  onClose={handleCloseCitizen}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {/* <MenuItem>
                    <a
                      href="https://pledge.mygov.in/viksit-bharat/"
                      target="_blank"
                      style={{
                        textDecoration: 'none',
                        color: 'black',
                      }}
                    >
                      {t('sankalp_pledge')}
                    </a>
                  </MenuItem> */}
              {/* <MenuItem onClick={handleNavigateToQuiz}>{t('quiz')}</MenuItem>
                  <MenuItem onClick={handleNavigateToUploadEvent}>{t('upload_event')}</MenuItem>
                  <MenuItem onClick={handleNavigateToUploadSelfie}>
                    {t('upload_selfie_at_event_location')}
                  </MenuItem> */}
              {/* <MenuItem onClick={handleNavigateToSelfieWithPM}>{t('selfie_with_pm')}</MenuItem> */}
              {/* </Menu> */}
              {/* </div> */}

              <div>
                <Button
                  className="header-links"
                  variant="text"
                  sx={{ background: 'transparent', fontSize: '16px' }}
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClickGlimpse}
                >
                  {t('event_glimpses')}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElGlimpse}
                  open={openGlimpse}
                  onClose={handleCloseGlimpse}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleNavigateToPhotos}>{t('photos')}</MenuItem>
                  <MenuItem onClick={handleNavigateToVideos}>{t('videos')}</MenuItem>
                  <MenuItem onClick={handleNavigateToSelfies}>{t('selfies')}</MenuItem>
                </Menu>
              </div>
              <Button
                className="header-links"
                variant="text"
                sx={{ background: 'transparent', fontSize: '16px' }}
                onClick={handleNavigateToDownload}
              >
                {t('download')}
              </Button>
              {/*{profileReducer?.logged_in ? (*/}
              {/*  <></>*/}
              {/*) : (*/}
              {/*  <Button*/}
              {/*    className="header-links"*/}
              {/*    variant="text"*/}
              {/*    sx={{ background: 'transparent', fontSize: '16px' }}*/}
              {/*    onClick={handleNavigateToLogin}*/}
              {/*  >*/}
              {/*    {t('login')}*/}
              {/*  </Button>*/}
              {/*)}*/}
              <Button
                className="header-links"
                variant="text"
                sx={{ background: 'transparent', fontSize: '16px' }}
                onClick={handleNavigateToFlag}
              >
                {t('flagship_schemes')}
              </Button>
              {/* <div>
                <Button
                  className="header-links"
                  variant="contained"
                  sx={{ background: 'green', fontSize: '16px' }}
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClickPM}
                >
                  {t('connect_with_pm')}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElPM}
                  open={openPM}
                  onClose={handleClosePM}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem>
                    <a
                      href="https://nm-4.com/ViksitBharatYatra"
                      target="_blank"
                      style={{
                        textDecoration: 'none',
                        color: 'black',
                      }}
                    >
                      {t('viksit_bharat_quiz')}
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="https://nm-4.com/ViksitBharatYatra"
                      target="_blank"
                      style={{
                        textDecoration: 'none',
                        color: 'black',
                      }}
                    >
                      {t('share_your_message_with_pm_modi')}
                    </a>
                  </MenuItem>
                </Menu>
              </div> */}
              <Button
                variant="contained"
                sx={{ background: 'green', color: 'white' }}
                onClick={handleNavigateToEventsCalendar}
              >
                {t('event_calendar')}
              </Button>
              <Button
                variant="contained"
                sx={{ width: '100px', background: 'green', color: 'white' }}
                onClick={handleNavigateToEvents}
              >
                {t('events')}
              </Button>
              <Button
                variant="contained"
                sx={{ background: 'green', color: 'white' }}
                onClick={handleNavigateToMCScript}
              >
                {t('emcee')}
              </Button>
              {profileReducer.logged_in ? (
                <Button
                  aria-label="menu"
                  color="inherit"
                  aria-controls="profile-menu"
                  aria-haspopup="true"
                  onClick={() => navigate(-1)}
                >
                  <Avatar
                    src="/broken-image.jpg"
                    sx={{
                      width: '30px',
                      height: '30px',
                      bgcolor: deepOrange[500],
                    }}
                  />
                </Button>
              ) : null}
              {/*<Button variant='outlined' onClick={handleNavigateToUploadEvent} color='secondary'>Upload Event Photo</Button>*/}
              {/*<a href='https://pledge.mygov.in/viksit-bharat/' target="_blank">*/}
              {/*<Button className='header-links' variant="text" sx={{ background: 'transparent', fontSize: '16px' }}>*/}
              {/*  {t('pledge')}*/}
              {/*</Button>*/}
              {/*</a>*/}
              {/*<Button className='header-links' variant="text" sx={{ background: 'transparent', fontSize: '16px' }}*/}
              {/*//  onClick={handleNavigateToQuiz}*/}
              {/*>*/}
              {/*  {t('quiz')}*/}
              {/*</Button>*/}
              {/*  <Button className='header-links' variant="text" sx={ {background: 'transparent', fontSize: '16px'} }>
             {t('quiz')}
             </Button> */}
              {/* <Button className='header-links' variant="text" sx={ {background: 'transparent', fontSize: '16px'} }>
             {t('dashboard')}
             </Button> */}
              {/*<Button className='header-links' variant="text" sx={{ background: 'transparent', fontSize: '16px' }} onClick={handleNavigateToDownload}>*/}
              {/*  {t('download')}*/}
              {/*</Button>*/}
            </Stack>
          </Box>
          {/* <img src={ Logo2 } alt='Logo2' width={ 104 } height={ 46 }/> */}
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};

export default Navbar;
