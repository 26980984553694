import React, { useEffect, useRef, useState } from 'react';
import { Card, CardContent, Divider, Button, MenuItem, Alert, Snackbar } from '@mui/material';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';
import * as Yup from 'yup';
import firebase from '../../../firebase';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateMultiple } from '../../../redux/userForm/Action';
import { loginTypes } from './constants/loginTypes';
import { userTypes } from './constants/userTypes';
import './form.css';
import UserTable from './tableView/UserTable';

const UserView = () => {
  const [userType, setUserType] = useState('');
  const [loginType, setType] = React.useState('');
  const [tableData, setTableData] = useState([]);
  const formState = useSelector((state) => state.FormReducer);
  const profileReducer = useSelector((state) => state.ProfileReducer);
  const { type } = useParams();
  const dispatch = useDispatch();

  //to get table data

  const getData = async () => {
    try {
      let query = firebase
        .firestore()
        .collection('users_detail')
        .where('created_by', '==', profileReducer?.info?.phone_number)
        .where('permission', '==', userType);
      console.log(userType, profileReducer?.info?.phone_number);
      if (userType == 'day_login') {
        query = firebase
          .firestore()
          .collection('users_detail')
          .where('created_by', '==', profileReducer?.info?.phone_number)
          .where('permission', 'in', [userType, 'rath_login']);
      }
      const userInfo = await query
        .get();
      if (userInfo.empty) {
        setTableData([]);
      } else {
        let array = [];
        userInfo.docs.forEach((e) => {
          array.push(e.data());
        });
        setTableData(array);
      }
    } catch (error) {
      console.error('Error checking profile:', error);
    }
  };

  useEffect(() => {
    getData();
  }, [formState]);
  const handleUserFormChange = (e) => {
    setUserType(e);
    dispatch(updateMultiple(loginTypes[e]));
  };
  //LGD MAPPING LOGIC

  useEffect(() => {
    // Define an object for type of login that can create users
    const typeMapping = {
      state_nodal_login: true,
      district_nodal_login: true,
      block_nodal_login: true,
      block_level_login: true,
      central_in_charge_login: true,
      state_in_charge_login: true,
      central_nodal_agency_login: true,
      state_nodal_agency_login: true,
      district_nodal_agency_login: true,
    };
    // Check if 'type' is in the mapping
    if (typeMapping[type] === undefined) {
      window.location.href = '/404';
    } else {
      setType(type);
    }
  }, [type]);

  //to check if profile exist

  const checkProfile = async (e) => {
    try {
      const userInfo = await firebase
        .firestore()
        .collection('users')
        .where('phone_number', '==', e)
        .where('permission', '==', type) // You need to put 'type' in quotes
        .limit(1)
        .get();

      if (userInfo.empty) {
        window.location.href = '/dashboards/dashboard1';
      }
    } catch (error) {
      console.error('Error checking profile:', error);
    }
  };

  useEffect(() => {
    if (profileReducer?.info?.phone_number) {
      checkProfile(profileReducer.info.phone_number);
    }
  }, [profileReducer.info]);

  return (
    <div>
      <Card
        sx={{
          p: 0,
        }}
      >
        <Divider />
        <CardContent
          sx={{
            padding: '30px',
          }}
        >
          <>
            <label htmlFor="user_type">Select Type of User</label>
            <CustomSelect
              fullWidth
              id="user_type"
              variant="outlined"
              name="user_type"
              size="small"
              placeholder="Select User"
              sx={{
                mb: 2,
              }}
              value={userType}
              onChange={(e) => {
                handleUserFormChange(e.target.value);
              }}
            >
              <MenuItem value={''} disabled>
                Select Option
              </MenuItem>
              {userTypes[loginType]?.map((item, key) => (
                <MenuItem key={key} value={item?.key}>
                  {item?.name}
                </MenuItem>
              ))}
            </CustomSelect>
          </>
        </CardContent>
      </Card>

      {profileReducer?.info?.phone_number && userType ? (
        <UserTable columns={formState} table_data={tableData} type={userType} />
      ) : null}
    </div>
  );
};

export default UserView;
