import React, { useEffect, useState } from 'react';
import { Alert, Box, Card, CardContent, Grid, MenuItem, Snackbar, Typography, } from '@mui/material';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router-dom';
import firebase, { firestore } from '../../../firebase';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const DistrictRathAllocationForm = () => {

  const { t } = useTranslation();
  const [numberOfRathsAllocated, setNumberOfRathsAllocated] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [districtList, setDistrictList] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedState, setSelectedState] = useState({});
  const [vanSize, setVanSize] = useState(0);
  const [vanSizeTotal, setVanSizeTotal] = useState(0);
  const [vanList, setVanList] = useState([]);
  const profileDetailReducer = useSelector((state) => state.ProfileDetailReducer);
  const profileReducer = useSelector((state) => state.ProfileReducer);
  const [loading, setLoading] = useState(false);
  const initialGridData = Array.from({ length: numberOfRathsAllocated }).map((_, index) => ({
    startDate: '',
    endDate: '',
    selectedVan: '', // Default value for van
  }));

  const [gridData, setGridData] = useState(initialGridData);
  const navigate = useNavigate();
  function isObjectFilled(obj) {
    return Object.values(obj).every(
      (value) => value !== '' && value !== null && value !== undefined,
    );
  }
  const fetchDistrictList = async (state_code) => {
    const res = await firebase
      .firestore()
      .collection('district_state_lgd_mapping')
      .where('state_code', '==', state_code)
      .get();
    if (!res.empty) {
      let districtArray = [];
      res.forEach((doc) => districtArray.push(doc.data()));
      districtArray.sort((a, b) => a.district_name.localeCompare(b.district_name));
      setDistrictList(districtArray);
    }
  };

  useEffect(() => {
    if (profileDetailReducer?.info?.length > 0) {
      // Filter info array based on the permission field
      const state = profileDetailReducer.info.filter(
        (e) => e.permission === profileReducer?.logged_in_type,
      );

      if (state.length > 0) {
        const stateCode = state[0].state_code;

        // Query Firestore for van list
        const vanListRef = firebase
          .firestore()
          .collection('van-list')
          .where('state_code', '==', stateCode)
          .where('lgd_type', '==', 'state');

        vanListRef.get().then((querySnapshot) => {
          const array = [];
          querySnapshot.docs.forEach((doc) => {
            array.push(doc.data());
          });

          // Update state with van list and size
          setVanList(array);
          getVanSize(stateCode);
        });

        // Update the selected state
        setSelectedState(state[0]);
        fetchDistrictList(state[0]?.state_code);
      }
    }
  }, [profileDetailReducer?.info]);

  //size of allocated vans

  const getVanSize = (stateCode) => {
    firebase
      .firestore()
      .collection('van_lgd_mapping')
      .where('state_code', '==', stateCode)
      .where('lgd_type', '==', 'state')
      .limit(1)
      .get()
      .then((querySnapshot) => {

        setVanSize(querySnapshot.docs?.[0].data().count);
        setVanSizeTotal(querySnapshot.docs?.[0].data().total)
      });
  };

  // Handler function to update the number of allocated raths

  const handleNumberOfRathsChange = (event) => {
    const allocatedRaths = event.target.value;
    if (allocatedRaths > vanSize) {
      setIsAlertOpen(true);
      setAlertSeverity('error');
      setAlertMessage(t('van_exceed_message'));
    } else {
      setNumberOfRathsAllocated(event.target.value);
      const updatedGridData = [...gridData];

      if (allocatedRaths > gridData.length) {
        for (let i = gridData.length; i < allocatedRaths; i++) {
          updatedGridData.push({
            startDate: '',
            endDate: '',
            selectedVan: '',
          });
        }
      } else if (allocatedRaths < gridData.length) {
        updatedGridData.splice(allocatedRaths);
      }

      setGridData(updatedGridData);
    }
  };
  const handleAlertClose = () => {
    setIsAlertOpen(false);
  };

  //logic for availability check

  const checkForAvailability = async (selectedVanId, selectedStartDate, selectedEndDate) => {

    const allocationRef = firestore.collection('van_date_mapping');

    // Fetch all allocations for the selected van
    const querySnapshot = await allocationRef.where('van_id', '==', selectedVanId).get();
    if (querySnapshot.empty) {
      return false;
    }
    let isAvailable = false; // Initialize a flag to track availability

    // Check each allocation for your conditions
    querySnapshot.docs.forEach((doc) => {
        const { start_date, end_date } = doc.data();

        // Convert date fields to Date objects
        const startDateObj = start_date.toDate();
        const endDateObj = end_date.toDate();
        const selectedStartDateObj = selectedStartDate.toDate();
        const selectedEndDateObj = selectedEndDate.toDate();

        if (
            (selectedEndDateObj >= startDateObj && selectedEndDateObj <= endDateObj) ||
            (selectedStartDateObj >= startDateObj && selectedStartDateObj <= endDateObj)
        ) {
            isAvailable = true; // Set the flag to true if a match is found
        }
    });

    return isAvailable; // Return the result after the loop
};


  const checkForAvailabilityLocal = async (array,selectedVanId, selectedStartDate, selectedEndDate) => {
    const data = array.filter((e) => e.selectedVan.trim() === selectedVanId);
    if (data?.length == 0) {
      return false;
    }
    let isAvailable = false;

    data.forEach((doc) => {
      const { startDate, endDate } = doc;

      // Convert date fields to Date objects
      const startDateObj = startDate && startDate?.toDate();
      const endDateObj = endDate && endDate?.toDate();
      const selectedStartDateObj = selectedStartDate.toDate();
      const selectedEndDateObj = selectedEndDate.toDate();

      if (
        (selectedEndDateObj >= startDateObj && selectedEndDateObj <= endDateObj) ||
        (selectedStartDateObj >= startDateObj && selectedStartDateObj <= endDateObj)
      ){
        isAvailable = true;
      }
    });

    return isAvailable; // Return true if no overlapping allocations are found
  };

  //submit function
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const allObjectsFilled = gridData.every(isObjectFilled);

      if (!allObjectsFilled) {
        setAlertSeverity('error');
        setAlertMessage(t('please_fill_all_the_fields_correctly'));
        setIsAlertOpen(true);
        setLoading(false);
        return;
      } else {
        for (const gridItem of gridData) {
          const van_lgd_db = firestore.collection('van_lgd_mapping');
          const van_list_db = firestore.collection('van-list');
          const van_date_db = firestore.collection('van_date_mapping');

          const dateRange =
            new Date(gridItem.startDate).getTime() + new Date(gridItem.endDate).getTime();
          const data = {
            district_name: JSON.parse(selectedDistrict)?.district_name,
            district_code: JSON.parse(selectedDistrict)?.district_code,
            start_date: new Date(gridItem.startDate),
            end_date: new Date(gridItem.endDate),
            van_id: gridItem.selectedVan,
            date_range: dateRange,
            lgd_type: 'district',
            phase: 'PHASE1',
            ...selectedState,
          };

          const vanDateDocRef = await van_date_db.add(data);

          const districtCode = data.district_code;

          const vanListQuery = van_list_db
            .where('id', '==', data?.van_id)
            .where('lgd_type', '==', 'district')
            .where('district_code', '==', districtCode)
            .limit(1);

          const vanListSnapshot = await vanListQuery.get();

          if (vanListSnapshot.empty) {
            await van_list_db.add({
              state_name: selectedState?.state_name,
              state_code: selectedState?.state_code,
              district_name: data?.district_name,
              district_code: data?.district_code,
              lgd_type: 'district',
              id: data?.van_id,
            });
          }

          const vanLgdQuery = van_lgd_db
            .where('lgd_type', '==', 'district')
            .where('district_code', '==', districtCode)
            .limit(1);

          const vanLgdSnapshot = await vanLgdQuery.get();

          if (!vanLgdSnapshot.empty) {
            const vanLgdDocRef = vanLgdSnapshot.docs[0].ref;
            await vanLgdDocRef.update({
              allocated: true,
              total: firebase.firestore.FieldValue.increment(1),
              count: firebase.firestore.FieldValue.increment(1),
            });
          }

          // const vanLgdQuery2 = van_lgd_db
          //   .where('lgd_type', '==', 'state')
          //   .where('state_code', '==', selectedState?.state_code)
          //   .limit(1);

          // const vanLgdSnapshot2 = await vanLgdQuery2.get();

          // if (!vanLgdSnapshot2.empty) {
          //   const vanLgdDocRef = vanLgdSnapshot2.docs[0].ref;
          //   await vanLgdDocRef.update({
          //     allocated: true,
          //     count: firebase.firestore.FieldValue.increment(-1),
          //     ...selectedState,
          //   });
          // }
        }

        setIsAlertOpen(true);
        setAlertSeverity('success');
        setAlertMessage(t('all_vans_allocated_successfully'));
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 1200);
      }
    } catch (error) {
      setLoading(false);
      setAlertSeverity('error');
      setAlertMessage(t('something_went_wrong'));
      setIsAlertOpen(true);
    }
  };

  const handleStartDateChange = (index, date) => {
    const updatedGridData = [...gridData];
    updatedGridData[index].startDate = date;
    updatedGridData[index].endDate = '';
    updatedGridData[index].selectedVan = '';
    setGridData(updatedGridData);
  };

  const handleEndDateChange = (index, date) => {
    if (date.toDate() <= gridData[index].startDate) {
      const updatedGridData = [...gridData];
      updatedGridData[index].endDate = null;
      setGridData(updatedGridData);
      setIsAlertOpen(true);
      setAlertSeverity('error');
      setAlertMessage(t('end_date_should_be_earlier_than_start_date'));
    } else {
      const updatedGridData = [...gridData];
      updatedGridData[index].endDate = date;
      updatedGridData[index].selectedVan = '';
      setGridData(updatedGridData);
    }
  };

  //check duplicate Van
  function hasDuplicateSelectedVan(objArray, selectedVan, startDate, endDate) {
    return objArray.some((obj) => {
      return (
        obj.selectedVan === selectedVan &&
        new Date(obj.startDate).getTime() === new Date(startDate).getTime() &&
        new Date(obj.endDate).getTime() === new Date(endDate).getTime()
      );
    });
  }

  const handleVanChange = (index, value) => {
    const updatedGridData = [...gridData];
    if (numberOfRathsAllocated > 1) {
      const hasDuplicate = hasDuplicateSelectedVan(
        updatedGridData,
        value,
        updatedGridData[index].startDate,
        updatedGridData[index].endDate,
      );

      if (hasDuplicate) {
        setIsAlertOpen(true);
        setAlertSeverity('error');
        setAlertMessage(t('duplicate_van_with_same_start_and_end_date_found'));
        updatedGridData[index].selectedVan = '';
        setGridData(updatedGridData);
        return;
      }
    }

    checkForAvailability(
      value,
      updatedGridData[index]?.startDate,
      updatedGridData[index]?.endDate,
    ).then((hasConflict) => {
      if (hasConflict) {
        setIsAlertOpen(true);
        setAlertSeverity('error');
        setAlertMessage(t('this_van_is_not_available_for_selected_date_range'));
        updatedGridData[index].selectedVan = '';
        setGridData(updatedGridData);
      } else {
        checkForAvailabilityLocal(
          updatedGridData,
          value,
          updatedGridData[index]?.startDate,
          updatedGridData[index]?.endDate,
        ).then((hasConflict) => {
          if (hasConflict) {
            setIsAlertOpen(true);
            setAlertSeverity('error');
            setAlertMessage(t('this_van_is_not_available_for_selected_date_range'));
            updatedGridData[index].selectedVan = '';

            setGridData(updatedGridData);
          } else {
            updatedGridData[index].selectedVan = value;
            setGridData(updatedGridData);
          }
        });
      }
    });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isAlertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert severity={alertSeverity} onClose={handleAlertClose} variant="filled">
          {alertMessage}
        </Alert>
      </Snackbar>
      <Box>
        <Typography sx={{ marginLeft: '1rem' }} variant="h2" gutterBottom>
        {t('total_vans_allocated_to')} {selectedState?.state_name}- <b>{vanSizeTotal}</b>
        </Typography>
        <Card
          sx={{
            p: 0,
          }}
        >
          <CardContent
            sx={{
              padding: '30px',
            }}
          >
            <Grid container gap={5}>
              <Grid item xs={12} lg={2}>
                <CustomFormLabel>{t('select_district')}</CustomFormLabel>
                <CustomSelect
                  id="district"
                  fullWidth
                  name="district"
                  value={selectedDistrict}
                  onChange={(e) => setSelectedDistrict(e.target.value)}
                >
                  <MenuItem disabled>
                    <em>{t('select_your_option')}</em>
                  </MenuItem>
                  {districtList.map((data) => (
                    <MenuItem value={JSON.stringify(data)} key={Math.random()}>
                      {data.district_name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} lg={2}>
                <CustomFormLabel>{t('num_of_van_allocated')}</CustomFormLabel>
                <CustomTextField
                  fullWidth
                  id="number_of_raths_located"
                  name="number_of_raths_located"
                  type="number"
                  value={numberOfRathsAllocated}
                  onChange={handleNumberOfRathsChange}
                  inputProps={{ min: 1 }}
                  disabled={selectedDistrict === ''}
                />
              </Grid>
              {gridData.map((data, index) => (
                <Grid container gap={5} key={index}>
                  <Grid item xs={12} lg={2}>
                    <CustomFormLabel>{t('start_date_for_van')} {index + 1}</CustomFormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                      <DatePicker
                        name={`start_date_${index}`}
                        id={`start_date_${index}`}
                        value={data.startDate}
                        onChange={(date) => handleStartDateChange(index, date)}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <CustomFormLabel>{t('end_date_for_van')} {index + 1}</CustomFormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                      <DatePicker
                        disabled={!data.startDate}
                        value={data?.endDate}
                        name={`end_date_${index}`}
                        id={`end_date_${index}`}
                        onChange={(date) => handleEndDateChange(index, date)}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <CustomFormLabel>{t('select_van')} {index + 1}</CustomFormLabel>
                    <CustomSelect
                      disabled={!data.endDate}
                      value={data.selectedVan}
                      onChange={(e) => handleVanChange(index, e.target.value)}
                      id={`rath_id_${index}`}
                      fullWidth
                      name={`rath_id_${index}`}
                    >
                      <MenuItem disabled value={'default'}>
                        <em>{t('select_your_option')}</em>
                      </MenuItem>
                      {vanList.map((data) => (
                        <MenuItem value={data.id} key={Math.random()}>
                          {data.id}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Box pt={3} textAlign={'center'}>
              <LoadingButton
                loading={loading}
                type="submit"
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled={numberOfRathsAllocated === 0 || numberOfRathsAllocated === ''||numberOfRathsAllocated<0}
              >
                {t('submit')}
              </LoadingButton>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default DistrictRathAllocationForm;
