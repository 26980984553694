import { combineReducers } from 'redux';
import CustomizerReducer from './customizer/CustomizerReducer';
import chatReducer from './chats/ChatReducer';
import notesReducer from './notes/NotesReducer';
import emailReducer from './email/EmailReducer';
import FormReducer from './userForm/FormReducer';
import ProfileReducer from './profile/ProfileReducer';
import ProfileDetailReducer from './profileDetail/ProfileReducer';
const RootReducers = combineReducers({
  CustomizerReducer,
  chatReducer,
  notesReducer,
  emailReducer,
  FormReducer,
  ProfileReducer,
  ProfileDetailReducer
});

export default RootReducers;
