import React, { useState } from 'react';
import PageContainer from '../../../components/container/PageContainer';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import { useTheme } from '@mui/material/styles';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import PropTypes from 'prop-types';
import img1 from '../../../assets/images/users/1.jpg';
import firebase from '../../../firebase/index';
import axios from 'axios';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { formatFirebaseTimestampToDateString } from './utility';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';
import FeatherIcon from 'feather-icons-react';
import { LoadingButton } from '@mui/lab';
import { checkUser } from '../../../utility/checkUser';
import { convertJsonToCsvAndDownload } from '../../../utility/ExportButton';
import { useTranslation } from 'react-i18next';

const DCVanList = ({ form, block, selectedVan }) => {
  const vanURL = process.env.REACT_APP_FIREBASE_ENV === 'production' ? 'https://asia-south1-viksit-bharat-s-y-dic.cloudfunctions.net/deleteVanAllocation' : 'https://asia-south1-vbsy-stagging.cloudfunctions.net/deleteVanAllocation';
  const { t } = useTranslation();

  const BCrumb = [
    {
      to: '/',
      title: t('home'),
    },
    {
      title: t('van_list'),
    },
  ];

  const eventMenu = [
    { name: 'Event 1', value: 'PHASE1' },
    { name: 'Event 2', value: 'PHASE2' },
  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('');
  const [blockList, setBlockList] = React.useState([]);
  const profileDetailReducer = useSelector((state) => state.ProfileDetailReducer);
  const profileReducer = useSelector((state) => state.ProfileReducer);
  const [open, setOpen] = React.useState(false);
  const [selectedBlock, setSelectedBlock] = React.useState(
    '{"block_name":"ALL","block_code":"ALL"}',
  );
  const [dcCode, setDistrictCode] = React.useState();
  const [selectedPhase, setSelectedPhase] = useState('');

  useEffect(() => {
    if (profileDetailReducer?.info?.length > 0) {
      // Filter info array based on the permission field
      const district = profileDetailReducer.info.filter(
        (e) => e.permission === profileReducer?.logged_in_type,
      );

      if (district.length > 0) {
        const districtCode = district[0].district_code;
        setDistrictCode(districtCode);
        // Query Firestore for van list
        firebase
          .firestore()
          .collection('van_date_mapping')
          .where('district_code', '==', districtCode)
          .where('lgd_type', '==', 'gp')
          .get()
          .then((querySnapshot) => {
            const array = [];
            querySnapshot.docs.forEach((doc) => {
              array.push(doc.data());

            });
            setRows(array);
          });

        fetchBlockList(districtCode).then();
      }
    }
  }, [profileDetailReducer?.info]);

  useEffect(() => {
    if (['district_login', 'district_nodal_agency_login', 'district_nodal_login', 'district_nodal_login_delhi', 'district_nodal_login_other'].includes(profileReducer.logged_in_type) == false) {
      window.location.href = '/dashboards/dashboard1';
    }
  }, []);
  const fetchBlockList = async (district_code) => {
    const res = await firebase
      .firestore()
      .collection('block_district_lgd_mapping')
      .where('district_code', '==', district_code)
      .get();
    if (!res.empty) {
      let blockArray = [];
      res.forEach((doc) => blockArray.push(doc.data()));
      blockArray.sort((a, b) => a.block_name.localeCompare(b.block_name));
      setBlockList(blockArray);
    }
  };

  useEffect(() => {
    setSelectedPhase('');
    if (form && block) {
      firebase
        .firestore()
        .collection('van_date_mapping')
        .where('block_code', '==', block)
        .where('van_id', '==', selectedVan)
        .where('lgd_type', '==', 'gp')
        .get()
        .then((querySnapshot) => {
          const array = [];
          querySnapshot.docs.forEach((doc) => {
            array.push(doc.data());

          });
          setRows(array);
        });
    } else {
      if (selectedBlock && JSON.parse(selectedBlock)?.block_code != 'ALL') {
        firebase
          .firestore()
          .collection('van_date_mapping')
          .where('block_code', '==', JSON.parse(selectedBlock)?.block_code)
          .where('lgd_type', '==', 'gp')
          .get()
          .then((querySnapshot) => {
            const array = [];
            querySnapshot.docs.forEach((doc) => {
              array.push(doc.data());
              console.log(doc.data());
            });
            setRows(array);
          });
      } else {
        if (dcCode) {
          firebase
            .firestore()
            .collection('van_date_mapping')
            .where('district_code', '==', dcCode)
            .where('lgd_type', '==', 'gp')
            .get()
            .then((querySnapshot) => {
              const array = [];
              querySnapshot.docs.forEach((doc) => {
                array.push(doc.data());

              });
              setRows(array);
            });
        }
      }
    }
  }, [block, selectedBlock]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = open; // Assuming 'open' is a valid object, otherwise replace with the correct data source

    try {

      firebase.auth().onAuthStateChanged(async (user) => {
        const token = await user.getIdToken();
        axios.get(`${vanURL}?van_id=${data?.van_id}&gp_code=${data?.gp_code}&day_nodal_officer_mobile=${data?.day_nodal_officer_mobile}&token=${token}`).then((res) => {

          if (res?.data?.status == 200) {
            handleClose();
            firebase
              .firestore()
              .collection('van_date_mapping')
              .where('district_code', '==', dcCode)
              .where('block_code', '==', JSON.parse(selectedBlock)?.block_code)
              .where('lgd_type', '==', 'gp')
              .get()
              .then((querySnapshot) => {
                const array = [];
                querySnapshot.docs.forEach((doc) => {
                  array.push(doc.data());

                });
                setRows(array);
              });

            setAlertSeverity('success');
            setAlertMessage('Deleted Successfully !');
            setIsAlertOpen(true);
            setLoading(false);
          } else {
            setLoading(false);
            setAlertSeverity('error');
            setAlertMessage('Something went wrong!');
            setIsAlertOpen(true);
          }
        });
      });


    } catch (error) {
      setLoading(false);
      setAlertSeverity('error');
      setAlertMessage('Something went wrong!' + ' ' + error);
      setIsAlertOpen(true);
      // Handle any errors that may occur during the execution
      console.error('An error occurred:', error);
    }
  };

  const getEventNumber = (phase) => {
    if (phase === 'PHASE1') {
      return 'Event 1';
    } else if (phase === 'PHASE2') {
      return 'Event 2';
    } else {
      return 'Event 1';
    }
  };


  const exportdata = () => {
    // Create an array for the CSV data with capitalized column names
    const csvData = rows.map((row) => ({
      'Block': row.block_name,
      'Gram Panchayat': row.gp_name || '', // Use an empty string if data is missing
      'Date of Yatra': formatFirebaseTimestampToDateString(row.end_date) || '', // Use an empty string if data is missing
      'Van ID': row.van_id || '', // Use an empty string if data is missing
      'Day Nodal Officer': `${row.day_nodal_officer_mobile} (${row.day_nodal_officer_name})` || '', // Use an empty string if data is missing
      'Slot': row.slot || '', // Use an empty string if data is missing
      'Event Number': getEventNumber(row?.phase) || 'PHASE1',
    }));

    // Convert the data to CSV and download it
    convertJsonToCsvAndDownload(csvData, 'list.xlsx');
  };
  const handleAlertClose = () => {
    setIsAlertOpen(false);
  };

  useEffect(() => {
    if (selectedPhase) {
      if (selectedBlock && JSON.parse(selectedBlock)?.block_code !== 'ALL') {
        if (selectedPhase === 'PHASE1') {
          firebase
            .firestore()
            .collection('van_date_mapping')
            .where('block_code', '==', JSON.parse(selectedBlock)?.block_code)
            .where('lgd_type', '==', 'gp')
            .where('phase', '!=', 'PHASE2')
            .get()
            .then((querySnapshot) => {
              const array = [];
              querySnapshot.docs.forEach((doc) => {
                array.push(doc.data());
              });
              setRows(array);
            });
        } else {
          firebase
            .firestore()
            .collection('van_date_mapping')
            .where('block_code', '==', JSON.parse(selectedBlock)?.block_code)
            .where('lgd_type', '==', 'gp')
            .where('phase', '==', 'PHASE2')
            .get()
            .then((querySnapshot) => {
              const array = [];
              querySnapshot.docs.forEach((doc) => {
                array.push(doc.data());
              });
              setRows(array);
            });
        }
      } else {
        if (selectedPhase === 'PHASE1') {
          firebase
            .firestore()
            .collection('van_date_mapping')
            .where('district_code', '==', dcCode)
            .where('lgd_type', '==', 'gp')
            .where('phase', '!=', 'PHASE2')
            .get()
            .then((querySnapshot) => {
              const array = [];
              querySnapshot.docs.forEach((doc) => {
                array.push(doc.data());
              });
              setRows(array);
            });
        } else {
          firebase
            .firestore()
            .collection('van_date_mapping')
            .where('district_code', '==', dcCode)
            .where('lgd_type', '==', 'gp')
            .where('phase', '==', 'PHASE2')
            .get()
            .then((querySnapshot) => {
              const array = [];
              querySnapshot.docs.forEach((doc) => {
                array.push(doc.data());
              });
              setRows(array);
            });
        }
      }
    }
  }, [selectedPhase]);

  return (
    <><Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isAlertOpen}
      autoHideDuration={2000}
      onClose={handleAlertClose}
    >
      <Alert severity={alertSeverity} onClose={handleAlertClose} variant='filled'>
        {alertMessage}
      </Alert>
    </Snackbar>
      <PageContainer title={`${t('van_list_title')}`} description='this is Van List page'>
        {/* breadcrumb */}
        {!form && (
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Box sx={{ width: '70%' }}>
              <Breadcrumb title='Van List' items={BCrumb} />
              <Typography sx={{ marginLeft: '1rem' }} variant='h4'>
                ({t('tentative_list_for_van')})
              </Typography>
            </Box>
            <Button sx={{ marginRight: '1rem' }} onClick={() => exportdata()} variant='contained' color='primary'>
              Export
            </Button>
          </Stack>
        )}

        <Box>
          <Card>
            <CardContent>
              {!form && (
                <Grid container spacing={2}>
                  <Grid item xs={4} lg={3}>
                    <CustomFormLabel>{t('select_block')}</CustomFormLabel>
                    <CustomSelect
                      value={selectedBlock}
                      onChange={(e) => {
                        setSelectedBlock(e?.target.value);
                      }}
                      id='block'
                      fullWidth
                      name='block'
                    >
                      <MenuItem value={'{"block_name":"ALL","block_code":"ALL"}'}>
                        <em>{t('all')}</em>
                      </MenuItem>
                      {blockList?.map((data) => (
                        <MenuItem value={JSON.stringify(data)} key={Math.random()}>
                          {data?.block_name}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </Grid>
                  <Grid item xs={4} lg={3}>
                    <CustomFormLabel>Select Event Number</CustomFormLabel>
                    <CustomSelect
                      value={selectedPhase}
                      onChange={(e) => {
                        setSelectedPhase(e.target.value);
                      }}
                      id='phase'
                      fullWidth
                      name='phase'
                    >
                      <MenuItem disabled>
                        <em>Select Your Option</em>
                      </MenuItem>
                      {eventMenu?.map((data) => (
                        <MenuItem value={data?.value} key={Math.random()}>
                          {data?.name}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </Grid>
                </Grid>
              )}
              {/* <Divider /> */}
              <Box
                sx={{
                  overflow: 'auto',
                  width: '100%',
                }}
              >
                <Table
                  aria-label='custom pagination table'
                  sx={{
                    whiteSpace: 'nowrap',
                  }}
                  exportButton={true}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant='h5'>{t('sno')}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h5'>{t('block')}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h5'>{t('gram_panchayat')}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h5'>{t('date_of_yatra')}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h5'>{t('van_id')}</Typography>
                      </TableCell>

                      <TableCell>
                        <Typography variant='h5'>{t('day_nodal_officer')}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h5'>{t('slot')}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h5'>{t('driver_name')}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h5'>{t('driver_mobile')}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h5'>Event Number</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h5'>Delete</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.sort((a, b) => a?.start_date?.toDate() - b?.start_date?.toDate())?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant='h5'> {index + 1}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant='h6' fontWeight='600'>
                            {row.block_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color='textSecondary' variant='h6' fontWeight='400'>
                            {row?.gp_name}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography color='textSecondary' variant='h6' fontWeight='400'>
                            {formatFirebaseTimestampToDateString(row?.end_date)}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography color='textSecondary' variant='h6' fontWeight='400'>
                            {row.van_id}{row?.vehicle_no ? (' ' + `(${row?.vehicle_no})`) : ''}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography color='textSecondary' variant='h6' fontWeight='400'>
                            {row?.day_nodal_officer_mobile + ' ' + `(${row?.day_nodal_officer_name})`}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography color='textSecondary' variant='h6' fontWeight='400'>
                            {row?.slot}
                          </Typography>

                        </TableCell>
                        <TableCell>
                          <Typography color='textSecondary' variant='h6' fontWeight='400'>
                            {row?.driver_name}
                          </Typography>

                        </TableCell>
                        <TableCell>
                          <Typography color='textSecondary' variant='h6' fontWeight='400'>
                            {row?.driver_mobile}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color='textSecondary' variant='h6' fontWeight='400'>
                            {row?.phase ? (
                              <>
                                {(row?.phase === 'PHASE1') && (
                                  'Event 1'
                                )}
                                {(row?.phase === 'PHASE2') && (
                                  'Event 2'
                                )}
                              </>
                            ) : (
                              'Event 1'
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>

                          <Typography color='textSecondary' variant='h6' fontWeight='400'>
                            <FeatherIcon onClick={() => setOpen(row)} style={{ cursor: 'pointer' }} icon={'delete'}
                                         width='20' height='20' />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </CardContent>
          </Card>
        </Box>

        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          fullWidth
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title' variant='h4'>
            {t('edit_van_allocation')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description' component='div'>{t('edit_van_allocation_p')}
              <br />
              <br />
              <span style={{ color: 'red' }}>{t('are_you_sure')}</span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={loading} onClick={() => handleSubmit()} color='danger' variant='contained'>
              {t('delete')} {' '} <FeatherIcon style={{ position: 'relative', left: '5px' }} icon={'trash'}
                                               width='20' height='20' />
            </LoadingButton>

          </DialogActions>
        </Dialog>
      </PageContainer>
    </>
  );
};

export default DCVanList;
