import React from 'react';
import { Grid } from '@mui/material';
import PageContainer from '../../../components/container/PageContainer';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import UserView from './userView';
import { useEffect } from 'react';
import { checkUser } from '../../../utility/checkUser';
import { useParams } from 'react-router';


const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'View Users',
  },
];

const ViewUser = () => {
  const { type } = useParams();
  useEffect(() => {
    checkUser(type)
  }, [type])
  return(
  <PageContainer title="List of users" description="this is create event page">
    {/* breadcrumb */}
    <Breadcrumb title="List of users" items={BCrumb} />
    {/* end breadcrumb */}
    <Grid container spacing={0}>
  
      <Grid item lg={12} md={12} xs={12}>
        <UserView/>
      </Grid>
     
    </Grid>
  </PageContainer>
);

  }
export default ViewUser;
