import firebase from '../firebase'
export const checkUser = async (role) => {
  
        firebase?.auth().onAuthStateChanged(async (res) => {
          if (!res) {
            window.location.href = '/';
          } else {
            const userquerySnapshot = await firebase
            .firestore()
            .collection('users')
            .doc(res?.uid).get();
            if(userquerySnapshot.exists){
              if(role){
                const backendRole=userquerySnapshot.data()?.permission=='district_login'?'district_nodal_login':userquerySnapshot.data()?.permission
                if(role!=backendRole){
                    window.location.href= '/dashboards/dashboard1'
                }
              }
          
            }
          }   })

  
  };