import {
  Alert,
  Breadcrumbs,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import firebase from '../../../firebase/index';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';
import PageContainer from '../../../components/container/PageContainer';
import { CardHeader } from 'react-bootstrap';
import { useSelector } from 'react-redux';


export default function ViewUL({ dataInfo }) {
  const [formData, setFormData] = useState({
    date: null,
    district: null,
    ulb: null,
    ul: null,
    user: null,
    slot: '',
  });
  const { t } = useTranslation();
  const [ulbs, setUlbs] = useState([]);
  const [uls, setUls] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [states, setStates] = useState([]);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = useState(false);
  const BCrumb = [
    {
      to: '/',
      title: t('home'),
    },
    {
      title: t('view_urban_event'),
    },
  ];

  const profileReducer = useSelector((state) => state.ProfileReducer);
  const profileDetailReducer = useSelector((state) => state.ProfileDetailReducer);

  const fetchStates = async () => {
    const dataArray = [];
    if (
      profileReducer?.logged_in_type === 'state_ib' ||
      profileReducer?.logged_in_type === 'municipal_user' ||
      profileReducer?.logged_in_type === 'district_nodal_urban_agency' ||
      profileReducer?.logged_in_type === 'district_nodal_login_delhi' ||
      profileReducer?.logged_in_type === 'district_nodal_login_other' ||
      profileReducer?.logged_in_type === 'urban_nodal_login'
    ) {
      if (profileDetailReducer?.info?.length > 0) {
        const stateData = profileDetailReducer.info.map((obj) => ({
          state_name: obj.state_name,
          state_code: obj.state_code,
        }));
        // Filter out unique states based on state_code
        const uniqueStates = stateData.filter(
          (value, index, self) =>
            self.findIndex((v) => v.state_code === value.state_code) === index,
        );
        setStates(uniqueStates);
      }
    } else {

      const res = await firebase
        .firestore()
        .collection('state_lgd_mapping')
        .get();
      if (!res.empty) {
        res.docs.forEach((doc) => {
          dataArray.push(doc.data());
        });
        dataArray.sort((a, b) => a.state_name.localeCompare(b.state_name));
        setStates(dataArray);
      }
    }
  };

  useEffect(() => {
    if (profileDetailReducer?.info.length > 0) {
      fetchStates().then();
      firebase.auth().onAuthStateChanged((res) => {
        setEmail(res.email.split('@')[0]);
      });
    }
  }, [profileDetailReducer]);

  const handleFormChange = async (field, value) => {
    let ul = {};
    let district = {};
    let ulb = {};
    let user = {};
    let state = {};
    setUls([]);
    if (field == 'state') {
      district = await states.find((districtObj) => districtObj.state_code === value);

      await handlestateChange(value, true);
    }
    if (field == 'ul') {
      ul = { ul_name: value };
    }

    if (field == 'ulb') {
      ulb = await ulbs.find((districtObj) => districtObj.ulb_code === value);

      await handleULBChange(value, true);
    }

    if (field == 'district') {
      district = await districts.find((districtObj) => districtObj.district_code === value);

      await handleDistrictChange(value, true);
    }

    if (field == 'user') {
      const iduser = await users.find((stateObj) => stateObj.mobile === value);
      user = { id: iduser?.id, mobile: value };
    }
    // Assuming this function updates the state with the changed value
    setFormData((prevData) => ({
      ...prevData,
      ...user,
      ...district,
      ...ulb,
      ...ul,
      [field]: value,
    }));
  };
  const handlestateChange = async (code, reset) => {
    if (reset) {
      setFormData((prevData) => ({
        ...prevData,
        ulb: null,
        ul: null,
        user: null,
        slot: '',
      }));
    }

    const dataArray = [];
    if (
      profileReducer.logged_in_type === 'municipal_user' ||
      profileReducer.logged_in_type === 'district_nodal_urban_agency' ||
      profileReducer?.logged_in_type === 'district_nodal_login_delhi' ||
      profileReducer?.logged_in_type === 'district_nodal_login_other' ||
      profileReducer?.logged_in_type === 'urban_nodal_login'
    ) {
      if (profileDetailReducer?.info?.length > 0) {
        const districtData = profileDetailReducer.info.map((obj) => ({
          district_name: obj.district_name,
          district_code: obj.district_code,
        }));
        setDistricts(districtData);
      }
    } else {

      const res = await firebase
        .firestore()
        .collection('district_state_lgd_mapping')
        .where('state_code', '==', code)
        .get();
      if (!res.empty) {
        res.docs.forEach((doc) => {
          dataArray.push(doc.data());
        });
        dataArray.sort((a, b) => a.district_name.localeCompare(b.district_name));
        setDistricts(dataArray);
      }
    }
  };

  const handleDistrictChange = async (code, reset) => {
    if (reset) {
      setFormData((prevData) => ({
        ...prevData,
        ulb: null,
        ul: null,
        user: null,
        slot: '',
      }));
    }

    const dataArray = [];
    if (profileReducer?.logged_in_type === 'urban_nodal_login') {
      if (profileDetailReducer?.info?.length > 0) {
        const ulbData = profileDetailReducer.info.map((obj) => ({
          ulb_name: obj.ulb_name,
          ulb_code: obj.ulb_code,
        }));
        setUlbs(ulbData);
      }
    } else {
      const res = await firebase
        .firestore()
        .collection('ulb_district_mapping')
        .where('district_code', '==', code)
        .get();
      if (!res.empty) {
        res.docs.forEach((doc) => {
          dataArray.push(doc.data());
        });
        dataArray.sort((a, b) => a.ulb_name.localeCompare(b.ulb_name));
        setUlbs(dataArray);
      } else {
        setUlbs([]);
      }
    }
  };
  const handleULBChange = async (code, reset) => {
    if (reset) {
      setFormData((prevData) => ({
        ...prevData,
        ul: null,
        user: null,
        slot: '',
      }));
    }

    const dataArray = [];

    const res = await firebase
      .firestore()
      .collection('ul_ulb_mapping')
      .where('ulb_code', '==', code)
      .get();
    if (!res.empty) {
      res.docs.forEach((doc) => {
        dataArray.push(doc.data());
      });
      dataArray.sort((a, b) => a.ul_name.localeCompare(b.ul_name));
      setUls(dataArray);
    } else {
      setUls([]);
    }


  };

  const handleSubmit = async () => {
    let uniqueId = new Date().getTime();
    setLoading(true);
    console.log(uniqueId);
    try {
      const isAvailbale = await firebase.firestore().collection('ul_ulb_mapping').doc(uniqueId?.toString()).get();
      console.log(isAvailbale);

      if (isAvailbale.exists) {
        setAlertSeverity('error');
        setAlertMessage('Something Went Wrong, Check your network or system time !');
        setIsAlertOpen(true);
        setLoading(false);
      } else {
        await firebase.firestore().collection('ul_ulb_mapping').doc(uniqueId?.toString()).set({
          created_by: email,
          platform: 'frontend',
          ul_name: formData?.ul_name?.toUpperCase(),
          ul_code: parseInt(uniqueId),
          ulb_name: formData?.ulb_name?.toUpperCase(),
          ulb_code: parseInt(formData?.ulb_code),
          lgd_type: 'ul',
        });

        setAlertSeverity('success');
        setAlertMessage('Urban Event Location Added !');
        setIsAlertOpen(true);
        setFormData({});

        setLoading(false);

      }
    } catch {
      setAlertSeverity('error');
      setAlertMessage('Something Went Wrong, Check your network or system time !');
      setIsAlertOpen(true);
      setLoading(false);
    }
  };

  const handleAlertClose = () => {
    setIsAlertOpen(false);
  };
  return (
    <PageContainer title={`${t('view_urban_event')}`} description='Add urban event location'>
      <Breadcrumbs title={`${t('view_urban_event')}`} items={BCrumb} />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isAlertOpen}
        autoHideDuration={2000}
        onClose={handleAlertClose}
      >
        <Alert severity={alertSeverity} onClose={handleAlertClose} variant='filled'>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Card>
        <CardHeader>
          <Typography variant='h2'>
            {t('view_urban_event')}
          </Typography>
        </CardHeader>
        <CardContent>


          <Grid container gap={5}>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>{t('select_state')}</CustomFormLabel>
              <CustomSelect
                id='state'
                fullWidth
                name='state'

                value={formData?.state_code || null}
                onChange={(event) => handleFormChange('state', event.target.value)}
              >
                <MenuItem disabled>
                  <em>{t('select_your_option')}</em>
                </MenuItem>
                {states?.map((item) => (
                  <MenuItem value={item?.state_code} key={item?.state_code}>
                    {item?.state_name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>{t('select_district')}</CustomFormLabel>
              <CustomSelect
                id='district'
                value={formData?.district_code}
                onChange={(event) => handleFormChange('district', event.target.value)}
                disabled={!formData?.state}
                fullWidth
                name='district'
              >
                <MenuItem disabled>
                  <em>{t('select_your_option')}</em>
                </MenuItem>
                {districts?.map((data) => (
                  <MenuItem value={data?.district_code} key={Math.random()}>
                    {data?.district_name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item xs={12} lg={2}>
              <CustomFormLabel>{t('select_ulb')}</CustomFormLabel>
              <CustomSelect
                id='ulb'
                value={formData?.ulb_code}
                onChange={(event) => handleFormChange('ulb', event.target.value)}
                disabled={!formData?.district}
                fullWidth
                name='ulb'
              >
                <MenuItem disabled>
                  <em>{t('select_your_option')}</em>
                </MenuItem>
                {ulbs.map((e) => (
                  <MenuItem value={e.ulb_code} key={Math.random()}>
                    {e?.ulb_name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Grid>


          </Grid>
          <br />
          {uls &&
            <Table
              aria-label='custom pagination table'
              sx={{
                whiteSpace: 'nowrap',
                marginTop: '1rem',
              }}
              exportButton={true}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h5'>{t('sno')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h5'>{t('state')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h5'>{t('district')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h5'>{t('ulb')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h5'>{t('ul')}</Typography>
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {uls?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant='h5'> {index + 1}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='h6' fontWeight='600'>
                        {formData?.state_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='h6' fontWeight='600'>
                        {formData?.district_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='h6' fontWeight='600'>
                        {row.ulb_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color='textSecondary' variant='h6' fontWeight='400'>
                        {row?.ul_name}
                      </Typography>
                    </TableCell>


                  </TableRow>
                ))}


              </TableBody>
            </Table>
          }
        </CardContent>
      </Card>
    </PageContainer>
  );
}
