import { UPDATE_PROFILE } from "../constants";

const INIT_STATE = {
  logged_in:false,
  logged_in_type:'',
  info:{}
};

// ProfileReducer
const ProfileReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_PROFILE:
      return {
        ...state,
       ...action.value,
      };
    default:
      return state;
  }
};

export default ProfileReducer;
