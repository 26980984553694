import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import RootReducers from './Rootreducers';

export function configureStore(initialState) {
  let enhancer = applyMiddleware(thunk);

  // Check if it's not a production environment
  if (process.env.REACT_APP_FIREBASE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    enhancer = composeWithDevTools(enhancer);
  }

  const store = createStore(RootReducers, initialState, enhancer);
  return store;
}
