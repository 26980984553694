// Layout and colors CONSTANTS
export const LIGHT_THEME = 'LIGHT_THEME';
export const DARK_THEME = 'DARK_THEME';
export const THEME_COLOR = 'THEME_COLOR';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const DIRECTION = 'DIRECTION';
export const BLUE_THEME = 'BLUE_THEME';
export const GREEN_THEME = 'GREEN_THEME';
export const RED_THEME = 'RED_THEME';
export const BLACK_THEME = 'BLACK_THEME';
export const PURPLE_THEME = 'PURPLE_THEME';
export const INDIGO_THEME = 'INDIGO_THEME';
export const ORANGE_THEME = 'ORANGE_THEME';
// CHAT CONSTANTS
export const FETCH_CHAT_SUCCESS = 'FETCH_CHAT_SUCCESS';
export const SELECTED_CHAT = 'SELECTED_CHAT';
export const SEARCH_USER = 'SEARCH_USER';
export const MSG_SUBMIT = 'MSG_SUBMIT';

// NOTES CONSTANTS
export const SELECTED_NOTES = 'SELECTED_NOTES';
export const SEARCH_NOTES = 'SEARCH_NOTES';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const ADD_NOTE = 'ADD_NOTE';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';

// EMAIL CONSTANTS
export const STAR_EMAIL = 'STAR_EMAIL';
export const IMPORTANT_EMAIL = 'IMPORTANT_EMAIL';
export const OPEN_EMAIL = 'OPEN_EMAIL';
export const TRASH_EMAIL = 'TRASH_EMAIL';
export const ASSIGN_FOLDER = 'ASSIGN_FOLDER';
export const ASSIGN_LABEL = 'ASSIGN_LABEL';
export const SET_SELECTED_EMAIL = 'SET_SELECTED_EMAIL';
export const FILTER_EMAIL = 'FILTER_EMAIL';
export const SET_EMAIL_VISIBILITY_FILTER = 'SET_EMAIL_VISIBILITY_FILTER';
//form-user
export const UPDATE_STATE_NAME = "UPDATE_STATE_NAME";
export const UPDATE_DISTRICT_NAME = "UPDATE_DISTRICT_NAME";
export const UPDATE_BLOCK_NAME = "UPDATE_BLOCK_NAME";
export const UPDATE_ULB_NAME = "UPDATE_ULB_NAME";
export const UPDATE_GP_NAME = "UPDATE_GP_NAME";
export const UPDATE_NAME = "UPDATE_NAME";
export const UPDATE_MOBILE = "UPDATE_MOBILE";
export const UPDATE_DESIGNATION = "UPDATE_DESIGNATION";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const UPDATE_AGENCY = "UPDATE_AGENCY";
export const UPDATE_SCHEME = "UPDATE_SCHEME";
export const UPDATE_MULTIPLE = "UPDATE_MULTIPLE";

//profile
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_DETAIL = "UPDATE_PROFILE_DETAIL";
