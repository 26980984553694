//redux state management user selection wise

//central_incharge,master_login,central_nodal_officer_login,
//state_nodal_login will be provide from backend

export const loginTypes = {
  state_in_charge_login: {
    state_name: true,
    district_name: false,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },

  state_urban_nodal: {
    state_name: true,
    district_name: false,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },

  state_nodal_login: {
    state_name: true,
    district_name: false,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: false, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },

  district_in_charge_login: {
    state_name: true,
    district_name: true,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },

  central_nodal_agency_login: {
    state_name: false,
    district_name: false,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: true, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  state_nodal_agency_login: {
    state_name: true,
    district_name: false,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: true, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  district_nodal_agency_login: {
    state_name: true,
    district_name: true,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: true, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  block_nodal_agency_login: {
    state_name: true,
    district_name: true,
    block_name: true,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: true, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  ulb_nodal_agency_login: {
    state_name: true,
    district_name: true,
    block_name: false,
    ulb_name: true,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: true, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  state_line_login: {
    state_name: true,
    district_name: false,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: true, // New field: Scheme
    department: true,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  state_committe_login: {
    state_name: true,
    district_name: false,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  district_nodal_login: {
    state_name: true,
    district_name: true,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  district_login: {
    state_name: true,
    district_name: true,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  block_nodal_login: {
    state_name: true,
    district_name: true,
    block_name: true,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  district_line_login: {
    state_name: true,
    district_name: true,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: true, // New field: Scheme
    department: true,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  district_committe_login: {
    state_name: true,
    district_name: true,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  block_line_login: {
    state_name: false,
    district_name: false,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: true, // New field: Scheme
    department: true,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  block_committe_login: {
    state_name: false,
    district_name: false,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  gp_committe_login: {
    state_name: false,
    district_name: false,
    block_name: false,
    ulb_name: false,
    gp_name: true,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  block_utsav_committe_login: {
    state_name: false,
    district_name: false,
    block_name: false,
    ulb_name: false,
    gp_name: true,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  block_swagat_committe_login: {
    state_name: false,
    district_name: false,
    block_name: false,
    ulb_name: false,
    gp_name: true,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: true,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  urban_nodal_login: {
    state_name: true,
    district_name: true,
    block_name: false,
    ulb_name: true,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  block_level_login: {
    state_name: true,
    district_name: true,
    block_name: true,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: true, // New field: Scheme
    department: true,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  gp_field_officer_login: {
    state_name: false,
    district_name: false,
    block_name: false,
    ulb_name: false,
    gp_name: true,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: true, // New field: Scheme
    department: true,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  rath_login: {
    state_name: true,
    district_name: true,
    block_name: true,
    ulb_name: false,
    gp_name: true,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: false, // New field: Designation
    email: false, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  day_login: {
    state_name: true,
    district_name: true,
    block_name: true,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  van_login: {
    state_name: true,
    district_name: true,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  executive_ib: {
    state_name: false,
    district_name: false,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  district_ib: {
    state_name: true,
    district_name: true,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  urban_van_supervisor: {
    state_name: true,
    district_name: false,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },
  line_scheme_officer: {
    state_name: true,
    district_name: true,
    block_name: false,
    ulb_name: true,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    camp: true,
    ul_name: true,
    location_type: false
  },
  camp_nodal_officer: {
    state_name: true,
    district_name: true,
    block_name: false,
    ulb_name: true,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    camp: false,
    ul_name: true,
    location_type: false
  },
  municipal_user: {
    state_name: true,
    district_name: true,
    block_name: false,
    ulb_name: true,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  }
  ,
  urban_camp_executive: {
    state_name: true,
    district_name: true,
    block_name: false,
    ulb_name: true,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    camp: true,
    ul_name: true,
    location_type: false
  },
  mohua: {
    state_name: false,
    district_name: false,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    camp: true,
    ul_name: true,
    location_type: false
  },

  district_nodal_login_delhi: {
    state_name: true,
    district_name: true,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },

  cbc_hlcc: {
    state_name: false,
    district_name: false,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: true, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: false,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },

  vbsy_event_observer: {
    state_name: false,
    district_name: false,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: false, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: true,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },

  gp_nodal_officer_central_agency: {
    state_name: true,
    district_name: true,
    block_name: true,
    ulb_name: false,
    gp_name: true,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: false, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: true,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },

  district_nodal_urban_agency: {
    state_name: true,
    district_name: true,
    block_name: false,
    ulb_name: false,
    gp_name: false,
    name: true, // New field: Name
    mobile: true, // New field: Mobile
    designation: true, // New field: Designation
    email: false, // New field: Email
    agency: false, // New field: Agency
    scheme: false, // New field: Scheme
    department: true,
    role: false,
    ul_name: false,
    location_type: false,
    camp: false,
  },

};
