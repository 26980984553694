import {
  UPDATE_STATE_NAME,
  UPDATE_DISTRICT_NAME,
  UPDATE_BLOCK_NAME,
  UPDATE_ULB_NAME,
  UPDATE_GP_NAME,
  UPDATE_NAME,
  UPDATE_MOBILE,
  UPDATE_DESIGNATION,
  UPDATE_EMAIL,
  UPDATE_AGENCY,
  UPDATE_SCHEME,
  UPDATE_MULTIPLE,
} from '../constants';
export const updateStateName = (value) => ({
  type: UPDATE_STATE_NAME,
  value,
});

export const updateDistrictName = (value) => ({
  type: UPDATE_DISTRICT_NAME,
  value,
});

export const updateBlockName = (value) => ({
  type: UPDATE_BLOCK_NAME,
  value,
});

export const updateUlbName = (value) => ({
  type: UPDATE_ULB_NAME,
  value,
});

export const updateGpName = (value) => ({
  type: UPDATE_GP_NAME,
  value,
});

export const updateName = (value) => ({
  type: UPDATE_NAME,
  value,
});

export const updateMobile = (value) => ({
  type: UPDATE_MOBILE,
  value,
});

export const updateDesignation = (value) => ({
  type: UPDATE_DESIGNATION,
  value,
});

export const updateEmail = (value) => ({
  type: UPDATE_EMAIL,
  value,
});

export const updateAgency = (value) => ({
  type: UPDATE_AGENCY,
  value,
});

export const updateScheme = (value) => ({
  type: UPDATE_SCHEME,
  value,
});

export const updateMultiple = (value) => ({
 
  type: UPDATE_MULTIPLE,
  value,
});





