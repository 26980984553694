import {
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  Breadcrumbs,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  DialogContentText,
  DialogActions, Tooltip,
} from '@mui/material';
import React from 'react';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import firebase from '../../../firebase/index';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';
import PageContainer from '../../../components/container/PageContainer';
import { CardHeader } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { Formik, Form, ErrorMessage, Field, useFormik } from 'formik';
import { doc, updateDoc } from 'firebase/firestore';
import * as yup from 'yup';
import { useSelector } from 'react-redux'; // Import Firestore methods

const validationSchema = yup.object({
  ul_name: yup.string().required('Urban Event Location is Required'),
});


export default function EditUL({ dataInfo }) {
  const [formData, setFormData] = useState({
    date: null,
    district: null,
    ulb: null,
    ul: null,
    user: null,
    slot: '',
  });
  const { t } = useTranslation();
  const [ulbs, setUlbs] = useState([]);
  const [uls, setUls] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [states, setStates] = useState([]);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = useState(false);
  const [editableUl, setEditableUl] = useState();
  const [deleteInfo, setDeleteDetail] = useState();
  const profileReducer = useSelector((state) => state.ProfileReducer);
  const profileDetailReducer = useSelector((state) => state.ProfileDetailReducer);

  const BCrumb = [
    {
      to: '/',
      title: t('home'),
    },
    {
      title: t('view_urban_event'),
    },
  ];

  const fetchStates = async () => {
    const dataArray = [];
    if (
      profileReducer.logged_in_type === 'municipal_user' ||
      profileReducer.logged_in_type === 'district_nodal_urban_agency' ||
      profileReducer?.logged_in_type === 'state_ib'
    ) {
      if (profileDetailReducer?.info?.length > 0) {
        const stateData = profileDetailReducer.info.map((obj) => ({
          state_name: obj.state_name,
          state_code: obj.state_code,
        }));
        // Filter out unique states based on state_code
        const uniqueStates = stateData.filter(
          (value, index, self) =>
            self.findIndex((v) => v.state_code === value.state_code) === index,
        );
        setStates(uniqueStates);
      }
    } else {

      const res = await firebase
        .firestore()
        .collection('state_lgd_mapping')
        .get();
      if (!res.empty) {
        res.docs.forEach((doc) => {
          dataArray.push(doc.data());
        });
        dataArray.sort((a, b) => a.state_name.localeCompare(b.state_name));
        setStates(dataArray);
      }
    }
  };

  useEffect(() => {
    if (profileDetailReducer?.info.length > 0) {
      fetchStates().then();
      firebase.auth().onAuthStateChanged((res) => {
        setEmail(res.email.split('@')[0]);
      });
    }
  }, [profileDetailReducer]);

  const handleFormChange = async (field, value) => {
    let ul = {};
    let district = {};
    let ulb = {};
    let user = {};
    let state = {};
    setUls([]);
    if (field == 'state') {
      district = await states.find((districtObj) => districtObj.state_code === value);

      await handlestateChange(value, true);
    }
    if (field == 'ul') {
      ul = { ul_name: value };
    }

    if (field == 'ulb') {
      ulb = await ulbs.find((districtObj) => districtObj.ulb_code === value);

      await handleULBChange(value, true);
    }

    if (field == 'district') {
      district = await districts.find((districtObj) => districtObj.district_code === value);

      await handleDistrictChange(value, true);
    }

    if (field == 'user') {
      const iduser = await users.find((stateObj) => stateObj.mobile === value);
      user = { id: iduser?.id, mobile: value };
    }
    // Assuming this function updates the state with the changed value
    setFormData((prevData) => ({
      ...prevData,
      ...user,
      ...district,
      ...ulb,
      ...ul,
      [field]: value,
    }));
  };
  const handlestateChange = async (code, reset) => {
    if (reset) {
      setFormData((prevData) => ({
        ...prevData,
        ulb: null,
        ul: null,
        user: null,
        slot: '',
      }));
    }

    const dataArray = [];
    if (profileReducer.logged_in_type === 'municipal_user' || profileReducer.logged_in_type === 'district_nodal_urban_agency') {
      if (profileDetailReducer?.info?.length > 0) {
        const districtData = profileDetailReducer.info.map((obj) => ({
          district_name: obj.district_name,
          district_code: obj.district_code,
        }));
        setDistricts(districtData);
      }
    } else {

      const res = await firebase
        .firestore()
        .collection('district_state_lgd_mapping')
        .where('state_code', '==', code)
        .get();
      if (!res.empty) {
        res.docs.forEach((doc) => {
          dataArray.push(doc.data());
        });
        dataArray.sort((a, b) => a.district_name.localeCompare(b.district_name));
        setDistricts(dataArray);
      }
    }
  };

  const handleDistrictChange = async (code, reset) => {
    if (reset) {
      setFormData((prevData) => ({
        ...prevData,
        ulb: null,
        ul: null,
        user: null,
        slot: '',
      }));
    }

    const dataArray = [];
    const res = await firebase
      .firestore()
      .collection('ulb_district_mapping')
      .where('district_code', '==', code)
      .get();
    if (!res.empty) {
      res.docs.forEach((doc) => {
        dataArray.push(doc.data());
      });
      dataArray.sort((a, b) => a.ulb_name.localeCompare(b.ulb_name));
      setUlbs(dataArray);
    } else {
      setUlbs([]);
    }
  };
  const handleULBChange = async (code, reset) => {
    if (reset) {
      setFormData((prevData) => ({
        ...prevData,
        ul: null,
        user: null,
        slot: '',
      }));
    }

    const dataArray = [];

    const res = await firebase
      .firestore()
      .collection('ul_ulb_mapping')
      .where('ulb_code', '==', code)
      .get();
    if (!res.empty) {
      res.docs.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        dataArray.push(data);
      });
      dataArray.sort((a, b) => a.ul_name.localeCompare(b.ul_name));
      setUls(dataArray);
    } else {
      setUls([]);
    }


  };

  const handleSubmit = async () => {
    let uniqueId = new Date().getTime();
    setLoading(true);
    console.log(uniqueId);
    try {
      const isAvailbale = await firebase.firestore().collection('ul_ulb_mapping').doc(uniqueId?.toString()).get();
      console.log(isAvailbale);

      if (isAvailbale.exists) {
        setAlertSeverity('error');
        setAlertMessage('Something Went Wrong, Check your network or system time !');
        setIsAlertOpen(true);
        setLoading(false);
      } else {
        await firebase.firestore().collection('ul_ulb_mapping').doc(uniqueId?.toString()).set({
          created_by: email,
          platform: 'frontend',
          ul_name: formData?.ul_name?.toUpperCase(),
          ul_code: parseInt(uniqueId),
          ulb_name: formData?.ulb_name?.toUpperCase(),
          ulb_code: parseInt(formData?.ulb_code),
          lgd_type: 'ul',
        });

        setAlertSeverity('success');
        setAlertMessage('Urban Event Location Added !');
        setIsAlertOpen(true);
        setFormData({});

        setLoading(false);

      }
    } catch {
      setAlertSeverity('error');
      setAlertMessage('Something Went Wrong, Check your network or system time !');
      setIsAlertOpen(true);
      setLoading(false);
    }
  };

  const handleAlertClose = () => {
    setIsAlertOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Handler for form submission
  const saveHandler = (values) => {
    console.log('Form data', values);
    // Implement your save logic here
  };

  const formik = useFormik({
    initialValues: {
      ul_name: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const query = firebase.firestore().collection('van_date_mapping');
      const query2 = firebase.firestore().collection('ul_ulb_mapping');
      try {
        console.log(open?.ul_code);
        const querySnapshot2 = await query.where('ul_code', '==', open?.ul_code).get();
        const querySnapshot = await query2.where('ul_code', '==', open?.ul_code).get();
        const ulAvailable= await firebase.firestore().collection('ul_ulb_mapping').where("ulb_code","==",parseInt(open?.ulb_code)).where("ul_name","==",values?.ul_name?.toUpperCase()).get();
        if(!ulAvailable.empty){
         setAlertSeverity('error');
         setAlertMessage('Same Urban event location has been created ! Before creating new location check urban event list !');
         setIsAlertOpen(true);
         setLoading(false);
         return
        }
        if (querySnapshot2?.empty) {
          console.log(querySnapshot);
          const docRef = querySnapshot.docs[0].ref;
          await query2.doc(docRef.id).update({
            ul_name: values?.ul_name?.toUpperCase(),
          });
          setAlertSeverity('success');
          setAlertMessage('Urban Event Location Edited !');
          // handleULBChange(open?.ulb_code)
          setIsAlertOpen(true);
          setFormData({});
          setLoading(false);
          setOpen(false); // Close the dialog
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setAlertSeverity('error');
          setAlertMessage('Urban event location is already allocated for events !');
          setIsAlertOpen(true);
          setFormData({});
          setLoading(false);
          setDeleteDetail(false);
        }
        // Firestore update handler
        // await query.doc(open?.ul_code).update({
        //     ul_name: values.ul_name,
        // });
        // // Handle successful update
        // console.log('Document successfully updated');
        // setOpen(false); // Close the dialog
      } catch (error) {
        // Handle errors
        console.error('Error updating document: ', error);
        setAlertSeverity('error');
        setAlertMessage('Something Went Wrong !');
        setIsAlertOpen(true);
        setFormData({});
        setLoading(false);
      }
    },
  });


  const handleDelete = async () => {
    setLoading(true);
    const query = firebase.firestore().collection('van_date_mapping');
    try {

      const querySnapshot = await query.where('ul_code', '==', deleteInfo?.ul_code).get();
      if (querySnapshot?.empty) {

        const docRef = firebase.firestore().collection('ul_ulb_mapping').doc(deleteInfo.id).delete();
        setAlertSeverity('success');
        setAlertMessage('Urban Event Location Deleted !');
        // handleULBChange(open?.ulb_code)
        setIsAlertOpen(true);
        setFormData({});
        setLoading(false);

        setDeleteDetail(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setAlertSeverity('error');
        setAlertMessage('Urban event location is already located for events !');
        setIsAlertOpen(true);
        setFormData({});
        setLoading(false);
        setDeleteDetail(false);
      }
    } catch (error) {
      // Handle errors
      console.error('Error updating document: ', error);
      setAlertSeverity('error');
      setAlertMessage('Error updating Urban event location !');
      setIsAlertOpen(true);
      setFormData({});
      setLoading(false);
      setDeleteDetail(false);
    }

  };

  return (
    <>
      <PageContainer title={`${t('edit_urban_event')}`} description='Add urban event location'>
        <Breadcrumbs title={`${t('edit_urban_event')}`} items={BCrumb} />

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isAlertOpen}
          autoHideDuration={2000}
          onClose={handleAlertClose}
        >
          <Alert severity={alertSeverity} onClose={handleAlertClose} variant='filled'>
            {alertMessage}
          </Alert>
        </Snackbar>
        <Card>
          <CardHeader>
            <Typography variant='h2'>
              {t('edit_urban_event')}
            </Typography>
          </CardHeader>
          <CardContent>


            <Grid container gap={5}>
              <Grid item xs={12} lg={2}>
                <CustomFormLabel>{t('select_state')}</CustomFormLabel>
                <CustomSelect
                  id='state'
                  fullWidth
                  name='state'

                  value={formData?.state_code || null}
                  onChange={(event) => handleFormChange('state', event.target.value)}
                >
                  <MenuItem disabled>
                    <em>{t('select_your_option')}</em>
                  </MenuItem>
                  {states?.map((item) => (
                    <MenuItem value={item?.state_code} key={item?.state_code}>
                      {item?.state_name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} lg={2}>
                <CustomFormLabel>{t('select_district')}</CustomFormLabel>
                <CustomSelect
                  id='district'
                  value={formData?.district_code}
                  onChange={(event) => handleFormChange('district', event.target.value)}
                  disabled={!formData?.state}
                  fullWidth
                  name='district'
                >
                  <MenuItem disabled>
                    <em>{t('select_your_option')}</em>
                  </MenuItem>
                  {districts?.map((data) => (
                    <MenuItem value={data?.district_code} key={Math.random()}>
                      {data?.district_name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} lg={2}>
                <CustomFormLabel>{t('select_ulb')}</CustomFormLabel>
                <CustomSelect
                  id='ulb'
                  value={formData?.ulb_code}
                  onChange={(event) => handleFormChange('ulb', event.target.value)}
                  disabled={!formData?.district}
                  fullWidth
                  name='ulb'
                >
                  <MenuItem disabled>
                    <em>{t('select_your_option')}</em>
                  </MenuItem>
                  {ulbs.map((e) => (
                    <MenuItem value={e.ulb_code} key={Math.random()}>
                      {e?.ulb_name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>


            </Grid>
            <br />
            {uls &&
              <Box sx={{
                overflowY: 'auto'
              }}>
                <Table
                  aria-label='custom pagination table'
                  sx={{
                    whiteSpace: 'nowrap',
                    marginTop: '1rem',
                  }}
                  exportButton={true}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant='h5'>{t('sno')}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h5'>{t('state')}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h5'>{t('district')}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h5'>{t('ulb')}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h5'>{t('ul')}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h5'>{t('edit')}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h5'>{t('delete')}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {uls?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant='h5'> {index + 1}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant='h6' fontWeight='600'>
                            {formData?.state_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant='h6' fontWeight='600'>
                            {formData?.district_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant='h6' fontWeight='600'>
                            {row.ulb_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Tooltip title={row?.ul_name} arrow placement="top">
                            <Typography
                              color='textSecondary'
                              variant='h6'
                              fontWeight='400'
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: '250px', // Set maximum width to 100px
                              }}
                            >
                              {row?.ul_name}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <FeatherIcon onClick={() => {
                            formik.setFieldValue('ul_name', row?.ul_name);
                            setOpen(row);
                          }} icon={'edit'} />
                        </TableCell>

                        <TableCell>

                          <Typography color='textSecondary' variant='h6' fontWeight='400'>
                            <FeatherIcon fill={'red'} onClick={() => setDeleteDetail(row)} style={{ cursor: 'pointer' }}
                                         icon={'trash'} width='20' height='20' />
                          </Typography>
                        </TableCell>


                      </TableRow>
                    ))}


                  </TableBody>
                </Table>
              </Box>
            }
          </CardContent>
        </Card>
      </PageContainer>
      <Dialog
        open={open}
        keepMounted
        onClose={() => {
          setOpen(false);
        }}
        fullWidth
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title' variant='h4'>
          Edit Urban Event Locations
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <CustomFormLabel>Urban Event Locations</CustomFormLabel>
            <CustomTextField
              fullWidth
              id='ul_name'
              name='ul_name'
              value={formik.values.ul_name}
              onChange={formik.handleChange}
              error={formik.touched.ul_name && Boolean(formik.errors.ul_name)}
              helperText={formik.touched.ul_name && formik.errors.ul_name}
            />
            <Box mt={3}>
              <LoadingButton
                loading={loading}
                type='submit'
                color='primary'
                variant='contained'
              >
                Save
              </LoadingButton>
            </Box>
          </form>
          {/*<Formik*/}
          {/*    initialValues={{ ul_name: open?.ul_name || '' }} // Set initial values using open state*/}
          {/*    onSubmit={async (values) => {*/}
          {/*        try {*/}
          {/*            // Firestore update handler*/}
          {/*            const ulbRef = doc(db, 'ul_ulb_mapping', open.ul_code); // Reference to the document*/}
          {/*            await updateDoc(ulbRef, {*/}
          {/*                ulb_name: values.ulb_name*/}
          {/*            });*/}
          {/*            // Handle successful update*/}
          {/*            console.log('Document successfully updated');*/}
          {/*            setOpen(false); // Close the dialog*/}
          {/*        } catch (error) {*/}
          {/*            // Handle errors*/}
          {/*            console.error('Error updating document: ', error);*/}
          {/*        }*/}
          {/*    }}*/}
          {/*>*/}
          {/*    {({ isSubmitting }) => (*/}
          {/*        <Form>*/}
          {/*            <CustomFormLabel htmlFor='ulb_name'>Urban Event Location*</CustomFormLabel>*/}
          {/*            <Field name="ul_name" type="text" as={CustomTextField} id='ul_name'/>*/}
          {/*            <ErrorMessage name='ul_name' component='div' className='error-message' />*/}

          {/*            <LoadingButton*/}
          {/*                loading={loading}*/}
          {/*                type='submit'*/}
          {/*                color='primary'*/}
          {/*                variant='contained'*/}
          {/*            >*/}
          {/*                Save*/}
          {/*            </LoadingButton>*/}
          {/*        </Form>*/}
          {/*    )}*/}
          {/*</Formik>*/}
        </DialogContent>
      </Dialog>

      <Dialog
        open={deleteInfo}
        keepMounted
        onClose={handleClose}
        fullWidth
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title' variant='h4'>
          {t('delete_urban_location')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description' component='div'>{t('edit_van_allocation_p')}
            <br />
            <br />
            <span style={{ color: 'red' }}>{t('are_you_sure')}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={loading} onClick={() => handleDelete()} color='danger' variant='contained'>
            {t('delete')} {' '} <FeatherIcon style={{ position: 'relative', left: '5px' }} icon={'trash'}
                                             width='20' height='20' />
          </LoadingButton>

        </DialogActions>
      </Dialog>
    </>
  );
}
