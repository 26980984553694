import React from 'react';
import { Box, Button, Grid, Stack } from '@mui/material';

import PageContainer from '../../../components/container/PageContainer';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import DayNodalForm from './DayNodalForm';
import { useTranslation } from 'react-i18next';


const DayNodalOfficer = () => {
  const { t } = useTranslation();
  const BCrumb = [
    {
      to: '/',
      title: t('home'),
    },
    {
      title: t('create_route_map'),
    },
  ];

  return(
  <PageContainer title={`${t('create_route_map')}`} description="Create Route Map">
    {/* breadcrumb */}
    <Breadcrumb title={`${t('create_route_map')}`} items={BCrumb} />
    {/* end breadcrumb */}
   
    <Grid container spacing={0}>

      <Grid item lg={12} md={12} xs={12}>
        <DayNodalForm/>
      </Grid>

    </Grid>
  </PageContainer>
  );
};

export default DayNodalOfficer;
