import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, Typography, CardContent, Button, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import PageContainer from '../../../components/container/PageContainer';
import './event-list.css';
import firebase from '../../../firebase';
import { useInView } from 'react-intersection-observer';
import eventImg from '../../../../src/assets/images/products/event.jpg';
import Spinner from '../../spinner/Spinner';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Navbar from '../../../components/Navbar/Navbar';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';


const PublicEventList = () => {
  const { t } = useTranslation();

  const [eventList, setEventList] = React.useState([]);
  const [lastId, setLastDocId] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [districtList, setDistrictList] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedBlock, setSelectedBlock] = useState('');
  const [blockList, setBlockList] = useState([]);
  const [selectedLocationType, setSelectedLocationType] = useState('');
  const [ulbList, setUlbList] = useState([]);
  const [selectedUlb, setSelectedUlb] = useState('');
  const [selectedStateCode, setSelectedStateCode] = useState(null);
  const [selectedDistrictCode, setSelectedDistrictCode] = useState(null);
  const [selectedBlockCode, setSelectedBlockCode] = useState(null);
  const [selectedUlbCode, setSelectedUlbCode] = useState(null);
  const [filter, setFilter] = useState('');


  const [upref, upView] = useInView({
    /* Optional options */
    threshold: 0,
  });

  const getEventList = async (conditions = []) => {
    setLoading(true);
    let query = firebase.firestore().collection('events');
    conditions.forEach((condition) => {
      query = query.where(condition.field, condition.operator, condition.value);
    });
    const res = await query.limit(16).orderBy('created_at', 'desc').get();
    if (!res.empty) {
      const dataArray = res.docs.map((doc) => {
        return {
          id: doc?.id,
          ...doc.data(),
        };
      });
      setEventList(dataArray);
      setLastDocId(dataArray[dataArray.length - 1]?.id);
      setLoading(false);
    } else {
      setEventList([]);
      setLoading(false);
    }
  };

  const getStateList = async () => {
    const res = await firebase
      .firestore()
      .collection('state_lgd_mapping')
      .get();
    if (!res.empty) {
      let stateArray = [];
      res.forEach((doc) => stateArray.push(doc.data()));
      stateArray.sort((a, b) => a.state_name.localeCompare(b.state_name));
      setStateList(stateArray);
    }
  };

  const getDistrictList = async (stateName) => {
    const state = await stateList.find((stateObj) => stateObj.state_name === stateName);
    if (state?.state_code) {
      const res = await firebase.firestore().collection('district_state_lgd_mapping')
        .where('state_code', '==', state.state_code).get();
      if (!res.empty) {
        const districtArray = res.docs.map((doc) => doc.data());
        districtArray.sort((a, b) => a.district_name.localeCompare(b.district_name));
        setDistrictList(districtArray);
      }
    }
  };

  const getBlockList = async (districtName) => {
    const district = districtList.find((districtObj) => districtObj.district_name === districtName);
    if (district?.district_code) {
      const res = await firebase
        .firestore()
        .collection('block_district_lgd_mapping')
        .where('district_code', '==', district?.district_code)
        .get();
      if (!res.empty) {
        let blockArray = [];
        res.forEach((doc) => blockArray.push(doc.data()));
        blockArray.sort((a, b) => a.block_name.localeCompare(b.block_name));
        setBlockList(blockArray);
      }
    }
  };

  const getUlbList = async (districtName) => {
    const district = districtList.find((districtObj) => districtObj.district_name === districtName);
    if (district?.district_code) {
      const res = await firebase
        .firestore()
        .collection('ulb_district_mapping')
        .where('district_code', '==', district?.district_code)
        .get();
      if (!res.empty) {
        let ulbArray = [];
        res.forEach((doc) => ulbArray.push(doc.data()));
        ulbArray.sort((a, b) => a.ulb_name.localeCompare(b.ulb_name));
        setUlbList(ulbArray);
      }
    }
  };

  useEffect(() => {
    getEventList().then();
  }, []);

  useEffect(() => {
    if (upView) {
      getNext();
    }
  }, [upView]);

  const getNext = () => {
    //Fetch next Pledges data
    let db = firebase.firestore();
    const lastDocRef = db.collection('events').doc(lastId);
    lastDocRef.get().then(async (lastDocFetched) => {
      let res;

      const docRef = db
        .collection('events')
        .orderBy('created_at', 'desc')
        .startAfter(lastDocFetched)
        .limit(16);
      if (filter === 'location') {
        res = await docRef.where('location_type', '==', selectedLocationType).get();
      } else if (filter === 'state') {
        res = await docRef.where('location_type', '==', selectedLocationType).where('state_code', '==', selectedStateCode).get();
      } else if (filter === 'district') {
        res = await docRef.where('location_type', '==', selectedLocationType)
          .where('state_code', '==', selectedStateCode)
          .where('district_code', '==', selectedDistrictCode).get();
      } else if (filter === 'block') {
        res = await docRef.where('location_type', '==', selectedLocationType)
          .where('state_code', '==', selectedStateCode)
          .where('district_code', '==', selectedDistrictCode)
          .where('block_code', '==', selectedBlockCode).get();
      } else if (filter === 'ulb') {
        res = await docRef.where('location_type', '==', selectedLocationType)
          .where('state_code', '==', selectedStateCode)
          .where('district_code', '==', selectedDistrictCode)
          .where('ulb_code', '==', selectedUlbCode).get();
      } else {
        res = await docRef.get();
      }
      const dataArray = res.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      setEventList([...eventList, ...dataArray]);
      setLastDocId(dataArray[dataArray.length - 1]?.id);
      // docRef
      //   .get()
      //   .then((querySnapshot) => {
      //     const dataArray = querySnapshot.docs.map((doc) => {
      //       return {
      //         id: doc.id,
      //         ...doc.data(),
      //       };
      //     });
      //     setEventList([...eventList, ...dataArray]);
      //     setLastDocId(dataArray[dataArray.length - 1].id);
      //   })
      //   .catch((error) => {
      //     console.error('Error getting documents: ', error);
      //   });
    });
  };

  const handleLocationTypeChange = async (e) => {
    setSelectedState('');
    setSelectedDistrict('');
    setSelectedBlock('');
    setSelectedUlb('');
    setSelectedStateCode(null);
    setSelectedDistrictCode(null);
    setSelectedBlockCode(null);
    setSelectedUlbCode(null);
    setSelectedLocationType(e);
    const condition = [
      { field: 'location_type', operator: '==', value: e },
    ];
    setFilter('location');
    await getEventList(condition);
    await getStateList();
  };

  const handleStateChange = async (e) => {
    setSelectedDistrict('');
    setSelectedBlock('');
    setSelectedUlb('');
    setSelectedDistrictCode(null);
    setSelectedBlockCode(null);
    setSelectedUlbCode(null);
    setSelectedState(e);
    const state = await stateList.find((stateObj) => stateObj.state_name === e);
    if (state?.state_code) {
      setSelectedStateCode(state?.state_code);
      const condition = [
        { field: 'location_type', operator: '==', value: selectedLocationType },
        { field: 'state_code', operator: '==', value: state?.state_code },
      ];
      setFilter('state');
      await getEventList(condition);
      await getDistrictList(e);
    }
  };

  const handleDistrictChange = async (e) => {
    setSelectedBlock('');
    setSelectedUlb('');
    setSelectedBlockCode(null);
    setSelectedUlbCode(null);
    setSelectedDistrict(e);
    const district = await districtList.find((districtObj) => districtObj.district_name === e);
    if (district?.district_code && selectedStateCode) {
      setSelectedDistrictCode(district?.district_code);
      const condition = [
        { field: 'location_type', operator: '==', value: selectedLocationType },
        { field: 'state_code', operator: '==', value: selectedStateCode },
        { field: 'district_code', operator: '==', value: district?.district_code },
      ];
      setFilter('district');
      await getEventList(condition);
      if (selectedLocationType === 'RURAL') {
        await getBlockList(e);
      } else {
        await getUlbList(e);
      }
    }
  };

  const handleBlockChange = async (e) => {
    setSelectedBlock(e);
    const block = await blockList.find((blockObj) => blockObj.block_name === e);
    if (block?.block_code && selectedDistrictCode && selectedStateCode) {
      setSelectedBlockCode(block?.block_code);
      const condition = [
        { field: 'location_type', operator: '==', value: selectedLocationType },
        { field: 'state_code', operator: '==', value: selectedStateCode },
        { field: 'district_code', operator: '==', value: selectedDistrictCode },
        { field: 'block_code', operator: '==', value: block?.block_code },
      ];
      setFilter('block');
      await getEventList(condition);
    }
  };

  const handleUlbChange = async (e) => {
    setSelectedUlb(e);
    const ulb = await ulbList.find((ulbObj) => ulbObj.ulb_name === e);
    if (ulb?.ulb_code && selectedDistrictCode && selectedStateCode) {
      setSelectedUlbCode(ulb?.ulb_code);
      const condition = [
        { field: 'location_type', operator: '==', value: selectedLocationType },
        { field: 'state_code', operator: '==', value: selectedStateCode },
        { field: 'district_code', operator: '==', value: selectedDistrictCode },
        { field: 'ulb_code', operator: '==', value: ulb?.ulb_code },
      ];
      setFilter('ulb');
      await getEventList(condition);
    }
  };

  const handleResetFilter = async () => {
    setSelectedState('');
    setSelectedDistrict('');
    setSelectedBlock('');
    setSelectedUlb('');
    setSelectedStateCode(null);
    setSelectedDistrictCode(null);
    setSelectedBlockCode(null);
    setSelectedUlbCode(null);
    setSelectedLocationType('');
    await getEventList();
  };


  return (
    <PageContainer title='Event List' description='this is Public Event List page'>
      <>
        <Navbar />
        <Box textAlign='center' mt='12%'>
          <Typography
            variant='h1'
            fontSize={ {
              xs: 25,
              lg: 36,
            } }
            sx={ { color: '#DB7600' } }
            fontWeight={ 700 }
          >
            { t('event_list') }
          </Typography>
        </Box>
        { loading && <div className='loader text-center'>
          <Spinner />
        </div> }
        <Grid container spacing={ 0 }>
          <Grid item xs={ 12 } mt={ 3 } p={ 4 }>
            <Grid container spacing={ 2 }>
              <Grid item xs={ 3 }>
                <CustomFormLabel>Select Location Type</CustomFormLabel>
                <CustomSelect
                  id='location_type'
                  fullWidth
                  name='location_type'
                  value={ selectedLocationType }
                  onChange={ (e) => handleLocationTypeChange(e.target.value) }
                  size='small'
                >
                  { ['RURAL', 'URBAN'].map((value) => (
                    <MenuItem value={ value } key={ Math.random() }>{ value }</MenuItem>
                  )) }
                </CustomSelect>
              </Grid>
              <Grid item xs={ 3 }>
                <CustomFormLabel>{ t('select_state') }</CustomFormLabel>
                <CustomSelect
                  id='state_name'
                  fullWidth
                  name='state_name'
                  value={ selectedState }
                  onChange={ (e) => handleStateChange(e.target.value) }
                  size='small'
                >
                  { stateList.map((state) => (
                    <MenuItem value={ state.state_name }
                              key={ Math.random() }>{ state.state_name }</MenuItem>
                  )) }
                </CustomSelect>
              </Grid>
              <Grid item xs={ 3 }>
                <CustomFormLabel>{ t('select_district') }</CustomFormLabel>
                <CustomSelect
                  id='district_name'
                  fullWidth
                  name='district_name'
                  value={ selectedDistrict }
                  onChange={ (e) => handleDistrictChange(e.target.value) }
                  size='small'
                >
                  { districtList.map((district) => (
                    <MenuItem value={ district.district_name }
                              key={ Math.random() }>{ district.district_name }</MenuItem>
                  )) }
                </CustomSelect>
              </Grid>
              { selectedLocationType === 'RURAL' && (
                <Grid item xs={ 3 }>
                  <CustomFormLabel>{ t('select_block') }</CustomFormLabel>
                  <CustomSelect
                    id='block_name'
                    fullWidth
                    name='block_name'
                    value={ selectedBlock }
                    onChange={ (e) => handleBlockChange(e.target.value) }
                    size='small'
                  >
                    { blockList.map((block) => (
                      <MenuItem value={ block.block_name }
                                key={ Math.random() }>{ block.block_name }</MenuItem>
                    )) }
                  </CustomSelect>
                </Grid>
              ) }
              { selectedLocationType === 'URBAN' && (
                <Grid item xs={ 3 }>
                  <CustomFormLabel>Select ULB</CustomFormLabel>
                  <CustomSelect
                    id='ulb_name'
                    fullWidth
                    name='ulb_name'
                    value={ selectedUlb }
                    onChange={ (e) => handleUlbChange(e.target.value) }
                    size='small'
                  >
                    { ulbList.map((ulb) => (
                      <MenuItem value={ ulb.ulb_name }
                                key={ Math.random() }>{ ulb.ulb_name }</MenuItem>
                    )) }
                  </CustomSelect>
                </Grid>
              ) }
            </Grid>
          </Grid>

          <Grid container spacing={ 2 } pl={ 4 } pt={ 2 }>
            <Box>
              <Button variant='contained' color='primary' onClick={ handleResetFilter }>
                Reset
              </Button>
            </Box>
          </Grid>

          { eventList.length > 0 && eventList?.map((event) => (
            <Grid item xs={ 12 } lg={ 2 } sm={ 4 } display='flex' alignItems='stretch' key={ Math.random() }>

              <Card sx={ { p: 0, width: '100%' } }>
                <Link
                  to={ `/public-event-list/${ event?.location_type }/${ event?.location_type === 'URBAN' ? event?.ul_code : event?.gp_code }` }>
                  <img src={ eventImg } alt='img' width='100%' />
                  <CardContent sx={ { p: 3 } }>

                    <Typography
                      component={ Link }
                      fontWeight='500'
                      sx={ {
                        display: 'block',
                        textDecoration: 'none',
                        color: (theme) =>
                          `${
                            theme.palette.mode === 'dark'
                              ? theme.palette.grey.A200
                              : 'rgba(0, 0, 0, 0.87)'
                          }`,
                      } }
                    >
                      { event.gp_name ? event.gp_name : event.ul_name && event.ul_name }
                    </Typography>
                    {/* <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      mt: '15px',
                    }}
                  >
                    <Typography variant="h6" sx={{ mr: 'auto' }} >
                      {event.gp ? event.gp : event.ul && event.ul}
                    </Typography>

                  </Box> */ }
                  </CardContent>
                </Link>
              </Card>

            </Grid>
          )) }

          { eventList.length > 0 && <div ref={ upref }></div> }


        </Grid>
      </>
    </PageContainer>);
};

export default PublicEventList;