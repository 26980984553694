import { firestore } from '../firebase';
import moment from 'moment/moment';

export const checkPhaseExistsInCollection = async (gpCode, collectionName) => {
  try {
    const querySnapshot = await firestore.collection(collectionName)
      .where("gp_code", "==", gpCode)
      .where('phase', '==', 'PHASE1')
      .get();
    return querySnapshot.empty && querySnapshot.size === 0;
  } catch (error) {
    console.error("Error checking date in Firestore collection:", error);
    return false;
  }
};

export const checkEventPhase = async (gpCode, collectionName, eventDate) => {
  try {
    const dateString = eventDate;
    const startDate = moment(dateString, 'DD/MM/YYYY').toDate();
    const endDate = moment(dateString, 'DD/MM/YYYY').add(1, 'day').toDate();
    const querySnapshot = await firestore.collection(collectionName)
      .where("gp_code", "==", gpCode)
      .where('start_date', '>=', startDate)
      .where('start_date', '<', endDate)
      .limit(1)
      .get();
    if (!querySnapshot.empty) {
      const data = querySnapshot.docs.map((doc) => doc.data())
      return data[0]?.phase;
    }
  } catch (e) {
    console.log(e);
  }
}