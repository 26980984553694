import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Divider,
    MenuItem,
    Grid,
    Alert,
    Snackbar,
    Box,
    Typography,
    Table,
    TableBody, TableCell, TableHead, TableRow, Button,
} from '@mui/material';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import '../event/event-list.css';
import firebase from '../../../firebase';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { convertJsonToCsvAndDownload } from '../../../utility/ExportButton';


const urbanColumns = {
    id: 'Van ID',
    vehicle_no: 'Vehicle No',
    state_name: 'State Name',
    driver_name: 'Van Driver - Name',
    driver_mobile: 'Van Driver - Mobile',
};

const AddVan = () => {

    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        van_no: Yup.string()
            .required('Van number is required')
            .matches(/^[0-9]+$/, 'Van no can only contain numbers')
            .max(3, 'Maximum length is 3'),
    });

    const profileDetailReducer = useSelector((state) => state.ProfileDetailReducer);
    const [isAlertOpen, setIsAlertOpen] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('success');
    const [alertMessage, setAlertMessage] = React.useState('');
    const [selectedState, setSelectedState] = useState('');
    const [stateList, setStateList] = useState([]);
    const [selectedStateCode, setSelectedStateCode] = useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [numberVan, setNumberVan] = React.useState(0);
    const [rows, setRows] = React.useState([]);

    useEffect(() => {
        if (profileDetailReducer?.info.length > 0) {
            const stateData = profileDetailReducer.info.map((obj) => ({
                state_name: obj.state_name,
                state_code: obj.state_code,
            }));
            // Filter out unique states based on state_code
            const uniqueStates = stateData.filter(
                (value, index, self) =>
                    self.findIndex((v) => v.state_code === value.state_code) === index,
            );
            setStateList(uniqueStates);
        }
    }, [profileDetailReducer]);

    const handleStateChange = async (stateName) => {
        if (!stateName) {
            return
        }
        const stateCode = await stateList.find((state) => state.state_name === stateName).state_code;
        setSelectedState(stateName);
        setSelectedStateCode(stateCode);
        getVanList(stateCode)
    };

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            setIsLoading(true);
            const db = firebase.firestore();
            const querySnapshot = await db.collection('van_lgd_mapping')
                .where('state_code', '==', selectedStateCode)
                .where('lgd_type', "==", 'state')
                .get();

            if (querySnapshot.empty) {
                setAlertSeverity('error');
                setAlertMessage('This state is not mapped with any LGD.');
                setIsLoading(false);
                setSubmitting(false);
                setIsAlertOpen(true);
                return;
            }

            let data = [];
            querySnapshot.forEach((doc) => data.push(doc.data()));
            const docRef = querySnapshot.docs[0].ref;
            let totalVan = (data[0].total ? data[0].total : 0) + parseInt(values.van_no, 10);

            for (let i = 1; i <= parseInt(values.van_no, 10); i++) {
                let start = (data[0].total ? data[0].total : 0) + i;
                await db.collection('van-list').add({
                    id: `U-${selectedStateCode}-${start}`,
                    lgd_type: "state",
                    state_code: parseInt(selectedStateCode, 10) || "",
                    state_name: selectedState || "",
                    van_type: "URBAN"
                })
            }
            await docRef.update({
                count: totalVan,
                total: totalVan
            })

            setAlertSeverity('success');
            setAlertMessage(t('all_data_saved_successfully'));
            setIsLoading(false);
            setTimeout(() => {
                window.location.reload()
            }, 2000)
            setSubmitting(false);
            setIsAlertOpen(true);
            resetForm();
        } catch (error) {
            setAlertSeverity('error');
            setAlertMessage(t('something_went_wrong'));
            setIsLoading(false);
            setSubmitting(false);
            setIsAlertOpen(true);
        }
    };

    const handleAlertClose = () => {
        setIsAlertOpen(false);
    };

    const getVanList = async (stateCode) => {
        const db = firebase.firestore();
        const querySnapshot = await db.collection('van_lgd_mapping')
            .where('state_code', '==', stateCode)
            .where('lgd_type', "==", 'state')
            .get();

        if (querySnapshot.empty) {
            setNumberVan(0)
            return
        }

        let data = [];
        querySnapshot.forEach((doc) => data.push(doc.data()));
        let totalVan = data[0].total ? data[0].total : 0;
        setNumberVan(totalVan)

        let query = firebase.firestore().collection('van-list')
            .where('lgd_type', '==', 'state')
            .where('state_code', '==', stateCode)
        const res = await query.get();
        if (!res.empty) {
            const dataArray = res.docs.map((doc) => {
                return {
                    id: doc?.id,
                    ...doc.data(),
                };
            });
            setRows(dataArray);
        } else {
            setRows([]);
        }
    }

    const exportdata = () => {
        const csvData = rows.map((row) => {
            const formattedRow = {};
            Object.keys(urbanColumns).forEach((columnName) => {
                if (row[columnName]) {
                    formattedRow[urbanColumns[columnName]] = `"${row[columnName]}"`;
                } else {
                    formattedRow[urbanColumns[columnName]] = 'NA';
                }
            });
            return formattedRow;
        });
        convertJsonToCsvAndDownload(csvData, 'list.xlsx');
    };



    return (
        <div>
            <Snackbar open={isAlertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert variant='filled' severity={alertSeverity} onClose={handleAlertClose}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Formik
                initialValues={{ van_no: "", }}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    handleSubmit(values, actions).then();
                }}
            >
                {({ values }) => (
                    <Form>
                        <Card sx={{ p: 0 }}>
                            <Box sx={{ padding: '15px 30px' }} display="flex" alignItems="center">
                                <Box flexGrow={1}>
                                    <Typography fontWeight="500" variant="h4">
                                        {t('add_van')}
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider />
                            <CardContent sx={{ padding: '30px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} lg={6}>
                                        <CustomFormLabel>Select State<sup>*</sup></CustomFormLabel>
                                        <CustomSelect
                                            id='state_name'
                                            fullWidth
                                            name='state_name'
                                            value={selectedState}
                                            onChange={(e) => handleStateChange(e.target.value)}
                                            size='small'
                                        >
                                            <MenuItem>
                                                <em>Select Your Option</em>
                                            </MenuItem>
                                            {stateList.map((state) => (
                                                <MenuItem value={state.state_name} key={Math.random()}>{state.state_name}</MenuItem>
                                            ))}
                                        </CustomSelect>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item lg={6} xs={12}>
                                        <CustomFormLabel htmlFor={`van_no`}>
                                            {t('number_of_vans_to_be_added')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomTextField}
                                            id={`van_no`}
                                            variant="outlined"
                                            maxLength="3"
                                            name={`van_no`}
                                            size="small"
                                        />
                                        <ErrorMessage name={`van_no`} component="div" className="error-message" />
                                    </Grid>
                                </Grid>
                                <Box flexGrow={1}>
                                    <Typography mt={2} fontWeight="400" variant="h6">
                                        {t('total_number_of_vans_already_added')} : {numberVan}
                                    </Typography>
                                </Box>
                                <Box pt={2}>
                                    <LoadingButton
                                        loading={isLoading}
                                        color='primary'
                                        variant='contained'
                                        disabled={values.van_no === '' || !selectedStateCode}
                                        type='submit'
                                    >
                                        {t('submit')}
                                    </LoadingButton>
                                </Box>
                            </CardContent>
                        </Card>
                    </Form>
                )}
            </Formik>
            {rows.length > 0 && (
                <Card>
                    <CardContent>
                        <Box mt={3}>
                            <Button
                                sx={{ marginRight: '1rem' }}
                                onClick={() => exportdata()}
                                variant='contained'
                                color='primary'
                            >
                                {t('export')}
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                overflow: 'auto',
                                width: '100%',
                            }}
                        >
                            <Table
                                aria-label='custom pagination table'
                                sx={{
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant='h5'>Van ID</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant='h5'>Vehicle Number</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant='h5'>State</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant='h5'>Driver Name</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant='h5'>Driver Mobile</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        ?.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell>
                                                    <Typography
                                                        variant='h5'> {row.id}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant='h6' fontWeight='600'>
                                                        {row.vehicle_no || "NA"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant='h6' fontWeight='600'>
                                                        {row.state_name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography color='textSecondary' variant='h6' fontWeight='400'>
                                                        {row?.driver_name || "NA"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography color='textSecondary' variant='h6' fontWeight='400'>
                                                        {row?.driver_mobile || "NA"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </CardContent>
                </Card>
            )}
        </div>
    );
};

export default AddVan;
