import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Divider,
    MenuItem,
    Grid,
    Alert,
    Snackbar,
    Box,
    Typography,
} from '@mui/material';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import '../event/event-list.css';
import firebase from '../../../firebase';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';

const ActivityChecklist = () => {
    const { t } = useTranslation();

    const profileReducer = useSelector((state) => state.ProfileReducer);
    const profileDetail = useSelector((state) => state.ProfileDetailReducer);
    const [isAlertOpen, setIsAlertOpen] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('success');
    const [alertMessage, setAlertMessage] = React.useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [filteredEvent, setFilteredEvent] = useState([]);
    const [doneEvent, setDoneEvent] = useState([]);
    const [isEventDatePast, setIsEventDatePast] = useState(false);
    const [selectedUlName, setSelectedUlName] = useState('');
    const [selectedUlCode, setSelectedUlCode] = useState('');
    const [ulOptions, setUlOptions] = useState([]);

    const nameToLabelMap = (name) => {
        const map = {
            recorded_message_of_pm_played: t('recorded_message_of_pm_played'),
            movie_film_on_vbsy_played: t('movie_film_on_vbsy_played'),
            number_of_calendars_distributed: t('number_of_calendars_distributed'),
            number_of_brochures_distributed: t('number_of_brochures_distributed'),
            number_of_booklets_distributed: t('number_of_booklets_distributed'),
            number_of_tshirts_distributed: t('number_of_tshirts_distributed'),
            number_of_caps_distributed: t('number_of_caps_distributed'),
            number_of_badges_distributed: t('number_of_badges_distributed'),
            number_of_qr_code_scanned_quiz: t('number_of_qr_code_scanned_quiz'),
            number_of_quiz_played_via_online_link: t('number_of_quiz_played_via_online_link'),
            number_of_qr_codes_scanned_material: t('number_of_qr_codes_scanned_material'),
            number_site_access_to_download_material: t('number_site_access_to_download_material'),
        }
        return map[name]
    }

    const validationSchema = Yup.object().shape({
        recorded_message_of_pm_played: Yup.boolean().required(t('field_is_required')),
        movie_film_on_vbsy_played: Yup.boolean().required(t('field_is_required')),
        number_of_calendars_distributed: Yup.string()
            .matches(/^\d{1,5}$/, 'Input is not valid')
            .required(t('field_is_required')),
        number_of_brochures_distributed: Yup.string()
            .matches(/^\d{1,5}$/, 'Input is not valid')
            .required(t('field_is_required')),
        number_of_booklets_distributed: Yup.string()
            .matches(/^\d{1,5}$/, 'Input is not valid')
            .required(t('field_is_required')),
        number_of_tshirts_distributed: Yup.string()
            .matches(/^\d{1,5}$/, 'Input is not valid')
            .required(t('field_is_required')),
        number_of_caps_distributed: Yup.string()
            .matches(/^\d{1,5}$/, 'Input is not valid')
            .required(t('field_is_required')),
        number_of_badges_distributed: Yup.string()
            .matches(/^\d{1,5}$/, 'Input is not valid')
            .required(t('field_is_required')),
        number_of_qr_code_scanned_quiz: Yup.string()
            .matches(/^\d{1,5}$/, 'Input is not valid')
            .required(t('field_is_required')),
        number_of_quiz_played_via_online_link: Yup.string()
            .matches(/^\d{1,5}$/, 'Input is not valid')
            .required(t('field_is_required')),
        number_of_qr_codes_scanned_material: Yup.string()
            .matches(/^\d{1,5}$/, 'Input is not valid')
            .required(t('field_is_required')),
        number_site_access_to_download_material: Yup.string()
            .matches(/^\d{1,5}$/, 'Input is not valid')
            .required(t('field_is_required')),
    });
    const [event, setEvent] = React.useState({
        recorded_message_of_pm_played: null,
        movie_film_on_vbsy_played: null,
        number_of_calendars_distributed: '',
        number_of_brochures_distributed: '',
        number_of_booklets_distributed: '',
        number_of_tshirts_distributed: '',
        number_of_caps_distributed: '',
        number_of_badges_distributed: '',
        number_of_qr_code_scanned_quiz: '',
        number_of_quiz_played_via_online_link: '',
        number_of_qr_codes_scanned_material: '',
        number_site_access_to_download_material: ''
    });

    useEffect(() => {
        if (selectedUlCode) {
            setDoneEvent([]);
            const db = firebase.firestore();
            const eventsCollection = db.collection('events');
            eventsCollection.where('ul_code', '==', selectedUlCode).get().then((querySnapshot) => {
                if (!querySnapshot.empty) {
                    querySnapshot.docs.forEach((doc) => {
                        let data = doc.data();
                        if (data.activity_checklist_done) {
                            const activitiesCollection = db.collection('activities');
                            activitiesCollection.where('event_id', '==', doc.id)
                                .where('ul_code', '==', selectedUlCode)
                                .where('category', '==', 'activity_checklist')
                                .get()
                                .then((querySnapshot1) => {
                                    if (!querySnapshot1.empty) {
                                        querySnapshot1.docs.forEach((doc1) => {
                                            let data1 = doc1.data();
                                            data = {
                                                ...data,
                                                [data1.key_name]: data1.key_value
                                            }
                                            setDoneEvent(data);
                                        })
                                    }
                                });

                        } else {
                            setDoneEvent(data);
                        }
                    });
                }
            });
        }
    }, [selectedUlCode]);

    //Get event date
    useEffect(() => {
        setIsEventDatePast(false);
        const db = firebase.firestore();
        const vanDateMapping = db.collection('van_date_mapping');
        if (selectedUlCode) {
            vanDateMapping
                .where('ul_code', '==', selectedUlCode)
                .get()
                .then((querySnapshot) => {
                    if (!querySnapshot.empty) {
                        const data = querySnapshot.docs.map((doc) => doc.data())[0];
                        const timestamp = data.start_date;
                        const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6;
                        const givenDate = dayjs(milliseconds).startOf('day');

                        // Get the current date
                        let currentDate = dayjs().startOf('day');
                        // Calculate the difference in days
                        const daysDifference = givenDate.diff(currentDate, 'day');

                        // older days
                        if (daysDifference < 0) {
                            setIsEventDatePast(true)
                        }

                        // today's date with forenoon slot
                        if (data.slot.includes('Forenoon') && daysDifference === 0) {
                            setIsEventDatePast(true);
                        }

                        // today's date with afternoon slot
                        if (data.slot.includes('Afternoon') && daysDifference === 0 && new Date().getHours() > 12) {
                            setIsEventDatePast(true);
                        }
                    }
                })
                .catch((error) => {
                    console.log('Error getting document:', error);
                });
        }
    }, [selectedUlCode]);

    useEffect(() => {
        const db = firebase.firestore();
        const userDetail = db.collection('users_detail');

        if (profileReducer.info.phone_number) {
            userDetail
                .where('mobile', '==', profileReducer.info.phone_number)
                .get()
                .then((querySnapshot) => {
                    if (!querySnapshot.empty) {
                        const data = querySnapshot.docs.map((doc) => doc.data());
                        const filteredUlOptions = data
                            .map((item) => ({
                                label: item.ul_name,
                                value: item.ul_code,
                                ulb_name: item.ulb_name,
                            }));
                        setUlOptions(filteredUlOptions);
                    }
                })
                .catch((error) => {
                    console.log('Error getting document:', error);
                });
        }
    }, [profileReducer]);

    const handleSelectedUL = (selectedValue, setFieldValue) => {
        setFieldValue('event_date', '');
        // const selectedOption = ulOptions.find((option) => option.label === selectedValue);

        const data = JSON.parse(selectedValue)
        if (data?.value) {
            updateEventBasedOnSelectedUL(data?.value);
            const updatedEvent = {
                ...event,
                ul_name: data?.label,
                ul_code: data?.value,
            };
            setEvent(updatedEvent);
            setSelectedUlName(data);
            setSelectedUlCode(data?.value);
        }
    };

    const updateEventBasedOnSelectedUL = (selectedULCode) => {
        const selectedULInfo = profileDetail.info.find((item) => item.ul_code === selectedULCode);
        setFilteredEvent(selectedULInfo);
    };

    const handleSubmit = async (values, { setSubmitting, resetForm },) => {
        setIsLoading(true);
        const db = firebase.firestore();
        const eventsCollection = db.collection('events');
        const activitiesCollection = db.collection('activities');

        try {
            let querySnapshot;
            querySnapshot = await eventsCollection.where('ul_code', '==', selectedUlCode).get();

            if (!querySnapshot.empty) {
                // Update the first matching document (you can handle multiple matches if needed)
                const docRef = querySnapshot.docs[0].ref;

                let keys = Object.keys(values)
                for (let i = 0; i < keys.length; i++) {
                    const activityQuerySnapshot = await activitiesCollection
                        .where('event_id', '==', docRef.id)
                        .where('ul_code', '==', selectedUlCode)
                        .where('category', '==', 'activity_checklist')
                        .where('key_name', '==', keys[i])
                        .get();
                    let key_value = values[keys[i]];
                    if (keys[i] !== 'recorded_message_of_pm_played' && keys[i] !== 'movie_film_on_vbsy_played') {
                        key_value = parseInt(key_value, 10);
                    }
                    if (!activityQuerySnapshot.empty) {
                        const activityDocRef = activityQuerySnapshot.docs[0].ref;
                        await activityDocRef.update({
                            key_value,
                            updated_at: Date.now(),
                            updated_by: profileReducer.info.phone_number,
                        });
                        continue;
                    }
                    const activityData = {
                        state_code: filteredEvent.state_code || "",
                        state_name: filteredEvent.state_name || "",
                        district_name: filteredEvent.district_name || "",
                        district_code: filteredEvent.district_code || "",
                        block_name: filteredEvent.block_name || "",
                        block_code: filteredEvent.block_code || "",
                        ul_name: selectedUlName?.label || '',
                        ul_code: selectedUlCode || '',
                        ulb_name: filteredEvent.ulb_name || "",
                        ulb_code: filteredEvent.ulb_code || "",
                        gp_name: filteredEvent.gp_name || '',
                        gp_code: filteredEvent.gp_code || '',
                        category: 'activity_checklist',
                        event_id: docRef.id,
                        created_by: profileReducer.info.phone_number || "",
                        created_at: Date.now(),
                        user_type: 'NON_PUBLIC',
                        location_type: profileReducer?.info?.location_type || '',
                        key_name: keys[i],
                        key_value,
                        key_label: nameToLabelMap(keys[i]),
                        phase: 'PHASE1',
                    };
                    try {
                        await activitiesCollection.add(activityData);
                    } catch (error) {
                        console.error('Error saving data:', error);
                    }
                }

                await docRef.update({
                    activity_checklist_done: true,
                });

                setAlertSeverity('success');
                setAlertMessage(`${t('activity_checklist_updated_successfully')}`);
                setIsAlertOpen(true);
                // Reset the form
                resetForm();
                setIsLoading(false);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                // No matching document found; show an alert
                setAlertSeverity('error');
                setAlertMessage(`${t('event_not_geo_tagged_yet')}`);
                setIsAlertOpen(true);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error updating or checking document:', error);
            setAlertSeverity('error');
            setAlertMessage(`${t('something_went_wrong')}`);
            setIsAlertOpen(true);
            setIsLoading(false);
        } finally {
            setSubmitting(false);
            setIsLoading(false);
        }
    };

    const handleAlertClose = () => {
        setIsAlertOpen(false);
    };

    return (
        <div>
            <Snackbar open={isAlertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert variant="filled" severity={alertSeverity} onClose={handleAlertClose}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Formik
                initialValues={event}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    handleSubmit(values, actions);
                }}
            >
                {({ setFieldValue, resetForm, values }) => (
                    <Form>
                        <Card
                            sx={{
                                p: 0,
                            }}
                        >
                            <Box
                                sx={{
                                    padding: '15px 30px',
                                }}
                                display="flex"
                                alignItems="center"
                            >
                                <Box flexGrow={1}>
                                    <Typography fontWeight="500" variant="h4">
                                        {t('activity_checklist')}
                                    </Typography>
                                </Box>
                            </Box>

                            <Divider />
                            <CardContent
                                sx={{
                                    padding: '30px',
                                }}
                            >
                                <Grid item lg={12} xs={12}>
                                    <>
                                        <CustomFormLabel htmlFor='ul_name'>
                                            {t('select_ul')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomSelect}
                                            fullWidth
                                            id='ul_name'
                                            variant='outlined'
                                            name='ul_name'
                                            size='small'
                                            sx={{
                                                mb: 2,
                                            }}
                                            onChange={(e) => {
                                                handleSelectedUL(e.target.value, setFieldValue);
                                            }}
                                            value={JSON.stringify(selectedUlName)}
                                            required
                                        >
                                            {ulOptions?.map((option) => (
                                                <MenuItem key={option.value} value={JSON.stringify(option)}>
                                                    {`${option.label} (${option?.ulb_name})`}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                        <ErrorMessage name='ul_name' component='div' className='error-message' />
                                    </>
                                </Grid>
                                <Grid container spacing={1}>
                                    {!isEventDatePast &&
                                        selectedUlName && (
                                            <Grid item xs={12}>
                                                <Box pt={2}>
                                                    <Typography variant="body2" color="error">
                                                        {t('data_cannot_be_uploaded_as_the_event_is_of_future_date_and_afternoon_slot')}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        )}
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="recorded_message_of_pm_played"
                                        >
                                            {nameToLabelMap('recorded_message_of_pm_played')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomSelect}
                                            fullWidth
                                            id="recorded_message_of_pm_played"
                                            variant="outlined"
                                            name="recorded_message_of_pm_played"
                                            size="small"
                                            sx={{
                                                mb: 2,
                                            }}
                                        >
                                            <MenuItem value={true}>{t('Yes')}</MenuItem>
                                            <MenuItem value={false}>{t('No')}</MenuItem>
                                        </Field>
                                        <ErrorMessage
                                            name="recorded_message_of_pm_played"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="movie_film_on_vbsy_played"
                                        >
                                            {nameToLabelMap('movie_film_on_vbsy_played')}
                                            < sup >*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomSelect}
                                            fullWidth
                                            id="movie_film_on_vbsy_played"
                                            variant="outlined"
                                            name="movie_film_on_vbsy_played"
                                            size="small"
                                            sx={{
                                                mb: 2,
                                            }}
                                        >
                                            <MenuItem value={true}>{t('Yes')}</MenuItem>
                                            <MenuItem value={false}>{t('No')}</MenuItem>
                                        </Field>
                                        <ErrorMessage
                                            name="movie_film_on_vbsy_played"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_of_calendars_distributed"
                                        >
                                            {nameToLabelMap('number_of_calendars_distributed')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomTextField}
                                            id="number_of_calendars_distributed"
                                            variant="outlined"
                                            name="number_of_calendars_distributed"
                                            fullWidth
                                            size="small"
                                            maxLength="5"
                                        />
                                        <ErrorMessage
                                            name="number_of_calendars_distributed"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_of_brochures_distributed"
                                        >
                                            {nameToLabelMap('number_of_brochures_distributed')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomTextField}
                                            fullWidth
                                            id="number_of_brochures_distributed"
                                            variant="outlined"
                                            name="number_of_brochures_distributed"
                                            size="small"
                                            maxLength="5"
                                        />
                                        <ErrorMessage
                                            name="number_of_brochures_distributed"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_of_booklets_distributed"
                                        >
                                            {nameToLabelMap('number_of_booklets_distributed')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomTextField}
                                            fullWidth
                                            id="number_of_booklets_distributed"
                                            variant="outlined"
                                            name="number_of_booklets_distributed"
                                            size="small"
                                        />
                                        <ErrorMessage
                                            name="number_of_booklets_distributed"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_of_tshirts_distributed"
                                        >
                                            {nameToLabelMap('number_of_tshirts_distributed')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomTextField}
                                            fullWidth
                                            id="number_of_tshirts_distributed"
                                            variant="outlined"
                                            name="number_of_tshirts_distributed"
                                            size="small"
                                        />
                                        <ErrorMessage
                                            name="number_of_tshirts_distributed"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_of_caps_distributed"
                                        >
                                            {nameToLabelMap('number_of_caps_distributed')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomTextField}
                                            fullWidth
                                            id="number_of_caps_distributed"
                                            variant="outlined"
                                            name="number_of_caps_distributed"
                                            size="small"
                                        />
                                        <ErrorMessage
                                            name="number_of_caps_distributed"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_of_badges_distributed"
                                        >
                                            {nameToLabelMap('number_of_badges_distributed')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomTextField}
                                            fullWidth
                                            id="number_of_badges_distributed"
                                            variant="outlined"
                                            name="number_of_badges_distributed"
                                            size="small"
                                        />
                                        <ErrorMessage
                                            name="number_of_badges_distributed"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_of_qr_code_scanned_quiz"
                                        >
                                            {nameToLabelMap('number_of_qr_code_scanned_quiz')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomTextField}
                                            fullWidth
                                            id="number_of_qr_code_scanned_quiz"
                                            variant="outlined"
                                            name="number_of_qr_code_scanned_quiz"
                                            size="small"
                                        />
                                        <ErrorMessage
                                            name="number_of_qr_code_scanned_quiz"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_of_quiz_played_via_online_link"
                                        >
                                            {nameToLabelMap('number_of_quiz_played_via_online_link')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomTextField}
                                            fullWidth
                                            id="number_of_quiz_played_via_online_link"
                                            variant="outlined"
                                            name="number_of_quiz_played_via_online_link"
                                            size="small"
                                        />
                                        <ErrorMessage
                                            name="number_of_quiz_played_via_online_link"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_of_qr_codes_scanned_material"
                                        >
                                            {nameToLabelMap('number_of_qr_codes_scanned_material')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomTextField}
                                            fullWidth
                                            id="number_of_qr_codes_scanned_material"
                                            variant="outlined"
                                            name="number_of_qr_codes_scanned_material"
                                            size="small"
                                        />
                                        <ErrorMessage
                                            name="number_of_qr_codes_scanned_material"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_site_access_to_download_material"
                                        >
                                            {nameToLabelMap('number_site_access_to_download_material')}
                                            <sup>*</sup>
                                        </CustomFormLabel>
                                        <Field
                                            as={CustomTextField}
                                            fullWidth
                                            id="number_site_access_to_download_material"
                                            variant="outlined"
                                            name="number_site_access_to_download_material"
                                            size="small"
                                        />
                                        <ErrorMessage
                                            name="number_site_access_to_download_material"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Grid>
                                </Grid>

                                <Box pt={2} textAlign="center">
                                    {isEventDatePast ? (
                                        <Box pt={2} textAlign="center">
                                            <LoadingButton
                                                loading={isLoading}
                                                color="primary"
                                                variant="contained"
                                                type="submit"
                                            >
                                                Save as Draft
                                            </LoadingButton>
                                        </Box>
                                    ) : (
                                        !isEventDatePast &&
                                        selectedUlName && (
                                            <Typography variant="body2" color="error">
                                                {t('data_cannot_be_uploaded_as_the_event_is_of_future_date_and_afternoon_slot')}
                                            </Typography>
                                        )
                                    )}
                                </Box>
                            </CardContent>
                        </Card>
                    </Form>
                )}
            </Formik>

            {doneEvent?.activity_checklist_done && (
                <Card
                    sx={{
                        p: 0,
                    }}
                >
                    <Box
                        sx={{
                            padding: '15px 30px',
                        }}
                        display="flex"
                        alignItems="center"
                    >
                        <Box flexGrow={1}>
                            <Typography fontWeight="500" variant="h4">
                                {t('submitted_data')}
                            </Typography>
                        </Box>
                    </Box>

                    <Divider />
                    <CardContent
                        sx={{
                            padding: '30px',
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item lg={12} xs={12}>
                                <CustomFormLabel
                                    sx={{
                                        mt: 2,
                                    }}
                                    htmlFor="ul_name"
                                >
                                    {doneEvent.ul_name}
                                </CustomFormLabel>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <Box display="flex">
                                    <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                        {nameToLabelMap('recorded_message_of_pm_played')}
                                    </Typography>
                                    <CustomFormLabel
                                        sx={{
                                            mt: 2,
                                        }}
                                        htmlFor="recorded_message_of_pm_played"
                                    >
                                        - {doneEvent.recorded_message_of_pm_played ? 'True' : 'False'}
                                    </CustomFormLabel>
                                </Box>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <Box display="flex">
                                    <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                        {nameToLabelMap('movie_film_on_vbsy_played')}
                                    </Typography>
                                    <CustomFormLabel
                                        sx={{
                                            mt: 2,
                                        }}
                                        htmlFor="movie_film_on_vbsy_played"
                                    >
                                        - {doneEvent.movie_film_on_vbsy_played ? 'True' : 'False'}
                                    </CustomFormLabel>
                                </Box>
                            </Grid>
                            {doneEvent.number_of_calendars_distributed &&
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('number_of_calendars_distributed')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_of_calendars_distributed"
                                        >
                                            - {doneEvent.number_of_calendars_distributed}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                            }
                            {doneEvent.number_of_brochures_distributed &&
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('number_of_brochures_distributed')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_of_brochures_distributed"
                                        >
                                            - {doneEvent.number_of_brochures_distributed}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                            }
                            {doneEvent.number_of_booklets_distributed &&
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('number_of_booklets_distributed')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_of_booklets_distributed"
                                        >
                                            - {doneEvent.number_of_booklets_distributed}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                            }
                            {doneEvent.number_of_tshirts_distributed &&
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('number_of_tshirts_distributed')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_of_tshirts_distributed"
                                        >
                                            - {doneEvent.number_of_tshirts_distributed}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                            }
                            {doneEvent.number_of_caps_distributed &&
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('number_of_caps_distributed')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_of_caps_distributed"
                                        >
                                            - {doneEvent.number_of_caps_distributed}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                            }
                            {doneEvent.number_of_badges_distributed &&
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('number_of_badges_distributed')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_of_badges_distributed"
                                        >
                                            - {doneEvent.number_of_badges_distributed}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                            }
                            {doneEvent.number_of_qr_code_scanned_quiz &&
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('number_of_qr_code_scanned_quiz')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_of_qr_code_scanned_quiz"
                                        >
                                            - {doneEvent.number_of_qr_code_scanned_quiz}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                            }
                            {doneEvent.number_of_quiz_played_via_online_link &&
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('number_of_quiz_played_via_online_link')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_of_quiz_played_via_online_link"
                                        >
                                            - {doneEvent.number_of_quiz_played_via_online_link}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                            }
                            {doneEvent.number_of_qr_codes_scanned_material &&
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('number_of_qr_codes_scanned_material')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_of_qr_codes_scanned_material"
                                        >
                                            - {doneEvent.number_of_qr_codes_scanned_material}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>
                            }
                            {doneEvent.number_site_access_to_download_material &&
                                <Grid item lg={6} xs={12}>
                                    <Box display="flex">
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                                            {nameToLabelMap('number_site_access_to_download_material')}
                                        </Typography>
                                        <CustomFormLabel
                                            sx={{
                                                mt: 2,
                                            }}
                                            htmlFor="number_site_access_to_download_material"
                                        >
                                            - {doneEvent.number_site_access_to_download_material}
                                        </CustomFormLabel>
                                    </Box>
                                </Grid>}

                        </Grid>
                    </CardContent>
                </Card>
            )
            }
        </div >
    );
};

export default ActivityChecklist;
