import React, { useEffect, useState } from 'react';
import { Grid, Box, Card, CardContent, Typography, Button, Avatar, Modal } from '@mui/material';
import {
  GoogleMap,
  Marker,
  LoadScript,
} from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import Spinner from '../../spinner/Spinner';
import { useInView } from 'react-intersection-observer';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: "80vw",
  bgcolor: 'background.paper',
  borderRadius: '10px',
  border: 'none',
  // boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const ImagePreview = ({ image, handleClose }) => {
  const [screenSize, setScreenSize] = useState('');

  useEffect(() => {
    setScreenSize(window.innerWidth);
  }, []);

  return (
    <Modal open={ true } onClose={ handleClose }>
      <Box sx={ style }>
        {/* <Typography id="modal-modal-title" variant="h6" component="h2">
        Preview
        </Typography> */ }
        <div className='text-center d-flex justify-content-center text-align-center'>
          <img
            style={ {
              width: screenSize > 960 ? 'auto' : '60vw',
              maxHeight: screenSize > 960 ? '30vw' : 'auto',
            } }
            src={ image }
            alt='.'
          />
        </div>
        <Button
          variant='contained'
          sx={ { width: '100px', background: '#DB7600' } }
          onClick={ handleClose }
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

const EventDetail = ({ event, eventId }) => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [more, setMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const createURL =
    process.env.REACT_APP_FIREBASE_ENV === 'production'
      ? 'https://asia-south1-viksit-bharat-s-y-dic.cloudfunctions.net'
      : 'https://asia-south1-vbsy-stagging.cloudfunctions.net';

  // const createURL = 'https://asia-south1-viksit-bharat-s-y-dic.cloudfunctions.net'


  const getEventImage = async () => {
    try {
      const res = await axios.get(`${ createURL }/getContentPaginated?event_id=${ eventId }&file_type=image&page=${ page }&limit=50&moderation_accepted=true`);
      if (res.status === 200) {
        setImages(res.data?.content);
      } else {
        console.log('Something went wrong');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getNext = async () => {
    if (more) {
      setPaginationLoading(true);
      setLoading(true);
      let pagination = page + 1;
      let url = `${ createURL }/getContentPaginated?event_id=${ eventId }&file_type=image&page=${ page }&limit=50&moderation_accepted=true`;
      try {
        const res = await axios.get(url);
        if (res?.data?.content) {
          if (res?.data?.content?.length < 50) {
            setMore(false);
          }
          setImages([...images, ...res?.data?.content]);
        } else {
          setPaginationLoading(false);
        }
        setPaginationLoading(false);
        setPage(pagination);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    } else {
      setPaginationLoading(false);
      setLoading(false);
    }
  };

  const [upref, upView] = useInView({
    /* Optional options */
    threshold: 0,
  });
  useEffect(() => {
    if (upView) {
      getNext();
    }
  }, [upView]);

  useEffect(() => {
    if (eventId) {
      getEventImage().then();
    }
  }, [eventId]);

  const { t } = useTranslation();

  function formatDateWithOrdinal(epoch) {
    var date = new Date(epoch * 1000); // Convert epoch to milliseconds
    const day = date.getDate();
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Function to determine the ordinal suffix
    function getOrdinal(n) {
      let s = ['th', 'st', 'nd', 'rd'];
      let v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }

    return `${ getOrdinal(day) } ${ month } ${ year }`;
  }

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <Box textAlign='center'>
        <Typography
          variant='h1'
          fontSize={ {
            xs: 25,
            lg: 36,
          } }
          sx={ { color: '#DB7600' } }
          fontWeight={ 700 }
        >
          { t('event_details') }
        </Typography>
      </Box>
      <Card
        sx={ {
          padding: '0',
        } }
      >

        <LoadScript googleMapsApiKey='AIzaSyA93xR8JywwU_tDrKPfpSjqM5TDDQ8i_FE'>
          <GoogleMap
            mapContainerStyle={ { width: '100%', height: '250px' } }
            center={ { lat: event?.latitude, lng: event?.longitude } }
            zoom={ 12 }
          >

            <Marker position={ { lat: event?.latitude, lng: event?.longitude } } />

          </GoogleMap>
        </LoadScript>
        {/* <img srcSet={`${profilecover} 1x, ${profilecover} 2x`} alt={profilecover} width="100%" /> */ }

        <CardContent
          sx={ {
            pt: '24px',
            pb: '28px',
          } }
        >
          <Grid container spacing={ 0 }>
            <Grid
              item
              lg={ 4 }
              sm={ 12 }
              xs={ 12 }
              sx={ {
                order: {
                  xs: '2',
                  sm: '2',
                  lg: '1',
                },
              } }
            >
              <h1>{ event?.location_type === 'RURAL' ? event?.gp_name : event?.ul_name }</h1>
              <p>{ event?.state_name }{ event?.district_name && <span>,{ event?.district_name }</span> }</p>
            </Grid>
            {/* about profile */ }
            <Grid
              item
              lg={ 4 }
              sm={ 12 }
              xs={ 12 }
              sx={ {
                order: {
                  xs: '1',
                  sm: '1',
                  lg: '2',
                },
              } }
            >
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                sx={ {
                  mt: '-90px',
                } }
              >

              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box textAlign='center'>
        <Typography
          variant='h1'
          fontSize={ {
            xs: 25,
            lg: 36,
          } }
          sx={ { color: '#DB7600' } }
          fontWeight={ 700 }
        >
          { t('image_gallery') }
        </Typography>
      </Box>
      { images?.length > 0 && (
        <>
          <Grid container>
            { images?.map((imageURL, index) => (
              <Grid item xs={ 12 } lg={ 3 } md={ 4 } sm={ 12 } key={ index }>
                <Card
                  style={ {
                    padding: '0px',
                  } }
                >
                  <div onClick={ () => handleImageClick(imageURL?.content_path) }>
                    <img
                      src={ imageURL?.thumbnail_path }
                      alt='Uploaded Img'
                      width='250'
                      style={ {
                        maxWidth: '100%',
                        cursor: 'pointer',
                        width: '100%',
                        objectFit: 'cover', // or 'contain' based on your preference
                        height: '200px', // Fixed height
                      } }
                    />
                  </div>
                  <CardContent>
                    <Grid container justifyContent='space-between' alignItems='center'>
                      <Grid item>
                        <FeatherIcon icon={ 'calendar' } size={ 15 } />
                        { imageURL?.created_at
                          ? ' ' +
                          formatDateWithOrdinal(imageURL?.created_at?._seconds.toString())
                          : null }
                      </Grid>
                      <Grid item>
                        <FeatherIcon icon={ 'map-pin' } size={ 13 } />
                        { ' ' +
                          imageURL?.state_name?.charAt(0) +
                          imageURL?.state_name?.slice(1).toLowerCase() }
                      </Grid>
                    </Grid>
                    <Box mt={ 2 }>
                      {/* <Typography variant="h6" sx={{
                              fontSize: '20px',
                            }}>

                              {imageURL?.category == "people_count" ? "People Attended" : imageURL?.category == "mkmz" ? "Meri Kahani Meri Zubani" : imageURL?.category == "health_camp_sickel_cell" ? "Health Camp Sickel Cell" : imageURL?.category == "dkpp" ? "Dharti Kahe Pukaar Ke" : imageURL?.category == "sankalp" ? "Sankalp" : imageURL?.category == "health_camp_tb" ? "Health Camp TB" : imageURL?.category == "pm_ujjwala" ? "PM Ujjwala" : imageURL?.category == "general_health_camp" ? "General Health Camp" : imageURL?.category == "visit_to_state" ? "Visit To State" : imageURL?.category == "visit_to_district" ? "Visit To District" : imageURL?.category == "bharat_volunteer_enrollment" ? "Bharat Volunteer Enrollment" : "Others"}
                            </Typography> */ }

                      <Typography
                        variant='h6'
                        sx={ {
                          fontSize: '15px',
                          color: '#777e89!important',
                        } }
                      >
                        { imageURL?.district_name
                          ?.split(' ')
                          ?.map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
                          )
                          ?.join(' ') }
                        {/*{ imageURL?.block_name ? ', ' : null }*/}
                        {/*{ imageURL?.block_name*/}
                        {/*  ?.split(' ')*/}
                        {/*  ?.map(*/}
                        {/*    (word) =>*/}
                        {/*      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),*/}
                        {/*  )*/}
                        {/*  ?.join(' ') }*/}
                        {/*{ imageURL?.gp_name ? ', ' : null }*/}
                        {/*{ imageURL?.gp_name*/}
                        {/*  ?.split(' ')*/}
                        {/*  ?.map(*/}
                        {/*    (word) =>*/}
                        {/*      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),*/}
                        {/*  )*/}
                        {/*  ?.join(' ') }*/}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )) }
            { paginationLoading && <Spinner /> }
            { images?.length > 0 && <div ref={ upref }></div> }
          </Grid>
        </>
      ) }
      { loading && <Spinner /> }
      { message && (
        <Box textAlign='center'>
          <Typography variant='h6'>{ message }</Typography>
        </Box>
      ) }
      { selectedImage && <ImagePreview image={ selectedImage } handleClose={ handleClose } /> }
    </>
  );
};

export default EventDetail;
