import {
  UPDATE_STATE_NAME,
  UPDATE_DISTRICT_NAME,
  UPDATE_BLOCK_NAME,
  UPDATE_ULB_NAME,
  UPDATE_GP_NAME,
  UPDATE_NAME,
  UPDATE_MOBILE,
  UPDATE_DESIGNATION,
  UPDATE_EMAIL,
  UPDATE_AGENCY,
  UPDATE_SCHEME,
  UPDATE_MULTIPLE,
} from '../constants';

const INIT_STATE = {
  state_name: false,
  district_name: false,
  block_name: false,
  ulb_name: false,
  gp_name: false,
  name: false,            // New field: Name
  mobile: false,          // New field: Mobile
  designation: false,     // New field: Designation
  email: false,           // New field: Email
  agency: false,          // New field: Agency
  scheme: false,
  role: false,
  department: false,
  ul_name: false,
  location_type: false,
  camp:false
};

// FormReducer
const FormReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_STATE_NAME:
      return {
        ...state,
        state_name: action.value,
      };
    case UPDATE_DISTRICT_NAME:
      return {
        ...state,
        district_name: action.value,
      };
    case UPDATE_BLOCK_NAME:
      return {
        ...state,
        block_name: action.value,
      };
    case UPDATE_ULB_NAME:
      return {
        ...state,
        ulb_name: action.value,
      };
    case UPDATE_GP_NAME:
      return {
        ...state,
        gp_name: action.value,
      };
    case UPDATE_NAME:
      return {
        ...state,
        name: action.value,
      };
    case UPDATE_MOBILE:
      return {
        ...state,
        mobile: action.value,
      };
    case UPDATE_DESIGNATION:
      return {
        ...state,
        designation: action.value,
      };
    case UPDATE_EMAIL:
      return {
        ...state,
        email: action.value,
      };
    case UPDATE_AGENCY:
      return {
        ...state,
        agency: action.value,
      };
    case UPDATE_SCHEME:
      return {
        ...state,
        scheme: action.value,
      };
    case UPDATE_MULTIPLE:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export default FormReducer;
