import React from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import 'react-perfect-scrollbar/dist/css/styles.css';
import "slick-carousel/slick/slick.css";
import 'dayjs/locale/en-gb';
import "slick-carousel/slick/slick-theme.css";
import firebase from './firebase/index';
const App = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();

  const customizer = useSelector((state) => state.CustomizerReducer);

  const db = firebase.firestore();

  // Specify the document you want to update
  const docRef = db.collection('visitors_count').doc('QoNjXxErlUMMND9ZBuTf');

  // Increment a field value
  try {
    localStorage.getItem("visited") == "visited" ? null :
      docRef.update({
        count: firebase.firestore.FieldValue.increment(1)
      }).then(() => {
        localStorage.setItem("visited", "visited");
      })
  } catch (e) {
    console.log(e)
  }

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        {routing}
      </RTL>
    </ThemeProvider>
  );
};

export default App;
