import { Alert, Card, CardContent, Divider, Grid, MenuItem, Snackbar } from '@mui/material';
import React from 'react';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import firebase from '../../../firebase/index';
import dayjs from 'dayjs';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

export default function DayNodalForm() {

  const { t } = useTranslation();
  const [blockList, setBlockList] = React.useState([]);
  const profileDetailReducer = useSelector((state) => state.ProfileDetailReducer);
  const profileReducer = useSelector((state) => state.ProfileReducer);
  const [selectedBlock, setSelectedBlock] = React.useState();
  const [selectedVan, setSelectedVan] = React.useState();
  const [vanArray, setVanArray] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState();
  const [selectedGp, setSelectedGp] = React.useState([]);
  const [slot1, selectSlot1] = React.useState('');
  const [slot2, selectSlot2] = React.useState('');
  const [name, setName] = React.useState();
  const [phone, setPhone] = React.useState();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [dummyVanArray, setDummyVanArray] = React.useState([]);
  const [userProfile, setUserProfile] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [uid,setUid]= useState();
  const [docId,setDocId]=useState();
  const [users,setUsers]= useState([])

  useEffect(() => {
    if (profileDetailReducer?.info?.length > 0) {
      // Filter info array based on the permission field
      const district = profileDetailReducer.info.filter(
        (e) => e.permission === profileReducer?.logged_in_type,
      );

      if (district.length > 0) {
        const districtCode = district[0].district_code;
        setUserProfile(district[0]);
        fetchBlockList(districtCode);
      }
    }
  }, [profileDetailReducer?.info]);
  
  const fetchBlockList = async (district_code) => {
    const res = await firebase
      .firestore()
      .collection('block_district_lgd_mapping')
      .where('district_code', '==', district_code)
      .get();
    if (!res.empty) {
      let blockArray = [];
      res.forEach((doc) => blockArray.push(doc.data()));
      blockArray.sort((a, b) => a.block_name.localeCompare(b.block_name));
      setBlockList(blockArray);
    }
  };
  function filterUniqueTimestamps(inputArray, key) {
    const uniqueTimestamps = new Set();
    const filteredArray = [];

    inputArray.forEach((obj) => {
      const date = obj?.start_date?.toDate().toDateString();
      const objKey = obj[key];
      const uniqueKey = `${date}-${objKey}`;

      if (!uniqueTimestamps.has(uniqueKey)) {
        uniqueTimestamps.add(uniqueKey);
        filteredArray.push(obj);
      }
    });

    return filteredArray;
  }
  const handleAlertClose = () => {
    setIsAlertOpen(false);
  };

  const handleBlockChange = async (e) => {
    setSelectedBlock(e?.target?.value);
    setUsers([]);
    setVanArray([]);
    setDummyVanArray([])
    setSelectedVan();
    setSelectedGp();
    setSelectedDate();
    setName();
    setPhone();
    setDocId();
    setUid();
    const data = JSON.parse(e?.target?.value);
    let query = firebase.firestore().collection('van_date_mapping').where('lgd_type', '==', 'gp');
    let userQuery= firebase.firestore().collection('users_detail').where('permission', '==', 'rath_login').where('block_code', '==', data?.block_code);

    if (data?.block_code != 'ALL') {
      query = firebase
        .firestore()
        .collection('van_date_mapping')
        .where('block_code', '==', data?.block_code)
        .where('lgd_type', '==', 'gp');
    }
    const res = await query.get();

    if (!res.empty) {
      let arr2 = [];
      console.log(res?.size, data);
      res.forEach((doc) => arr2.push(doc.data()));
      setDummyVanArray(arr2);
      console.log(arr2)
      const uniqueTimestampArray = filterUniqueTimestamps(arr2, 'block_code');

      setVanArray(uniqueTimestampArray);
    }else{
    setVanArray([]);
    setDummyVanArray([])
    setUsers([]);
    return;
    }

    const res2 = await userQuery.get();
    if (!res2.empty) {
      let arr = [];
      res2.forEach((doc) =>{
      const data= doc.data();
      data.doc_id= doc.id
      arr.push(data)
    
    });
      setUsers(arr);
    }
    
  };

  const handleVanChange = (e) => {
  
    setSelectedVan();
    setSelectedGp();
    setSelectedDate();
    const data = JSON.parse(e?.target?.value);
    const { seconds, nanoseconds } = data?.start_date;
    console.log(data);
    const milliseconds = seconds * 1000 + Math.round(nanoseconds / 1e6);
    setSelectedDate(dayjs(milliseconds).format('DD/MM/YYYY'));
    console.log(dayjs(milliseconds).format('DD/MM/YYYY'));
    const gpArray = dummyVanArray?.filter(
      (e) =>
        e.block_code == data?.block_code &&
        dayjs(e?.start_date?.toDate()).format('DD/MM/YYYY') ==
          dayjs(milliseconds).format('DD/MM/YYYY'),
    );

    setSelectedGp(gpArray);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Destructure variables

    // Check if any required fields are empty
    if (!name || !phone || !slot1 || !slot2 || selectedGp.length === 0) {
      setAlertSeverity('error');
      setAlertMessage(t('please_fill_all_the_fields_correctly'));
      setIsAlertOpen(true);
      setLoading(false);
      return; // Stop execution if there are missing fields
    }

    if (phone.length != 10) {
      setAlertSeverity('error');
      setAlertMessage(t('phone_number_validation_error'));
      setIsAlertOpen(true);
      setLoading(false);
      return; // Stop execution if there are missing fields
    }

    try {
      const db = firebase.firestore();
      const userDetailCollection = db.collection('users_detail');

  
        await userDetailCollection.doc(docId).delete();

        for (const gp of selectedGp) {
          const userDetailObj = {
            permission: 'rath_login' || '',
            gp_code: gp.gp_code,
            gp_name: gp.gp_name,
            slot: gp.slot,
            user_id: uid,
            created_by: profileReducer?.info?.phone_number || '',
            block_name: JSON.parse(selectedBlock)?.block_name,
            block_code: JSON.parse(selectedBlock)?.block_code,
            district_name: userProfile?.district_name,
            district_code: userProfile?.district_code,
            state_code: userProfile?.state_code,
            state_name: userProfile?.state_name,
            mobile: phone,
            name: name,
          };
          await userDetailCollection.add(userDetailObj);

          // Update van_date_mapping if a matching record is found
          const vanDateMapping = await firebase
            .firestore()
            .collection('van_date_mapping')
            .where('gp_code', '==', gp.gp_code)
            .where('lgd_type', '==', 'gp')
            .limit(1)
            .get();
          if (!vanDateMapping.empty) {
            vanDateMapping.docs[0].ref.update({
              slot: gp.slot,
              day_nodal_officer_name: name,
              day_nodal_phone: phone,
            });
          }
        }

        setAlertSeverity('success');
        setAlertMessage(t('route_added_successfully'));
        setLoading(false);
        setIsAlertOpen(true);
        window.location.reload();
      
    } catch (error) {
        console.log(error)
      setLoading(false);
      setAlertSeverity('error');
      setAlertMessage(t('something_went_wrong'));
      setIsAlertOpen(true);
    } finally {
      // Any final cleanup or actions can be added here.
    }
  };

  const handleSlotChange = (e, value, slot) => {
    console.log(e);
    if (slot == 'slot1') {
      selectSlot1(value);
    } else {
      selectSlot2(value);
    }
    // Create a new array by mapping over the selectedGp and updating the necessary elements
    const updatedGpArray = selectedGp.map((f) => {
      if (f?.gp_code === e) {
        // Create a new object to ensure immutability
        return { ...f, slot: value };
      }
      return f;
    });

    // Update the state with the new array
    setSelectedGp(updatedGpArray);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isAlertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert severity={alertSeverity} onClose={handleAlertClose} variant="filled">
          {alertMessage}
        </Alert>
      </Snackbar>
      <Card>
        <CardContent>
          <Grid container gap={5}>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>{t('select_block')}</CustomFormLabel>
              <CustomSelect
                value={selectedBlock}
                onChange={(e) => {
                  handleBlockChange(e);
                }}
                id="block"
                fullWidth
                name="block"
              >
                <MenuItem disabled>
                  <em>{t('select_your_option')}</em>
                </MenuItem>
                {blockList?.map((data) => (
                  <MenuItem value={JSON.stringify(data)} key={Math.random()}>
                    {data?.block_name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>{t('select_van')}</CustomFormLabel>
              <CustomSelect
                value={selectedVan}
                onChange={(e) => {
                  handleVanChange(e);
                }}
                disabled={!selectedBlock}
                id="van"
                fullWidth
                name="van"
              >
                <MenuItem disabled>
                  <em>{t('select_your_option')}</em>
                </MenuItem>
                {vanArray?.map((data) => (
                  <MenuItem value={JSON.stringify(data)} key={Math.random()}>
                    {`(${data?.van_id})` +
                      '   ' +
                      ' ' +
                      '  ' +
                      dayjs(data?.start_date?.toDate()).format('DD/MM/YYYY') +
                      ' ' +
                      ' to' +
                      ' ' +
                      dayjs(data?.end_date?.toDate()).format('DD/MM/YYYY')}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>{t('date_of_yatra')}</CustomFormLabel>
              <CustomTextField
                min={1}
                fullWidth
                id="start_date"
                name="start_date"
                type="text"
                value={selectedDate}
                disabled={true}
              />
            </Grid>
          </Grid>
          <br />
          <Divider sx={{ height: '8px' }} />
          {selectedDate && (
            <form onSubmit={handleSubmit}>
              <Grid container gap={5}>
              <Grid item xs={12} lg={3}>
                  <CustomFormLabel>{t('day_nodal_officer_phone_number')}</CustomFormLabel>
                  <CustomSelect
                value={phone}
                onChange={(e) => {
                   const data = JSON.parse(e?.target?.value)
                  setPhone(data?.mobile);
                  setName(data?.name);
                  setDocId(data?.doc_id);
                  setUid(data?.user_id)
                }}
                id="phone"
                fullWidth
                name="phone"
           
              >
                <MenuItem disabled>
                  <em>{t('select_your_option')}</em>
                </MenuItem>
                {users?.map((data) => (
                  <MenuItem value={JSON.stringify(data)} key={Math.random()}>
                    {data?.mobile}
                     
                  </MenuItem>
                ))}
              </CustomSelect>
                
                </Grid>
               
                <Grid item xs={12} lg={3}>
                  <CustomFormLabel>{t('day_nodal_officer_name')}</CustomFormLabel>
                  <CustomTextField
                    fullWidth
                    id="name"
                    name="name"
                    type="text"
                    value={name}
                    disabled
                    required
                    inputProps={{
                      maxLength: 10, // Limit the input to 10 characters
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <CustomFormLabel>
                    {'Select Slot for' + ' ' + selectedGp?.[0]?.gp_name}
                  </CustomFormLabel>
                  <CustomSelect
                    value={slot1}
                    onChange={(e) =>
                      handleSlotChange(selectedGp?.[0]?.gp_code, e.target.value, 'slot1')
                    }
                    id="slot1"
                    fullWidth
                    name="slot1"
                    required
                  >
                    <MenuItem disabled>
                      <em>{t('select_your_option')}</em>
                    </MenuItem>
                    {['Forenoon', 'Afternoon']?.map((data) => (
                      <MenuItem value={data} key={Math.random()}>
                        {data}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <CustomFormLabel>
                    {'Select Slot for' + ' ' + selectedGp?.[1]?.gp_name}
                  </CustomFormLabel>
                  <CustomSelect
                    value={slot2}
                    onChange={(e) =>
                      handleSlotChange(selectedGp?.[1]?.gp_code, e.target.value, 'slot2')
                    }
                    id="slot2"
                    fullWidth
                    name="slot2"
                    required
                  >
                    <MenuItem disabled>
                      <em>{t('select_your_option')}</em>
                    </MenuItem>
                    {['Forenoon', 'Afternoon']?.map((data) => (
                      <MenuItem value={data} key={Math.random()}>
                        {data}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </Grid>
              </Grid>
              <br />
              <LoadingButton
                  loading={loading}
                type="submit"
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                {t('submit')}
              </LoadingButton>
            </form>
          )}
        </CardContent>
      </Card>
    </>
  );
}
