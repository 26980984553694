import React from 'react';
import { Box, Button, Grid, Stack } from '@mui/material';

import PageContainer from '../../../components/container/PageContainer';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import DistrictRathAllocationForm from "./DistrictRathAllocationForm";
import UlbBlockGpRathAllocationForm from "./UlbBlockGpRathAllocationForm";
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';  
import { useTranslation } from 'react-i18next';


const VanDistrictAllocation = () => {
  const { t } = useTranslation();

  const BCrumb = [
    {
      to: '/',
      title:  t('home'),
    },
    {
      title:  t('van_allocation'),
    },
  ];

  return(<PageContainer title={`${t('van_allocation')}`} description="this is rath allocation page">
    {/* breadcrumb */}
    <Breadcrumb title={`${t('van_allocation')}`} items={BCrumb} />
    {/* end breadcrumb */}
   
        
        <Button sx={{float:'right'}} variant="contained" color="primary">
          <Link to={'/rath/district-collector'} style={{ textDecoration: 'none' }}>
          {t('view_list')}   <FeatherIcon style={{position:'relative',top:"5px",left:"5px"}} icon={'list'} width="20" height="20" />
          </Link>
        </Button>
      
    <Grid container spacing={0}>

      <Grid item lg={12} md={12} xs={12}>
        <UlbBlockGpRathAllocationForm/>
      </Grid>

    </Grid>
  </PageContainer>)
};

export default VanDistrictAllocation;
