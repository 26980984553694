import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "../locales/english.json";
import hiTranslations from "../locales/hindi.json";
import gujTranslations from "../locales/gujrati.json";
import malTranslations from "../locales/malyalam.json";
import odTranslations from "../locales/odia.json";
import punTranslations from "../locales/punjabi.json";
import tamTranslations from "../locales/tamil.json";
import telTranslations from "../locales/telugu.json";
import asTranslations from "../locales/Assamese.json";
import benTranslations from "../locales/Bengali.json";
import kanTranslations from "../locales/Kannada.json";
import marTranslations from "../locales/Marathi.json";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector) // using the language detector plugin
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslations,
      },
      hi: {
        translation: hiTranslations,
      },
      guj: {
        translation: gujTranslations,
      },
      mal: {
        translation: malTranslations,
      },
      od: {
        translation: odTranslations,
      },
      pun: {
        translation: punTranslations,
      },
      tam: {
        translation: tamTranslations,
      },
      tel: {
        translation: telTranslations,
      },
      as: {
        translation: asTranslations,
      },
      ben: {
        translation: benTranslations,
      },
      kan: {
        translation: kanTranslations,
      },
      mar: {
        translation: marTranslations,
      },
    },
    lng: localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
