import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  MenuItem,
  Snackbar,
  Typography,
} from '@mui/material';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import firebase, { firestore } from '../../../firebase';
import { dateDifferenceInDays, formatTimestampObjectToDateString } from './utility';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import DCVanList from './DCVanList';
import { checkUser } from '../../../utility/checkUser';
import { useTranslation } from 'react-i18next';

const UrbanVanAllocation = () => {
  const { t } = useTranslation();
  const [numberOfRathsAllocated, setNumberOfRathsAllocated] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [vanSize, setVanSize] = useState(0);
  const [vanSizeTotal, setVanSizeTotal] = useState(0);
  const [vanList, setVanList] = useState([]);
  const [selectedStartDate, setselectedStartDate] = useState();
  const [selectedEndDate, setselectedEndDate] = useState();
  const [startBlockDate, setStartBlockDate] = useState(null);
  const [endBlockDate, setEndBlockDate] = useState(null);
  const [selectedVan, setSelectedVan] = useState();
  const [loading, setLoading] = useState(false);
  const [gpArray, setGpArray] = useState([]);
  const profileDetailReducer = useSelector((state) => state.ProfileDetailReducer);
  const profileReducer = useSelector((state) => state.ProfileReducer);
  const [vanNodalList, setVanNodalList] = useState([]);
  const [gridData, setGridData] = useState([{}]);
  const [selectedVanOfficer, setSelectedVanOfficer] = useState();
  const [vanData, setVanData] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [districtList, setDistrictList] = useState([]);
  const [blockList, setBlockList] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    checkUser('state_ib');
  }, []);
  useEffect(() => {
    if (profileDetailReducer?.info?.length > 0) {
      // Filter info array based on the permission field
      const districts = profileDetailReducer.info.filter(
        (e) => e.permission === profileReducer?.logged_in_type,
      );

      if (districts.length > 0) {
        const stateCode = districts[0].state_code;

        // Query Firestore for van list
        const vanListRef = firebase
          .firestore()
          .collection('van-list')
          .where('state_code', '==', stateCode)
          .where('lgd_type', '==', 'state');

        vanListRef.get().then((querySnapshot) => {
          const array = [];
          querySnapshot.docs.forEach((doc) => {
            array.push(doc.data());
          });

          // Update state with van list and size
          setVanList(array);
          getVanSize(stateCode);
          // getVanNodalList(districtCode).then((res) => {
          //   setVanNodalList(res);
          // });
        });
        setSelectedState(districts[0]);
        fetchDistrictList(districts[0]?.state_code);
      }
    }
  }, [profileDetailReducer?.info]);

  const getVanSize = (stateCode) => {
    firebase
      .firestore()
      .collection('van_lgd_mapping')
      .where('state_code', '==', stateCode)
      .where('lgd_type', '==', 'state')
      .limit(1)
      .get()
      .then((querySnapshot) => {
        setVanSize(querySnapshot.docs?.[0].data().count);
        setVanSizeTotal(querySnapshot.docs?.[0].data().total);
      });
  };

  const handleAlertClose = () => {
    setIsAlertOpen(false);
  };

  const getVanNodalList = async (code) => {
    const res = await firebase
      .firestore()
      .collection('users_detail')
      .where('permission', '==', 'van_login')
      .where('district_code', '==', code)
      .get();
    if (!res.empty) {
      let blockArray = [];
      res.forEach((doc) => {
        const data = doc.data();
        data.doc_id = doc.id;
        blockArray.push(data);
      });

      return blockArray;
    }
  };
  function checkArray(array) {
    return array.length > 0 && array.every(obj => Object.values(obj).every(val => val));
  }

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Display a confirmation message
      const message = "Are you sure you want to leave?";

      // Standard for most browsers
      event.returnValue = message;

      // For some older browsers
      return message;
    };

    // Add the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  const handleSubmit = async () => {
    setLoading(true);
    try {
      console.log(gridData)
      const allObjectsFilled = checkArray(gridData);

      if (!selectedVan || !startBlockDate || !endBlockDate) {
        setAlertSeverity('error');
        setAlertMessage('Please fill all fields correctly... !');
        setIsAlertOpen(true);
        setLoading(false);
        return;
      }

      if (!allObjectsFilled) {
        setAlertSeverity('error');
        setAlertMessage('Please fill all fields correctly !');
        setIsAlertOpen(true);
        setLoading(false);
        return;
      } else {
        const van_date_db = firestore.collection('van_date_mapping');
        const userDetailCollection = firestore.collection('users_detail');

        for (const gridItem of gridData) {
          const data = {
            district_name: JSON.parse(gridItem?.district)?.district_name,
            district_code: JSON.parse(gridItem?.district)?.district_code,
            state_code: selectedState?.state_code,
            state_name: selectedState?.state_name,
            ulb_name: JSON.parse(gridItem?.ulb)?.ulb_name,
            ulb_code: JSON.parse(gridItem?.ulb)?.ulb_code,
            start_date: new Date(gridItem.date),
            end_date: new Date(gridItem.date),
            ul_name: JSON.parse(gridItem?.ul)?.ul_name,
            ul_code: JSON.parse(gridItem?.ul)?.ul_code,
            van_id: selectedVan,
            slot: gridItem?.slot,
            district_nodal_officer_mobile: JSON.parse(gridItem?.dc_ib_user)?.mobile?.toString(),
            district_nodal_officer_name: JSON.parse(gridItem?.dc_ib_user)?.name,
            day_nodal_officer_mobile: JSON.parse(gridItem?.user)?.mobile?.toString(),
            day_nodal_officer_name: JSON.parse(gridItem?.user)?.name,
            lgd_type: 'ul',
            phase: 'PHASE1',
            //driver details
            driver_name: vanData?.driver_name || '',
            vehicle_no: vanData?.vehicle_no || '',
            driver_mobile: vanData?.driver_mobile || ''

          };


          const userDetail = await userDetailCollection.doc(JSON.parse(gridItem?.user)?.doc_id);
          await userDetail.update({
            allocated: true
          })
          await userDetailCollection.add({
            user_id: JSON.parse(gridItem?.user)?.user_id,
            created_by: profileReducer?.info?.phone_number || '',
            ulb_name: JSON.parse(gridItem?.ulb)?.ulb_name,
            ulb_code: JSON.parse(gridItem?.ulb)?.ulb_code,
            designation: JSON.parse(gridItem?.user)?.designation,
            van_allocated: true,
            district_name: JSON.parse(gridItem?.district)?.district_name,
            district_code: JSON.parse(gridItem?.district)?.district_code,
            state_code: selectedState?.state_code,
            state_name: selectedState?.state_name,
            mobile: JSON.parse(gridItem?.user)?.mobile?.toString(),
            name: JSON.parse(gridItem?.user)?.name,
            driver_name: vanData?.driver_name || '',
            vehicle_no: vanData?.vehicle_no || '',
            driver_mobile: vanData?.driver_mobile || '',
            ul_name: JSON.parse(gridItem?.ul)?.ul_name,
            ul_code: JSON.parse(gridItem?.ul)?.ul_code,
            slot: gridItem?.slot,
            district_nodal_officer_mobile: JSON.parse(gridItem?.dc_ib_user)?.mobile?.toString(),
            district_nodal_officer_name: JSON.parse(gridItem?.dc_ib_user)?.name,
            allocated: true,
            initial: false,
            permission: 'urban_van_supervisor',
            location_type: "URBAN"
          })
          await van_date_db.add(data);




        }
        setIsAlertOpen(true);
        setAlertSeverity('success');
        setAlertMessage('All Vans Allocated successfully');
        setLoading(false);
        setTimeout(() => {
          window.location.reload()
        }, 1200);
      }
    } catch (error) {
      setLoading(false);
      setAlertSeverity('error');
      console.log(error)
      setAlertMessage('Something went wrong. Please try again later.');
      setIsAlertOpen(true);
    }
  };

  //fetch blocks
  const fetchULBList = async (district_code) => {
    const res = await firebase
      .firestore()
      .collection('ulb_district_mapping')
      .where('district_code', '==', district_code)
      .get();
    if (!res.empty) {
      let ulbArray = [];
      res.forEach((doc) => ulbArray.push(doc.data()));
      ulbArray.sort((a, b) => a.ulb_name.localeCompare(b.ulb_name));
      return ulbArray
    }
  };

  const fetchDistrictList = async (state_code) => {
    const res = await firebase
      .firestore()
      .collection('district_state_lgd_mapping')
      .where('state_code', '==', state_code)
      .get();
    if (!res.empty) {
      let blockArray = [];
      res.forEach((doc) => blockArray.push(doc.data()));
      blockArray.sort((a, b) => a.district_name.localeCompare(b.district_name));
      setDistrictList(blockArray);
    }
  };

  const fetchUserList = async (state_code) => {
    const res = await firebase
      .firestore()
      .collection('users_detail')
      .where('permission', '==', 'urban_van_supervisor')
      .where('initial', "==", true)
      .where('state_code', '==', state_code)
      .get();

    if (!res.empty) {
      let blockArray = [];
      let phoneArray = []
      res.forEach((doc) => {
        if (!phoneArray.includes(doc.data()?.mobile)) {
          const data = doc.data();
          data.doc_id = doc.id;
          blockArray.push(data);
          phoneArray.push(data?.mobile);
        }

      });
      console.log(blockArray);
      return blockArray;
    }
  };
  const fetchDisUserList = async (district_code) => {
    const res = await firebase
      .firestore()
      .collection('users_detail')
      .where('permission', '==', 'district_ib')
      .where('district_code', '==', district_code)
      .get();

    if (!res.empty) {
      let blockArray = [];
      let phoneArray = []
      res.forEach((doc) => {
        if (!phoneArray.includes(doc.data()?.mobile)) {
          const data = doc.data();
          data.doc_id = doc.id;
          blockArray.push(data);
          phoneArray.push(data?.mobile);
        }

      });
      console.log(blockArray);
      return blockArray;
    }
  };
  //fetch gp
  const fetchULList = async (ulb_code) => {
    const res = await firebase
      .firestore()
      .collection('ul_ulb_mapping')
      .where('ulb_code', '==', ulb_code)
      .get();
    if (!res.empty) {
      let blockArray = [];
      res.forEach((doc) => blockArray.push(doc.data()));
      blockArray.sort((a, b) => a.ul_name.localeCompare(b.ul_name));
      return blockArray;
    }
  };

  //rath change event
  const handleRathsChange = async (e) => {
    const data = JSON.parse(e.target.value);
    const van = data?.id;
    setVanData(data);
    setSelectedVan(van);



  };

  //check availability

  const checkForAvailability = async (type, value, name) => {
    let allocationRef;
    if (type == 'date') {
      allocationRef = firestore
        .collection('van_date_mapping')
        .where('van_id', '==', selectedVan)
        .where('start_date', '==', new Date(value))
        .where('lgd_type', '==', 'ul');
    } else {
      allocationRef = firestore
        .collection('van_date_mapping')
        .where('van_id', '==', selectedVan)
        .where('day_nodal_officer_mobile', '==', name)
        .where('lgd_type', '==', 'ul')
        .where('start_date', '==', new Date(value));
    }
    // Fetch all allocations for the selected van

    const querySnapshot = await allocationRef.get();
    console.log(querySnapshot.size);
    if (querySnapshot.empty) {
      return false;
    } else if (querySnapshot.size > 1) {
      return true;
    } else {
      return false;
    }
  };

  const checkForAvailabilityLocal = async (type, value, name) => {
    let allocationRef;

    if (type === 'date') {
      allocationRef = gridData.filter(
        (e) => new Date(e.date).getTime() === new Date(value).getTime(),
      );
    } else {
      allocationRef = gridData.filter(
        (e) =>
          new Date(e.date).getTime() === new Date(value).getTime() &&
          JSON.parse(e?.user || '{}')?.mobile === name,
      );
    }

    // Fetch all allocations for the selected van
    const querySnapshot = allocationRef.length;
    console.log(querySnapshot);

    if (querySnapshot === 0) {
      return false;
    } else if (querySnapshot > 1) {
      return true;
    } else {
      return false;
    }
  };

  const checkForULB = async (ul_code) => {

    let allocationRef = gridData.filter((e) => JSON.parse(e?.ul || '{}')?.ul_code === ul_code);
    console.log(allocationRef)
    // Fetch all allocations for the selected van

    const querySnapshot = allocationRef.length;
    if (querySnapshot == 0) {
      return false;
    } else if (querySnapshot > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkForULBLocal = async (ul_code) => {
    let allocationRef = firestore.collection('van_date_mapping').where('ul_code', '==', ul_code);

    // Fetch all allocations for the selected van

    const querySnapshot = await allocationRef.get();
    if (querySnapshot.empty) {
      return false;
    } else if (querySnapshot.size > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleEndDate = (e) => {
    setEndBlockDate(e);
    if (e.toDate() < startBlockDate.toDate()) {
      setIsAlertOpen(true);
      setAlertSeverity('error');
      setAlertMessage('The end date should be earlier than the start date.');
      setTimeout(() => {
        setEndBlockDate(null);
      }, 1000);
      setGridData([{}]);
      return;
    }
  };

  const handleFormChange = async (index, field, value) => {
    let gpArray = [];
    let userArray = [];
    const updatedGridData = [...gridData];

    // if (field == 'date') {
    //   const check = await checkForAvailability('date', value);
    //   const checkLocal = await checkForAvailabilityLocal('date', value);
    //   if (check) {
    //     setAlertSeverity('error');
    //     setAlertMessage('Selected date is not available for this van !');
    //     setIsAlertOpen(true);
    //     updatedGridData[index][field] = null;
    //     setGridData(updatedGridData);

    //     return;
    //   }
    //   if (checkLocal) {
    //     setAlertSeverity('error');
    //     setAlertMessage('Selected date is not available for this van !');
    //     setIsAlertOpen(true);
    //     updatedGridData[index][field] = null;
    //     setGridData(updatedGridData);

    //     return;
    //   }
    // }

    // if (field == 'user') {
    //   const check = await checkForAvailability(
    //     'user',
    //     updatedGridData[index]['date'],
    //     JSON.parse(value)?.mobile,
    //   );
    //   const checkLocal = await checkForAvailabilityLocal(
    //     'user',
    //     updatedGridData[index]['date'],
    //     JSON.parse(value)?.mobile,
    //   );
    //   if (checkLocal) {
    //     setAlertSeverity('error');
    //     setAlertMessage('Selected officer is not available for this van !');
    //     setIsAlertOpen(true);
    //     updatedGridData[index][field] = null;
    //     setGridData(updatedGridData);

    //     return;
    //   }
    //   if (check) {
    //     setAlertSeverity('error');
    //     setAlertMessage('Selected officer is not available for this van !');
    //     setIsAlertOpen(true);
    //     updatedGridData[index][field] = null;
    //     setGridData(updatedGridData);

    //     return;
    //   }
    // }
    if (field == 'district') {
      console.log(JSON.parse(value)?.district_code)
      let ulbArray = await fetchULBList(JSON.parse(value)?.district_code);

      updatedGridData[index]['ulbArray'] = ulbArray;
      userArray = await fetchDisUserList(JSON.parse(value)?.district_code);
      updatedGridData[index]['dcuserList'] = userArray;
      // userArray = await fetchUserList(JSON.parse(value)?.block_code);
      // updatedGridData[index]['userList'] = userArray;
    }
    if (field == 'ulb') {
      let ulArray = await fetchULList(JSON.parse(value)?.ulb_code);
      updatedGridData[index]['ulArray'] = ulArray;
      userArray = await fetchUserList(selectedState?.state_code);
      updatedGridData[index]['userList'] = userArray;
    }
    if (field == 'ul') {
      const check = await checkForULB(JSON.parse(value)?.ul_code);
      const checkLocal = await checkForULBLocal(JSON.parse(value)?.ul_code);
      if (check) {
        setAlertSeverity('error');
        setAlertMessage('Selected Urban Event Location is already allocated !');
        setIsAlertOpen(true);
        updatedGridData[index][field] = null;
        setGridData(updatedGridData);


        return;
      }
      if (checkLocal) {
        setAlertSeverity('error');
        setAlertMessage('Selected Urban Event Location is already allocated !');
        setIsAlertOpen(true);
        updatedGridData[index][field] = null;
        setGridData(updatedGridData);

        return;
      }

    }

    updatedGridData[index][field] = value;
    setGridData(updatedGridData);
  };
  const handleRemoveForm = (index) => {
    const updatedGridData = [...gridData];
    updatedGridData.splice(index, 1);
    setGridData(updatedGridData);
  };

  // const checkvanOfficer=(data)=>{
  //   const info = JSON.parse(data)
  //   firebase
  //     .firestore()
  //     .collection('users')
  //     .doc(info?.user_id)
  //     .get().then((res)=>{

  //       if(res?.data()?.van_id){
  //         if(res?.data()?.van_id==selectedVan){
  //           setSelectedVanOfficer(data);
  //         }else{
  //         setAlertSeverity('error');
  //         setAlertMessage(`User is already allocated to a van :- ${res?.data()?.van_id} !`);
  //         setIsAlertOpen(true);
  //         setSelectedVanOfficer(data);

  //         setTimeout(() => {
  //           setSelectedVanOfficer();
  //         }, 100);
  //       }
  //       }else{
  //         setSelectedVanOfficer(data);
  //       }
  //     })

  // }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isAlertOpen}
        autoHideDuration={2000}
        onClose={handleAlertClose}
      >
        <Alert severity={alertSeverity} onClose={handleAlertClose} variant="filled">
          {alertMessage}
        </Alert>
      </Snackbar>
      <Box>
        <Typography sx={{ marginLeft: '1rem' }} variant="h2" gutterBottom>
          {t('total_vans_allocate_to')} {selectedState?.state_name}- <b>{vanSizeTotal}</b>
        </Typography>
        <Card
          sx={{
            p: 0,
          }}
        >
          <CardContent
            sx={{
              padding: '30px',
            }}
          >
            <Grid container gap={5}>
              <Grid item xs={12} lg={2}>
                <CustomFormLabel>{t('select_van')}</CustomFormLabel>
                <CustomSelect
                  onChange={(e) => {
                    handleRathsChange(e);
                  }}
                  id="rath"
                  fullWidth
                  name="rath"
                >
                  <MenuItem disabled value={''}>
                    <em>{t('select_your_option')}</em>
                  </MenuItem>
                  {vanList?.map((data, index) => (
                    <MenuItem value={JSON.stringify(data)} key={Math.random()}>
                      {data?.id}  {data?.vehicle_no ? (" " + `(${data?.vehicle_no})`) : ''}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} lg={2}>
                <CustomFormLabel>{t('start_date_for_van')}</CustomFormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb' >
                  <DatePicker
                    onChange={(e) => {
                      setEndBlockDate();
                      setSelectedVanOfficer();
                      setStartBlockDate(e);
                    }}
                    maxDate={dayjs('02/29/2024')}
                    disabled={!selectedVan}
                    value={startBlockDate || null}
                    name={`start_date`}
                    id={`start_date`}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} lg={2}>
                <CustomFormLabel>{t('end_date_for_van')}</CustomFormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                  <DatePicker
                    onChange={(e) => {
                      handleEndDate(e);
                    }}
                    maxDate={dayjs('02/29/2024')}
                    value={endBlockDate || null}
                    disabled={!startBlockDate}
                    name={`end_date`}
                    id={`end_date`}
                  />
                </LocalizationProvider>
              </Grid>

              {endBlockDate && (
                <Grid container gap={5}>
                  <Grid container gap={5}>
                    {gridData?.length > 0 && (
                      <>
                        <Divider sx={{ height: '10px', width: '100%' }} />
                        <Typography sx={{ marginLeft: '1rem' }} variant="h2" gutterBottom>
                          {t('allocate_vans_to_ulb')}
                        </Typography>
                        {gridData?.map((data, index) => (
                          <Grid container gap={5} key={index}>
                            <Grid item xs={12} lg={2}>
                              <CustomFormLabel> Date</CustomFormLabel>
                              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                                <DatePicker
                                  name={`end_date`}
                                  id={`end_date`}
                                  value={data?.date}
                                  onChange={(event) => handleFormChange(index, 'date', event)}
                                  minDate={dayjs(startBlockDate)}
                                  maxDate={dayjs(endBlockDate)}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} lg={2}>
                              <CustomFormLabel>{t('select_district')}</CustomFormLabel>
                              <CustomSelect
                                id="district"
                                value={data?.district}
                                onChange={(event) =>
                                  handleFormChange(index, 'district', event.target.value)
                                }
                                disabled={!data?.date}
                                fullWidth
                                name="district"
                              >
                                <MenuItem disabled>
                                  <em>{t('select_your_option')}</em>
                                </MenuItem>
                                {districtList?.map((data) => (
                                  <MenuItem value={JSON.stringify(data)} key={Math.random()}>
                                    {data?.district_name}
                                  </MenuItem>
                                ))}
                              </CustomSelect>
                            </Grid>
                            <Grid item xs={12} lg={2}>
                              <CustomFormLabel>{t('select_ulb')}</CustomFormLabel>
                              <CustomSelect
                                id="ulb"
                                value={data?.ulb}
                                onChange={(event) =>
                                  handleFormChange(index, 'ulb', event.target.value)
                                }
                                disabled={!data?.district}
                                fullWidth
                                name="ulb"
                              >
                                <MenuItem disabled>
                                  <em>{t('select_your_option')}</em>
                                </MenuItem>
                                {data?.["ulbArray"]?.map((e) => (
                                  <MenuItem value={JSON.stringify(e)} key={Math.random()}>
                                    {e?.ulb_name}
                                  </MenuItem>
                                ))}
                              </CustomSelect>
                            </Grid>

                            <Grid item xs={12} lg={2}>
                              <CustomFormLabel>Select Urban Event Locations</CustomFormLabel>
                              <CustomSelect
                                id="ul"
                                fullWidth
                                name="ul"
                                disabled={!data?.ulb}
                                value={data?.ul || null}
                                onChange={(event) =>
                                  handleFormChange(index, 'ul', event.target.value)
                                }
                              >
                                <MenuItem disabled>
                                  <em>{t('select_your_option')}</em>
                                </MenuItem>
                                {data['ulArray']?.map((item) => (
                                  <MenuItem value={JSON.stringify(item)} key={item?.ul_code}>
                                    {item?.ul_name}
                                  </MenuItem>
                                ))}
                              </CustomSelect>
                            </Grid>
                            <Grid item xs={12} lg={2}>
                              <CustomFormLabel>{t('district_ib')}</CustomFormLabel>
                              <CustomSelect
                                value={data?.dc_ib_user}
                                onChange={(event) =>
                                  handleFormChange(index, 'dc_ib_user', event.target.value)
                                }
                                disabled={!data?.ul}
                                id="phone"
                                fullWidth
                                name="phone"
                              >
                                <MenuItem disabled>
                                  <em>{t('select_your_option')}</em>
                                </MenuItem>
                                {data['dcuserList']?.map((item) => (
                                  <MenuItem value={JSON.stringify(item)} key={item?.mobile}>
                                    {item?.mobile + ' ' + `(${item?.name})`}
                                  </MenuItem>
                                ))}
                              </CustomSelect>
                            </Grid>
                            <Grid item xs={12} lg={2}>
                              <CustomFormLabel>{t('van_nodal_officer')}</CustomFormLabel>
                              <CustomSelect
                                value={data?.user}
                                onChange={(event) =>
                                  handleFormChange(index, 'user', event.target.value)
                                }
                                disabled={!data?.ul}
                                id="phone"
                                fullWidth
                                name="phone"
                              >
                                <MenuItem disabled>
                                  <em>{t('select_your_option')}</em>
                                </MenuItem>
                                {data['userList']?.map((item) => (
                                  <MenuItem value={JSON.stringify(item)} key={item?.mobile}>
                                    {item?.mobile + ' ' + `(${item?.name})`}
                                  </MenuItem>
                                ))}
                              </CustomSelect>
                            </Grid>

                            <Grid item xs={12} lg={2}>
                              <CustomFormLabel>{t('slot')}</CustomFormLabel>
                              <CustomSelect
                                value={data?.slot}
                                onChange={(event) =>
                                  handleFormChange(index, 'slot', event.target.value)
                                }
                                id="slot1"
                                fullWidth
                                name="slot"
                                required
                              >
                                <MenuItem disabled>
                                  <em>{t('select_your_option')}</em>
                                </MenuItem>
                                {['Forenoon', 'Afternoon']?.map((data) => (
                                  <MenuItem value={data} key={Math.random()}>
                                    {data}
                                  </MenuItem>
                                ))}
                              </CustomSelect>
                            </Grid>
                            {index !== 0 && (
                              <Grid item xs={12} lg={1}>
                                <LoadingButton
                                  type="submit"
                                  color="danger"
                                  variant="contained"
                                  sx={{ width: '100px', textAlign: 'center' }}
                                  onClick={() => handleRemoveForm(index)}
                                >
                                  {t('remove')} -
                                </LoadingButton>
                              </Grid>
                            )}
                          </Grid>
                        ))}
                      </>
                    )}
                  </Grid>
                </Grid>
              )}

              {endBlockDate && gridData?.length > 0 && (
                <Box pt={3} textAlign={'center'}>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    sx={{ width: '100px' }}
                    onClick={() => setGridData([...gridData, {}])}
                  >
                    Add +
                  </LoadingButton>
                </Box>
              )}
            </Grid>
            {endBlockDate && gridData?.length > 0 && (
              <Box pt={5} textAlign={'center'}>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{ width: '300px' }}
                  onClick={handleSubmit}
                  loading={loading}
                >
                  Submit
                </LoadingButton>
              </Box>
            )}
          </CardContent>
        </Card>
        {/* {selectedBlock && (
          <Card>
            <CardContent>
              <Typography sx={{ marginLeft: '1rem' }} variant="h2" gutterBottom>
                Already allocated vans for selected block
              </Typography>

              <DCVanList
                block={JSON.parse(selectedBlock)?.block_code}
                selectedVan={selectedVan}
                form={true}
              />
            </CardContent>
          </Card>
        )} */}
      </Box>
    </>
  );
};

export default UrbanVanAllocation;
