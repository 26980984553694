import { UPDATE_PROFILE_DETAIL} from "../constants";

const INIT_STATE = {
  logged_in:false,
  info:{}
};

// ProfileDetailReducer
const ProfileDetailReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_DETAIL:
      return {
        ...state,
       ...action.value,
      };
    default:
      return state;
  }
};

export default ProfileDetailReducer;
