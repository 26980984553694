import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import firebase from '../../../firebase';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import CoverCard from '../../../components/profile/CoverCard';
import PageContainer from '../../../components/container/PageContainer';
import { useTranslation } from 'react-i18next';
import EventDetail from './EventDetail';
import Navbar from '../../../components/Navbar/Navbar';
import { Box } from '@mui/material';

const Event = () => {
  const [event, setEvent] = useState();
  const [eventId, setEventId] = useState('')

  const { id, locationType } = useParams();
  const { t } = useTranslation();

  const eventData = async () => {
    if (locationType && id) {
      const db = firebase.firestore().collection('events');
      let querySnapshot;
      let eventData = [];
      if (locationType === 'RURAL') {
        querySnapshot = await db.where('gp_code', '==', parseInt(id)).get();
      } else {
        querySnapshot = await db.where('ul_code', '==', parseInt(id)).get();
      }
      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        setEventId(docRef?.id)
        querySnapshot.forEach((doc) => eventData.push(doc.data()));
        setEvent(eventData[0]);
      }
    }
  };

  useEffect(() => {
    eventData().then();
  }, []);

  return (
    <PageContainer title={ `${ t('event_details') }` } description='this is Event Details page'>
      {/* breadcrumb */ }
      {/* end breadcrumb */ }
      <Navbar/>
      <Box mt='8%'>
        <EventDetail event={ event } eventId={eventId}/>
      </Box>

    </PageContainer>
  );
};

export default Event;