import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, Typography, CardContent, Modal, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import PageContainer from '../../../components/container/PageContainer';
import CoverCard from '../../../components/profile/CoverCard';
import Shopitems from './data';
import { use } from 'i18next';
import firebase from '../../../firebase';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import Spinner from '../../spinner/Spinner';
import { useInView } from 'react-intersection-observer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: "80vw",
  bgcolor: 'background.paper',
  borderRadius: '10px',
  border: 'none',
  // boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const ImagePreview = ({ image, handleClose }) => {
  const [screenSize, setScreenSize] = useState('');

  useEffect(() => {
    setScreenSize(window.innerWidth);
  }, []);

  return (
    <Modal open={ true } onClose={ handleClose }>
      <Box sx={ style }>
        {/* <Typography id="modal-modal-title" variant="h6" component="h2">
        Preview
        </Typography> */ }
        <div className='text-center d-flex justify-content-center text-align-center'>
          <img
            style={ {
              width: screenSize > 960 ? 'auto' : '60vw',
              maxHeight: screenSize > 960 ? '30vw' : 'auto',
            } }
            src={ image }
            alt='.'
          />
        </div>
        <Button
          variant='contained'
          sx={ { width: '100px', background: '#DB7600' } }
          onClick={ handleClose }
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};



const EventProfile = () => {


  const { t } = useTranslation();

  const eventId = window.location.href.split("/")[4];
  const [event, setEvent] = React.useState({});

  const [images, setImages] = useState([]);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [more, setMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [upref, upView] = useInView({
    /* Optional options */
    threshold: 0,
  });

  const BCrumb = [
    {
      to: '/',
      title: t('home'),
    },
    {
      title: t('event_details'),
    },
  ];

  const baseUrl =
    process.env.REACT_APP_FIREBASE_ENV === 'production'
      ? 'https://asia-south1-viksit-bharat-s-y-dic.cloudfunctions.net'
      : 'https://asia-south1-vbsy-stagging.cloudfunctions.net';

  // const event = {
  //   event_name: "EVENT NAME",
  //   event_description: "EVENT DESCRIPTION",
  //   event_date: "DateTime",
  //   locationType: "rural",
  //   state_name: "HARYANA",
  //   state_code: 12,
  //   district_name: "AMBALA",
  //   district_code: 23,
  //   block_name: "BARARA",
  //   block_code: 34,
  //   ulb_name: "",
  //   ulb_code: "",
  //   gp_name: "BARARA",
  //   gp_code: 32,
  //   active: true,
  //   approved: true,
  //   created_by: "SHIVANGI",
  //   created_at: "DateTime",
  //   updated_by: "",
  //   updated_at: "DateTime"

  // }

  const getEventImage = async () => {
    try {
      setLoading(true)
      const res = await axios.get(`${ baseUrl }/getContentPaginated?event_id=${ eventId }&file_type=image&page=${ page }&limit=50`);
      if (res.status === 200) {
        setImages(res.data?.content);
        setLoading(false);
      } else {
        console.log('Something went wrong');
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getNext = async () => {
    if (more) {
      setPaginationLoading(true);
      setLoading(true);
      let pagination = page + 1;
      let url = `${baseUrl}/getContentPaginated?event_id=${eventId}&file_type=image&page=${page}&limit=50`;
      try {
        const res = await axios.get(url);
        if (res?.data?.content) {
          if (res?.data?.content?.length < 50) {
            setMore(false);
          }
          // Filter out duplicate images
          const uniqueImages = res.data.content.filter(newImage => !images.some(existingImage => existingImage.id === newImage.id));
          setImages(prevImages => [...prevImages, ...uniqueImages]);
        } else {
          setPaginationLoading(false);
        }
        setPaginationLoading(false);
        setPage(pagination);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    } else {
      setPaginationLoading(false);
      setLoading(false);
    }
  };


  useEffect(() => {
    const db = firebase.firestore();
    const docRef = db.collection("events").doc(eventId);
    docRef.get().then((doc) => {
      if (doc.exists) {
        // console.log("Document data:", doc.data());
        setEvent(doc.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }
    ).catch((error) => {
      console.log("Error getting document:", error);
    }
    );
  }, []);

  useEffect(() => {
    if (eventId) {
      getEventImage().then();
    }
  },[eventId]);

  useEffect(() => {
    if (upView) {
      getNext();
    }
  }, [upView]);

  function formatDateWithOrdinal(epoch) {
    var date = new Date(epoch * 1000); // Convert epoch to milliseconds
    const day = date.getDate();
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Function to determine the ordinal suffix
    function getOrdinal(n) {
      let s = ['th', 'st', 'nd', 'rd'];
      let v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }

    return `${ getOrdinal(day) } ${ month } ${ year }`;
  }

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };


  return (
    <PageContainer title={`${t('event_details')}`} description="this is User Profile page">
      {/* breadcrumb */}
      <Breadcrumb title={`${t('event_details')}`} items={BCrumb} />
      {/* end breadcrumb */}
      <CoverCard event={event} />

      <Box mt={5}>
        <Box textAlign='center'>
          <Typography variant='h1' color='secondary' fontWeight={600}>
            Event Media
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant='subtitle1' color='error' fontWeight={600}><i>{t("event_image_note")}</i></Typography>
        </Box>
        { images?.length > 0 && (
          <>
            <Grid container>
              { images?.map((imageURL, index) => (
                <Grid item xs={ 12 } lg={ 3 } md={ 4 } sm={ 12 } key={ index }>
                  <Card
                    style={ {
                      padding: '0px',
                    } }
                  >
                    <div onClick={ () => handleImageClick(imageURL?.content_path) }>
                      <img
                        src={ imageURL?.thumbnail_path }
                        alt='Uploaded Img'
                        width='250'
                        style={ {
                          maxWidth: '100%',
                          cursor: 'pointer',
                          width: '100%',
                          objectFit: 'cover', // or 'contain' based on your preference
                          height: '200px', // Fixed height
                        } }
                      />
                    </div>
                    <CardContent>
                      <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>
                          <FeatherIcon icon={ 'calendar' } size={ 15 } />
                          { imageURL?.created_at
                            ? ' ' +
                            formatDateWithOrdinal(imageURL?.created_at?._seconds.toString())
                            : null }
                        </Grid>
                        <Grid item>
                          <FeatherIcon icon={ 'map-pin' } size={ 13 } />
                          { ' ' +
                            imageURL?.state_name?.charAt(0) +
                            imageURL?.state_name?.slice(1).toLowerCase() }
                        </Grid>
                      </Grid>
                      <Box mt={ 2 }>
                        {/* <Typography variant="h6" sx={{
                              fontSize: '20px',
                            }}>

                              {imageURL?.category == "people_count" ? "People Attended" : imageURL?.category == "mkmz" ? "Meri Kahani Meri Zubani" : imageURL?.category == "health_camp_sickel_cell" ? "Health Camp Sickel Cell" : imageURL?.category == "dkpp" ? "Dharti Kahe Pukaar Ke" : imageURL?.category == "sankalp" ? "Sankalp" : imageURL?.category == "health_camp_tb" ? "Health Camp TB" : imageURL?.category == "pm_ujjwala" ? "PM Ujjwala" : imageURL?.category == "general_health_camp" ? "General Health Camp" : imageURL?.category == "visit_to_state" ? "Visit To State" : imageURL?.category == "visit_to_district" ? "Visit To District" : imageURL?.category == "bharat_volunteer_enrollment" ? "Bharat Volunteer Enrollment" : "Others"}
                            </Typography> */ }

                        <Typography
                          variant='h6'
                          sx={ {
                            fontSize: '15px',
                            color: '#777e89!important',
                          } }
                        >
                          { imageURL?.district_name
                            ?.split(' ')
                            ?.map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
                            )
                            ?.join(' ') }
                          {/*{ imageURL?.block_name ? ', ' : null }*/}
                          {/*{ imageURL?.block_name*/}
                          {/*  ?.split(' ')*/}
                          {/*  ?.map(*/}
                          {/*    (word) =>*/}
                          {/*      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),*/}
                          {/*  )*/}
                          {/*  ?.join(' ') }*/}
                          {/*{ imageURL?.gp_name ? ', ' : null }*/}
                          {/*{ imageURL?.gp_name*/}
                          {/*  ?.split(' ')*/}
                          {/*  ?.map(*/}
                          {/*    (word) =>*/}
                          {/*      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),*/}
                          {/*  )*/}
                          {/*  ?.join(' ') }*/}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              )) }
              { paginationLoading && <Spinner /> }
              { images?.length > 0 && <div ref={ upref }></div> }
            </Grid>
          </>
        ) }
        { loading && <Spinner /> }
        { selectedImage && <ImagePreview image={ selectedImage } handleClose={ handleClose } /> }
      </Box>


      {/* <Card>
      <Typography level="h1" variant="h1" >Media Gallery</Typography>
      <Grid container spacing={0}>

    {Shopitems.map((product) => (
      <Grid item xs={12} lg={3} sm={4} display="flex" alignItems="stretch" key={product.id}>
        <Card sx={{ p: 0, width: '100%' }}>
          <img src={product.photo} alt="img" width="100%" />
          <CardContent sx={{ p: 3 }}>
            <Typography variant="caption">{product.category}</Typography>
            <Typography
              component={Link}
              to="/shop/shop-detail"
              fontWeight="500"
              sx={{
                display: 'block',
                textDecoration: 'none',
                color: (theme) =>
                  `${
                    theme.palette.mode === 'dark'
                      ? theme.palette.grey.A200
                      : 'rgba(0, 0, 0, 0.87)'
                  }`,
              }}
            >
              {product.title}
            </Typography>

          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
      </Card> */}

    </PageContainer>
  );
};

export default EventProfile;
